/* eslint no-restricted-globals: 0 */
import React, {
  MutableRefObject,
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSelector } from '../../../../hooks/useSelector'
import usePlayerActions from '../../hooks/usePlayerActions'
import { useDispatch } from 'react-redux'
import { setFullscreen, setMap } from '../../../../reducers/game/game'

interface Action<T = any> {
  id?: string
  type: string
  payload?: T
}

interface GameBoardContextReturn {
  iFrameRef: MutableRefObject<HTMLIFrameElement | undefined>
  focusPlayer: (playerId: string) => void
  focusPlanet: (planet: string) => void
  onPopout: () => void
  poppedOut: boolean
}

type PlanetMap = Set<string>

export const GameBoardContext = createContext<GameBoardContextReturn>(null!)

export const GameBoardProvider = ({ children, edit }: PropsWithChildren<{ edit?: boolean }>) => {
  const win = useMemo(() => {
    const popped = sessionStorage.getItem('poppedout')
    if (!popped) return
    const w = window.open('', 'Prometheus')
    w?.addEventListener('beforeunload', () => {
      sessionStorage.removeItem('poppedout')
      setPoppedOut(false)
      windowRef.current = undefined
    })
    return w
  }, [])
  const iFrameRef = useRef<HTMLIFrameElement | undefined>(undefined)
  const windowRef = useRef<Window | undefined>(win || undefined)
  const [poppedOut, setPoppedOut] = useState(!!win)
  const promisesRef = useRef<Record<string, (res: any) => void>>({})
  const lastShipPositionRef = useRef<Record<string, string>>({})
  const ownedPlanetsRef = useRef<Record<string, PlanetMap>>({})
  const board = useSelector(s => s.game.game_board)
  const players = useSelector(s => s.game.metadata?.players)
  const uid = useSelector(s => s.game.sheet?.id)
  const uidRef = useRef(uid)
  uidRef.current = uid
  const fullscreen = useSelector(s => s.game.fullscreen)
  const fullscreenRef = useRef(fullscreen)
  fullscreenRef.current = fullscreen
  const map = useSelector(s => s.game.galaxy_display)
  const mapRef = useRef(map)
  mapRef.current = map
  const choosable = useSelector(s => s.game.sheet?.planet_movement_options)
  const [ready, setReady] = useState(!!win)
  const [boardDrawn, setBoardDrawn] = useState(!!win)
  const [playersDrawn, setPlayersDrawn] = useState(!!win)
  const { onPickChoosablePlanet } = usePlayerActions()
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    if (iFrameRef.current) {
      windowRef.current = iFrameRef.current.contentWindow ?? undefined
    }
  }, [])

  const sendMessage = useCallback((data: Action) => new Promise((res) => {
    data.id = Math.random().toString(16).slice(2)
    promisesRef.current[data.id] = res
    windowRef.current?.postMessage(JSON.stringify(data), '*')
  }), [])

  const focusPlayer = useCallback((playerId: string) => {
    sendMessage({ type: 'focus_ship', payload: playerId })
  }, [sendMessage])

  const focusPlanet = useCallback((planet: string) => {
    sendMessage({ type: 'focus_planet', payload: planet })
  }, [sendMessage])

  const onHotkey = useCallback((key: string) => {
    switch (key) {
    case 'F':
      dispatch(setFullscreen(!fullscreenRef.current))
      break
    case 'M':
      dispatch(setMap(!mapRef.current))
      break
    case 'Space':
      if (!uidRef.current) return
      focusPlayer(uidRef.current)
      break
    default:
    }
  }, [dispatch, focusPlayer])

  const onMessage = useCallback((data: MessageEvent) => {
    if (!data.data) return
    if (data.data.startsWith('setImmediate')) return
    const action = JSON.parse(data.data) as Required<Action>
    switch (action.type) {
    case 'ping':
      sendMessage({ type: 'pong' })
      break
    case 'ready':
      setReady(true)
      break
    case 'complete':
      promisesRef.current[action.id](action.payload)
      delete promisesRef.current[action.id]
      break
    case 'on_click_planet':
      onPickChoosablePlanet(action.payload)
      break
    case 'copy_to_clipboard':
      navigator.clipboard.writeText(action.payload)
      break
    case 'hotkey':
      onHotkey(action.payload)
      break
    default:
      break
    }
  }, [sendMessage, onPickChoosablePlanet, onHotkey])

  const onPopout = useCallback(() => {
    setReady(false)
    setBoardDrawn(false)
    setPlayersDrawn(false)
    setPoppedOut(p => !p)
    if (sessionStorage.getItem('poppedout')) {
      sessionStorage.removeItem('poppedout')
      if (!windowRef.current?.closed) {
        windowRef.current?.close()
      }
      setImmediate(() => {
        windowRef.current = iFrameRef.current?.contentWindow ?? undefined
      })
    } else {
      sessionStorage.setItem('poppedout', 'true')
      const win = window.open(
        '/godot-out/PrometheusBoard.html',
        '_blank',
        'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=600, height=600',
      ) ?? undefined
      if (win) {
        win.name = 'Prometheus'
      }
      win?.addEventListener('beforeunload', () => {
        sessionStorage.removeItem('poppedout')
        setPoppedOut(false)
        windowRef.current = undefined
      })
      windowRef.current = win
    }
  }, [])

  useEffect(() => {
    if (!ready || !board || boardDrawn) {
      return
    }

    sendMessage({ type: 'hydrate_board', payload: board })
      .then(() => setBoardDrawn(true))
  }, [ready, board, boardDrawn, sendMessage])

  useEffect(() => {
    if (!boardDrawn || playersDrawn) {
      return
    }

    setPlayersDrawn(true)

    sendMessage({ type: 'draw_ships', payload: players })
  }, [boardDrawn, playersDrawn, players, sendMessage])

  useEffect(() => {
    if (!boardDrawn) {
      return
    }

    sendMessage({ type: 'set_choosable_planets', payload: Object.keys(choosable ?? {}) })
  }, [boardDrawn, choosable, sendMessage])

  useEffect(() => {
    if (!boardDrawn || !edit) {
      return
    }

    sendMessage({ type: 'set_edit_mode' })
  }, [boardDrawn, edit, sendMessage])

  // Effect for moving players around the board
  useEffect(() => {
    if (!players) return
    for (const [id, status] of Object.entries(players)) {
      if (status.dead) continue

      const last = lastShipPositionRef.current[id]
      if (!last) {
        lastShipPositionRef.current[id] = status.current_planet
        continue
      }

      if (status.current_planet !== last) {
        lastShipPositionRef.current[id] = status.current_planet
        sendMessage({ type: 'move_ship', payload: [id, status.current_planet] })
      }
    }
  }, [players, sendMessage])

  // Effect for drawing planet ownership
  useEffect(() => {
    if (!players || !boardDrawn) return
    for (const [id, status] of Object.entries(players)) {
      if (status.dead) continue

      if (!ownedPlanetsRef.current[id]) {
        ownedPlanetsRef.current[id] = new Set<string>()
      }

      for (const planet of status.planets) {
        if (ownedPlanetsRef.current[id].has(planet)) continue

        ownedPlanetsRef.current[id].add(planet)
        sendMessage({ type: 'set_owner', payload: [planet, status.color] })
      }
    }
  }, [players, sendMessage, boardDrawn])

  const currentWindow = poppedOut ? windowRef.current : window
  useEffect(() => {
    const bfu = () => {
      if (currentWindow !== window && currentWindow?.closed) {
        sessionStorage.removeItem('poppedout')
      }
    }
    currentWindow?.addEventListener('message', onMessage)
    window.addEventListener('beforeunload', bfu)
    return () => {
      currentWindow?.removeEventListener('message', onMessage)
      window.removeEventListener('beforeunload', bfu)
    }
  }, [onMessage, currentWindow])

  const value = useMemo(() => ({
    iFrameRef,
    focusPlayer,
    focusPlanet,
    onPopout,
    poppedOut,
  }), [iFrameRef, focusPlayer, focusPlanet, onPopout, poppedOut])

  return (
    <GameBoardContext.Provider value={value}>
      {children}
    </GameBoardContext.Provider>
  )
}
