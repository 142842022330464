import React, { useMemo, useState } from 'react'
import { Popup, Menu, Icon, Button } from 'semantic-ui-react'
import { translateResource, translateResourceColor } from '../../../../utils/translateResource'
import { useSelector } from '../../../../hooks/useSelector'
import { BulkMinePurchaseModal } from './BulkMinePurchase'
import { resources } from '../GalacticTrader/GalacticTrader'
import { truncateCash } from '../../../../utils/truncateCash'
import usePlayerActions from '../../hooks/usePlayerActions'
import { getResourceUsageForecast } from '../../../../utils/getResourceUsageForecast'

export const ResourceHoverOver = () => {
  const planets = useSelector(s => s.game.sheet?.planets) || {}
  const sheet_resources = useSelector(s => s.game.sheet?.resources)
  const cash = useSelector(s => s.game.sheet?.cash)
  const [resource, setResource] = useState('')
  const { onUpgradePlanet } = usePlayerActions()

  const { maximum_mines, usage_forecast, current_mines, sheet_resources2, current_barriers } = useMemo(() => {
    return getResourceUsageForecast(planets, sheet_resources, cash)
  }, [planets, sheet_resources, cash])

  return (
    <>
      <BulkMinePurchaseModal
        open={!!resource}
        setOpen={() => setResource('')}
        resource={resource}
        mineCount={current_mines[resource] || 0}
        mineMax={maximum_mines[resource] || 0}
      />
      <Popup hoverable trigger={(
        <Menu.Item>
          <Icon name = "factory" color="yellow" />
          Mines
        </Menu.Item>
      )}>
        <div style={{ width: '600px' }} className="cr-breakdown">
          <div className="cr-header" style={{ justifyContent: 'center', textAlign: 'center' }}>
            Mine Counts
          </div>
          <div className="cr-content">
            <div className={'columns bold'}>Resource</div>
            <div className={'columns bold'}>Current/Max</div>
            <div className={'columns bold'}>Use Forecast</div>
            <div className={'columns bold'}>Deficit/Surplus</div>
            <div className={'columns bold'}>Barriers</div>
          </div>
          {resources.map(r => {
            return (
              <div key={r} className="cr-content">
                <div className={'columns'}>
                  <Button onClick={() => !!maximum_mines[r] && setResource(r)} size='tiny' color={translateResourceColor(r)} fluid>
                    {translateResource(r)}
                  </Button>
                </div>
                <div className={'columns'} style={{ color: current_mines[r] === maximum_mines[r] ? 'green' : 'black' }}>
                  <Button style={{ size:'tiny' }} onClick={() => onUpgradePlanet([r, Math.ceil(maximum_mines[r] / 4)], 'bulk_mine')}
                    key={'someKey'} // this is just a key, any string I want I believe, so can replace this with whatever we like (tried adding my own string and its fine!)
                    className={'padded'}
                  >
                      +
                  </Button>
                  {truncateCash(current_mines[r], { raw: true })}/{truncateCash(maximum_mines[r], { raw: true })}
                </div>
                <div className={'columns'}>{truncateCash(usage_forecast[r], { raw: true })}</div>
                <div className={'columns'}><span style={{ color: sheet_resources2[r] - usage_forecast[r] < 0 ? 'red' : 'green' }}>{truncateCash(sheet_resources2[r] - usage_forecast[r], { raw: true })}</span></div>
                <div className={'columns'} style={{ color: current_mines[r] - current_barriers[r] > 0 ? 'red' : 'green' }}>
                  <Button style={{ size:'tiny' }} onClick={() => onUpgradePlanet([r, maximum_mines[r]], 'bulk_barrier')}
                    key={'someKey'} // this is just a key, any string I want I believe, so can replace this with whatever we like (tried adding my own string and its fine!)
                    className={'padded'}
                  >
                    +
                  </Button>
                  <div style={{ alignItems: 'right' }}>
                    {truncateCash(current_barriers[r], { raw: true })}/{truncateCash(maximum_mines[r], { raw: true })}
                  </div>

                </div>
              </div>
            )
          })}
        </div>
      </Popup>
    </>
  )
}
