import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from './useSelector'
import { useSocket } from './useSocket'

export const useJoinGameRoom = () => {
  const socket = useSocket()
  const connected = useSelector(s => s.auth.connected)
  const { id } = useParams<any>()

  useEffect(() => {
    if (!socket || !socket.authenticated || !connected || !id) {
      return
    }

    socket.emit('join_game', id)

    return () => {
      socket.emit('leave_game')
    }
  }, [socket, id, connected])
}
