import React, { useCallback, useMemo, useState } from 'react'
import './PlanetDetailsDisplayDeed.scss'
import { PlanetDeed, GameBoard, OwnedPlanet, UpgradeCost, ResourceCosts } from '../../../../../types'
import translateOrbitingPlanet from '../../../../utils/translateOrbitingPlanet'
import formatCost from '../../../../utils/formatCost'
import { translateResource } from '../../../../utils/translateResource'
import translateOrbitingPlanetColorRaw from '../../../../utils/translateOrbitingPlanetColorRaw'
import classNames from 'classnames'
import usePlayerActions from '../../hooks/usePlayerActions'
import PromiseDiv from '../../../../components/PromiseDiv'
import { MineCostPopup } from './MineCostPopup'
import { useSelector } from '../../../../hooks/useSelector'
import { useDispatch } from 'react-redux'
import { pushError } from '../../../../reducers/game/game'
import { truncateCash } from '../../../../utils/truncateCash'
import { Icon } from 'semantic-ui-react'
import { MinePurchaseModal } from './MinePurchaseModal'
import { round, cloneDeep } from 'lodash'
import { probability } from './calculateProbabilityofRolls'

interface PlanetDetailsDisplayDeedProps {
  deed: PlanetDeed
  planet: keyof GameBoard
  ownedPlanet?: OwnedPlanet
  owned: boolean
}

export const PlanetDetailsDisplayDeed = ({ deed, planet, ownedPlanet, owned }: PlanetDetailsDisplayDeedProps) => {
  const {
    onUpgradePlanet,
  } = usePlayerActions()
  const dispatch = useDispatch()
  const mine_costs = useSelector(s => s.game.metadata?.mine_costs)
  const cost_per_unit = useSelector(s => s.game.metadata?.strength_cost || 1)
  let prime_rate = useSelector(s => s.game.metadata?.prime_rate) || 0
  prime_rate += useSelector(s => s.game.sheet?.credit_risk_adjusted_rate) || 0
  const barrier_costs = useSelector(s => s.game.metadata?.disaster_defence_costs)
  const resource_inflation_rates = useSelector(s => s.game.metadata?.resource_costs_growth_rates) || {}
  const resource_current_prices = useSelector(s => s.game.metadata?.resource_costs) || {}
  const resource_init_game_prices = useSelector(s => s.game.metadata?.resource_costs_init) || {}
  const planet_rolls_count = useSelector(s => s.game.metadata?.planet_rolls_count) || 0
  const metadata_pop_cost = useSelector(s => s.game.metadata?.population_cost) || 0
  const resource_inflation_turn = useSelector(s => s.game.metadata?.resource_inflation_turn) || 0
  const disabled_planet_tokens = useSelector(s => s.game.options?.disable_planet_tokens) || 0
  const tile_upgrades = useSelector(s => s.game.metadata?.tile_upgrades) || []

  const turn = useSelector(s => s.game.metadata?.turn) || 1
  const population_inflation = useSelector(s => s.game.metadata?.population_cost_growth_rate) || 1
  const [region_info] = planet.split(':')
  const [orbiting_planet] = region_info.split('|')
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [open4, setOpen4] = useState(false)
  const [open5, setOpen5] = useState(false)
  const [disaster_open1, setDisasterOpen1] = useState(false)
  const [disaster_open2, setDisasterOpen2] = useState(false)
  const [disaster_open3, setDisasterOpen3] = useState(false)
  const [disaster_open4, setDisasterOpen4] = useState(false)
  const [disaster_open5, setDisasterOpen5] = useState(false)

  function findStrengthCost(strength: number) {
    const strengthCost: UpgradeCost = {
      cash: (strength || 1) * cost_per_unit,
    }
    return strengthCost
  }

  const inflation_turns = Math.max(turn - resource_inflation_turn, 0)
  // console.log(inflation_turns, 'inflation turn')
  // console.log(resource_inflation_turn, 'resource inflation turn')

  let DIAGNOSTIC_MODE = false // Use diagnostic mode to calculate ROI based on current year via static calculation rather than based on metadata's current state
  // //Disable diagnostic mode during regular gameplay so that ROI is based on the metadata's resource prices

  const { resource_costs, pop_cost } = useMemo(() => {
    let _pop_cost = 0
    if (DIAGNOSTIC_MODE) {
      _pop_cost = 100 * (population_inflation ** inflation_turns)
    } else {
      _pop_cost = metadata_pop_cost
    }

    let _resource_costs: ResourceCosts
    if (DIAGNOSTIC_MODE) {
      _resource_costs = cloneDeep(resource_init_game_prices)
      for (const [resource, cost] of Object.entries(_resource_costs)) {
        cost.cash = (cost.cash || 0) * (resource_inflation_rates[resource] || 0) ** inflation_turns
        // console.log('cost.cash', cost.cash)
      }
    } else {
      _resource_costs = resource_current_prices
    }

    return {
      pop_cost: _pop_cost,
      resource_costs: _resource_costs,
    }
  }, [resource_init_game_prices, population_inflation, resource_inflation_rates, inflation_turns, metadata_pop_cost, DIAGNOSTIC_MODE, resource_current_prices])
  // ////////////////////////////////////////////////////////////////////// resource_inflation_rates - add this to the react hook when trying to see no-recession prices

  function findROI(deed: PlanetDeed, planet: any) {
    let years_per_level = 2
    let buy_cost = pop_cost * (deed.levels[0].cost.population || 0) + (deed.levels[0].cost.cash || 0) + (deed.levels[0].cost.resources?.Wa || 0) * (resource_costs['Wa'].cash || 0)
    if (!disabled_planet_tokens) {
      if (ownedPlanet) {
        let p: number = probability(planet_rolls_count, ownedPlanet.tile)
        years_per_level = (1 / p)
      } else {
        let p: number = probability(planet_rolls_count, 8)
        years_per_level = (1 / p)
      }
    }
    if (disabled_planet_tokens && ownedPlanet) {
      let tile_upgrade_purchases = 0
      const index = tile_upgrades.findIndex(x => x.from_numbers[0] === ownedPlanet.tile)
      for (let i = 0; i < index; i++) {
        if (tile_upgrades[i].cost.cash) {
          tile_upgrade_purchases += (tile_upgrades[i].cost.cash || 0)
        }
        const TU = tile_upgrades[i].cost.resources
        if (TU) {
          for (const [res, qt] of Object.entries(TU)) {
            tile_upgrade_purchases += ((resource_costs[res].cash || 0) * qt)
          }
        }
      }
      buy_cost += tile_upgrade_purchases
    }

    if (disabled_planet_tokens) {
      if (ownedPlanet) {
        years_per_level = (ownedPlanet.tile) // use the base turns/lvl
      } else {
        years_per_level = (tile_upgrades[0].from_numbers[0]) // use the base turns/lvl
      }
    }
    let cashflows: number[] = []

    cashflows.push(buy_cost * -1)
    let current_level = 0
    let remainder = 0
    let current_remainder = 0
    deed.levels.forEach((level) => {
      if (remainder > 0 || current_remainder > 0) {
        current_remainder = remainder
        remainder = 0
      }
      for (let i = 0; i + current_remainder < years_per_level; i++) {
        if (years_per_level - i - current_remainder < 1) {
          remainder += years_per_level - i - current_remainder
        }
        let cashflow = 0
        if (i === 0) {
          let rez_cost = 0
          for (const [rez, qty] of Object.entries(level.cost.resources || {})) {
            let turns_till_level_up = (current_level + 1) * years_per_level // (1/ probability of a roll) X  = total turns
            if (turn < resource_inflation_turn) {
              turns_till_level_up = Math.max(0, turns_till_level_up - (resource_inflation_turn - turn))
            }
            // console.log('set resource', rez, current_level)
            rez_cost += (qty * (resource_costs[rez].cash || 0) * -1 * resource_inflation_rates[rez] ** turns_till_level_up)
          }
          cashflow += rez_cost
        }
        cashflow += deed.levels[current_level].pop
        cashflows.push(cashflow)
      }
      current_level += 1
    })

    for (let i = 0; i < 40; i++) { // add perpetual cashflows
      if (deed.levels[deed.levels.length - 1].pop) {
        cashflows.push(deed.levels[deed.levels.length - 1].pop)
      }
    }
    let count = 0
    // console.log(cashflows)
    let final_value2 = round(IRRCalc(cashflows, count), 2)
    if (DIAGNOSTIC_MODE && final_value2 > 7) {
      if (planet === 'α:Khomsa' || planet === 'K|α:Erriapus' || planet === 'O|α:Bergelmir' || planet === 'β:Chaldene'
          || planet === 'τ:Harriot' || planet === 'Q|α:Beirut' || planet === 'ξ:Fortitudo') {
        console.log(final_value2, planet, ' growth: ', deed.growth)
      }
    }
    return final_value2
  }

  const onPurchaseMine = useCallback((i: number, current: number, max: number) => {
    // () => onUpgradePlanet([planet, i], 'mine').catch(e => dispatch(pushError({ message: e.message, type: 'error' })))
    if (current === 0 && max === 1) {
      return onUpgradePlanet([planet, i, 1], 'mine')
    } else if (current === 1 && max === 1) {
      return onUpgradePlanet([planet, i, -1], 'mine')
    }

    if (i === 0) {
      setOpen1(true)
    } else if (i === 1) {
      setOpen2(true)
    } else if (i === 2) {
      setOpen3(true)
    } else if (i === 3) {
      setOpen4(true)
    } else if (i === 4) {
      setOpen5(true)
    }
  }, [planet, onUpgradePlanet])

  const onPurchaseBarrier = useCallback((i: number, current: number, max: number) => {
    // () => onUpgradePlanet([planet, i], 'mine').catch(e => dispatch(pushError({ message: e.message, type: 'error' })))
    if (current === 0 && max === 1) {
      return onUpgradePlanet([planet, i, 1], 'barrier_modal')
    } else if (current === 1 && max === 1) {
      return onUpgradePlanet([planet, i, -1], 'barrier_modal')
    }

    if (i === 0) {
      setDisasterOpen1(true)
    } else if (i === 1) {
      setDisasterOpen2(true)
    } else if (i === 2) {
      setDisasterOpen3(true)
    } else if (i === 3) {
      setDisasterOpen4(true)
    } else if (i === 4) {
      setDisasterOpen5(true)
    }
  }, [planet, onUpgradePlanet])

  if (!mine_costs || !barrier_costs) {
    return null
  }

  if (owned && !ownedPlanet) {
    return null
  }

  return (
    <div className="pr-wrapper">
      <div className="pr-header details" style={{ backgroundColor: translateOrbitingPlanetColorRaw(orbiting_planet), color: translateOrbitingPlanetColorRaw(orbiting_planet, true) }}>
        <div className="pr-hcolumn orbit">
          {translateOrbitingPlanet(orbiting_planet)}
        </div>
        <div className="pr-hcolumn cost">
          {truncateCash(deed.levels[0].cost?.cash || 0)}
        </div>
        <div className="pr-hcolumn growth" style={{ flex: 2 }}>
          Return: {findROI(deed, planet)}%
          {/* Growth: {Math.round(deed.growth * 100)}% */}

        </div>
      </div>
      <div className="pr-header">
        <div className="pr-hcolumn">
          Lvl
        </div>
        <div className="pr-hcolumn left">
          Req
        </div>
        <div className="pr-hcolumn left">
          Pop
        </div>
        <div className="pr-hcolumn left">
          Loan
        </div>
        <div className="pr-hcolumn left">
          Cost
        </div>
        <div className="pr-hcolumn">
          Rate
        </div>
      </div>
      {deed.levels.map((level, i) => (
        <div
          className={classNames('pr-row', {
            current: owned && ownedPlanet?.level === i,
            locked: owned && ownedPlanet?.levelLock === i && (ownedPlanet?.level !== i),
            upgraded: owned && ownedPlanet?.level === i && (ownedPlanet?.levelsGained || 0) > 0,
            downgraded: owned && ownedPlanet?.level === i && (ownedPlanet?.levelsGained || 0) < 0,
          })}
          key={level.pop}
        >
          <PromiseDiv onClick={() => owned && onUpgradePlanet([planet, i], 'lock')} className="pr-row-chunk-details">
            <div className="pr-column">
              {owned && (ownedPlanet?.levelLock !== -1) && (ownedPlanet?.levelLock || 0) <= i ? (
                <Icon name="lock" style={{
                  position: 'relative',
                  top: '-4px',
                  right: '-2px',
                }} />
              ) : (
                i + 1
              )}
            </div>
            <div className="pr-column left">
              {level.cost && formatCost(level.cost, { ignoreCash: true, removeDigits: true })}
            </div>
            <div className="pr-column left">
              {truncateCash(level.pop, { raw: true })}
            </div>
          </PromiseDiv>
          <PromiseDiv onClick={() => owned && onUpgradePlanet([planet, i], 'loc')} className={classNames('pr-row-chunk-loan', { 'loaned drop-shadow': owned && ownedPlanet?.loan === i })}>
            <div className="pr-column left">
              {truncateCash(level.loan)}
            </div>
            <div className="pr-column left">
              {truncateCash(level.loan_cost)}
            </div>
            <div className="pr-column">
              {(((level.loan_cost + (level.loan * prime_rate)) / level.loan) * 100).toFixed(1)}%
            </div>
          </PromiseDiv>
        </div>
      ))}
      <div className="pr-planet-attributes">
        <div className="pr-pa-item">

          {owned && ownedPlanet && ownedPlanet.mines.map((mine, i) => {
            const m = Object.keys(mine)[0]

            if (!m) {
              return null
            }
            let display_mine_text = translateResource(m)
            if (deed.mine_production_maximum[m] > 1) {
              display_mine_text = ownedPlanet.deed.disasterBarrierCountCurrent[i] + '/' + truncateCash(deed.mine_production_maximum[m], { raw: true, removeDigits: true }) + ' ' + m
            }

            return (
              <React.Fragment key={`${m}${i} barrier`}>
                <MineCostPopup key={`${m}${i} barrier`} cost={barrier_costs[m]}>
                  <PromiseDiv key={`${m}${i} barrier`} onClick={() => onPurchaseBarrier(i, ownedPlanet.deed.disasterBarrierCountCurrent[i], deed.mine_production_maximum[m])?.catch((e) => dispatch(pushError({
                    message: e.message,
                    type: 'error',
                  })))} className={classNames('mine', { [`${m}-bg drop-shadow`]: ownedPlanet.deed.disasterBarrierCountCurrent[i] > 0 })}>
                    { display_mine_text } Mine Barrier
                  </PromiseDiv>
                </MineCostPopup>
                <MinePurchaseModal
                  open={i === 0 ? disaster_open1 : i === 1 ? disaster_open2 : i === 2 ? disaster_open3 : i === 3 ? disaster_open4 : disaster_open5}
                  setOpen={i === 0 ? setDisasterOpen1 : i === 1 ? setDisasterOpen2 : i === 2 ? setDisasterOpen3 : i === 3 ? setDisasterOpen4 : setDisasterOpen5}
                  planet={planet}
                  index={i}
                  resource={m}
                  building_type={'Barrier'}
                />
              </React.Fragment>
            )
          })}
          {!owned && deed.mines.map((mine, i) => {
            return (
              <PromiseDiv key={`${mine}${i}`} className="mine">
                {mine + ' Mine Barriers'}
              </PromiseDiv>
            )
          })}
        </div>
        <div className='pr-pa-item'>

          <MineCostPopup key={'someKey'} cost={findStrengthCost(deed.strength || deed.levels[0].strength)}>
            <PromiseDiv
              onClick={(owned && ownedPlanet) ? () => onUpgradePlanet([planet, 'someKey'], 'strength').catch((e) => dispatch(pushError({ message: e.message, type: 'error' }))) : () => {}}
              key={'someKey'} // this is just a key, any string I want I believe, so can replace this with whatever we like (tried adding my own string and its fine!)
              // eslint-disable-next-line no-useless-computed-key
              className={classNames('disaster', { ['Ag-bg drop-shadow']: owned && ownedPlanet && ownedPlanet.barriers.indexOf('deed.disasterBarriers[0]' as any) !== -1 })}
            >
              { truncateCash(deed.strength || deed.levels[ownedPlanet?.level || 0].strength, { raw: true }) + '🛡️'}
            </PromiseDiv>
          </MineCostPopup>

        </div>
        <div className="pr-pa-item">
          {owned && ownedPlanet && ownedPlanet.mines.map((mine, i) => {
            const m = Object.keys(mine)[0]

            if (!m) {
              return null
            }

            let display_mine_text = translateResource(m)
            if (deed.mine_production_maximum[m] > 1) {
              display_mine_text = truncateCash(mine[m], { raw: true, removeDigits: true }) + '/' + truncateCash(deed.mine_production_maximum[m], { raw: true, removeDigits: true }) + ' ' + translateResource(m)
            }

            return (
              <React.Fragment key={`${m}${i}`}>
                <MineCostPopup key={`${m}${i}`} cost={mine_costs[m]}>
                  <PromiseDiv key={`${m}${i}`} onClick={() => onPurchaseMine(i, mine[m], deed.mine_production_maximum[m])?.catch((e) => dispatch(pushError({
                    message: e.message,
                    type: 'error',
                  })))} className={classNames('mine', { [`${m}-bg drop-shadow`]: !!mine[m] })}>
                    { display_mine_text }
                  </PromiseDiv>
                </MineCostPopup>
                <MinePurchaseModal
                  open={i === 0 ? open1 : i === 1 ? open2 : i === 2 ? open3 : i === 3 ? open4 : open5}
                  setOpen={i === 0 ? setOpen1 : i === 1 ? setOpen2 : i === 2 ? setOpen3 : i === 3 ? setOpen4 : setOpen5}
                  planet={planet}
                  index={i}
                  resource={m}
                />
              </React.Fragment>
            )
          })}
          {!owned && deed.mines.map((mine, i) => {
            let display_mine_text = translateResource(mine)
            if (deed.mine_production_maximum[mine] > 1) {
              display_mine_text = truncateCash(deed.mine_production_maximum[mine], { raw: true, removeDigits: true }) + ' ' + translateResource(mine)
            }
            return (
              <PromiseDiv key={`${mine}${i}`} className="mine">
                {display_mine_text}
              </PromiseDiv>
            )
          })}
        </div>
      </div>
    </div>
  )
}

function IRRCalc(CArray: number[], count: number) {
  // return 5;
  let min = 0.0
  let max = 1.0
  let guess = 0
  let NPV = 0
  let max_error = CArray[CArray.length - 1] * .001 // how far away from 0 can NPV be and we're still happy
  // console.log(max_error)
  // console.log(CArray)
  // return 15;
  do {
    guess = (min + max) / 2
    // console.log(guess, 'guess')
    NPV = 0
    // console.log(guess, 'guess')
    count += 1
    // console.log(count)
    for (var j = 0; j < CArray.length; j++) {
      NPV += CArray[j] / Math.pow((1 + guess), j)
      // console.log(NPV, 'NPV')
    }

    if (NPV > 0) {
      min = guess
      // console.log(min, 'min')
    }
    else {
      max = guess
      // console.log(max, 'max')
    }
    if (count > 40) {
      // console.log(count)
      // console.log('npv', NPV)
      // console.log('guess', guess)
      // console.log('min', min)
      // console.log('max', max)
      return 0
    }
  } while (Math.abs(NPV) > max_error)
  // } while(Math.abs(NPV) > 0.000001); //very accurate
  return guess * 100
}
