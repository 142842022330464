import { useCallback, useEffect } from 'react'
import * as firebase from 'firebase/app'
import { GameBoard, GalacticTraderOffer, PrivateTrade, GameOptions, Sheet, GameMetadata } from '../../../../types'
import { useSocket } from '../../../hooks/useSocket'
import { loadGameArgs } from '../../../../types/arguments/loadGameArgs'

const db = firebase.firestore()

export const getUniqueID = () => db.collection('g').doc().id

type upgradeTypes = 'loc_max' | 'loc_rate' | 'bond_rate' | 'bond_max' | 'population_max'
type modifyCashAccountTypes = 'withdraw_loc' | 'withdraw_bonds' | 'deposit_bonds' | 'deposit_loc' | 'balance_cash'
type planetUpgradeActions = 'purchase' | 'loc' | 'all_planet_loans' | 'deposit_all_planet_loans' | 'mine' | 'bulk_mine' | 'barrier' | 'barrier_modal' | 'bulk_barrier' | 'starbase' | 'starbase-refund' | 'tile' | 'downgrade_tile' | 'lock' | 'strength' | 'submit_quest' | 'activate_reward'
type conversionTypes = 'Wa' | 'Au' | 'Oil' | 'population' | 'U' | 'Cu' | 'Ag' | 'Dia' | 'Ti' | 'Fe' | 'Pt'
type privateTradeTypes = 'offer' | 'accept' | 'revoke'

let socket: SocketIOClient.Socket

const builder = (endpoint: string) => (data?: any): Promise<any> => {
  return new Promise((res, rej) => {
    const requestId = getUniqueID()
    const handleError = (data: any) => {
      rej(data)
      socket.off(`response-${requestId}`, handleSuccess)
    }
    const handleSuccess = (data: any) => {
      res({ data })
      socket.off(`response-error-${requestId}`, handleError)
    }
    socket.once(`response-${requestId}`, handleSuccess)
    socket.once(`response-error-${requestId}`, handleError)
    socket.emit('invoke', requestId, [endpoint, data])
  })
}

const readyUp = builder('readyUp')
const leaveGame = builder('leaveGame')
const endGame = builder('endGame')
const leaveLobby = builder('leaveLobby')
const deleteGame = builder('deleteGame')
const upgrade = builder('upgrade')
const modifyCashAccounts = builder('modifyCashAccounts')
const getGameBoard = builder('getGameBoard')
export const pickChoosablePlanet = builder('pickChoosablePlanet')
const commitTrade = builder('commitTrade')
const upgradePlanet = builder('upgradePlanet')
const startGame = builder('startGame')
const joinGame = builder('joinGame')
const loadGame = builder('loadGame')
const createGame = builder('createGame')
const convertResource = builder('convertResource')
const exportGame = builder('exportGame')
const offerTrade = builder('offerTrade')
const forceTurnEnd = builder('forceTurnEnd')
const setGameOptions = builder('setGameOptions')
const adminSaveGame = builder('adminSaveGame')
const pauseGame = builder('pauseGame')
const addTime = builder('addTime')

export default function usePlayerActions() {
  const sock = useSocket()

  useEffect(() => {
    if (sock) {
      socket = sock
    }
  }, [sock])

  const onReady = useCallback(() => readyUp(), [])
  const onLeaveGame = useCallback(() => leaveGame(), [])
  const onEndGame = useCallback(() => endGame(), [])
  const onLeaveLobby = useCallback(() => leaveLobby(), [])
  const onDeleteGame = useCallback(() => deleteGame(), [])
  const onUpgrade = useCallback((type: upgradeTypes) => upgrade(type), [])
  const onModifyCashAccounts = useCallback((type: modifyCashAccountTypes) => modifyCashAccounts(type), [])
  const onGetGameBoard = useCallback((id?: string) => getGameBoard(id), [])
  const onPickChoosablePlanet = useCallback((planet: (keyof GameBoard)) => pickChoosablePlanet(planet), [])
  const onCommitTrade = useCallback((tradeOffer: GalacticTraderOffer) => commitTrade(tradeOffer), [])
  const onUpgradePlanet = useCallback((planet: any, action: planetUpgradeActions) => upgradePlanet([planet, action]), [])
  const onStartGame = useCallback(() => startGame(), [])
  const onJoinGame = useCallback((arg) => joinGame(arg), [])
  const onCreateGame = useCallback((arg) => createGame(arg), [])
  const onForceTurnEnd = useCallback(() => forceTurnEnd(), [])
  const onConvertResource = useCallback((type: conversionTypes, quantity: number) => convertResource([type, quantity]), [])
  const onExportGame = useCallback(() => exportGame(), [])
  const onOfferTrade = useCallback((type: privateTradeTypes, offer: PrivateTrade) => offerTrade([type, offer]), [])
  const onSetGameOptions = useCallback((options: Partial<GameOptions>) => setGameOptions(options), [])
  const onAdminSaveGame = useCallback((sheets: Sheet[], metadata: GameMetadata) => adminSaveGame({ sheets, metadata }), [])
  const onPauseGame = useCallback((paused: boolean) => pauseGame(paused), [])
  const onAddTime = useCallback((time: number) => addTime(time), [])
  const onLoadGame = useCallback((args: loadGameArgs) => loadGame(args), [])

  return {
    onReady,
    onLeaveGame,
    onEndGame,
    onLeaveLobby,
    onDeleteGame,
    onUpgrade,
    onModifyCashAccounts,
    onGetGameBoard,
    onPickChoosablePlanet,
    onCommitTrade,
    onUpgradePlanet,
    onStartGame,
    onForceTurnEnd,
    onJoinGame,
    onCreateGame,
    onConvertResource,
    onExportGame,
    onOfferTrade,
    onSetGameOptions,
    onAdminSaveGame,
    onPauseGame,
    onLoadGame,
    onAddTime,
  }
}
