import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyDkbebN5si_9f8MZOIUzltruZGx7oZfiDo',
  authDomain: 'prometheus-game.firebaseapp.com',
  databaseURL: 'https://prometheus-game.firebaseio.com',
  projectId: 'prometheus-game',
  storageBucket: 'prometheus-game.appspot.com',
  messagingSenderId: '608286291522',
  appId: '1:608286291522:web:e653f232b99b39f7d61fdb',
  measurementId: 'G-JETWE4Q4E5',
}

firebase.initializeApp(firebaseConfig)
