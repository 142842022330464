import React, { useCallback, useEffect, useState } from 'react'
import update from 'immutability-helper'
import { useParams } from 'react-router-dom'
import { ButtonGroup, Container, Input, Segment, Table } from 'semantic-ui-react'
import { GameSave } from '../../../types/arguments/loadGameArgs'
import { useJoinGameRoom } from '../../hooks/useJoinGameRoom'
import * as firebase from 'firebase/app'
import useRouting from '../../hooks/useRouting'
import { fixSaveGame } from './utils/fixSaveGame'
import { useSelector } from '../../hooks/useSelector'
import usePlayerActions from '../SheetView/hooks/usePlayerActions'
import PromiseButton from '../../components/PromiseButton'
import { useSocket } from '../../hooks/useSocket'
import { GAME_VERSION } from '../../GAME_VERSION'

const db = firebase.firestore()

export const GameResumeScreen = () => {
  const [gameData, setGameData] = useState<GameSave>()
  const [fromServer, setFromServer] = useState(false)
  useJoinGameRoom()
  const socket = useSocket()
  const { id } = useParams<any>()
  const uid = useSelector(s => s.auth.uid) || ''
  const authorized = useSelector(s => s.auth.authorized)
  const { replace } = useRouting()
  const { onLoadGame } = usePlayerActions()
  const gameStatus = gameData?.game.status

  useEffect(() => {
    if (gameStatus === 'active') {
      replace(`/game/${id}`)()
    }
  }, [gameStatus, id, replace])

  useEffect(() => {
    if (!socket) {
      return
    }

    socket.on('set-game-data', (data: GameSave) => {
      setGameData(data)
      setFromServer(true)
    })

    socket.on('set-game-data-delta', (delta: any) => {
      setGameData(d => update(d, delta))
    })
  }, [socket])

  useEffect(() => {
    if (!id) {
      const newId = db.collection('blah').doc().id
      replace(`/games/resume/${newId}`)()
    }
  }, [id, replace])

  const onUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.addEventListener('load', (event) => {
        const result = event.target?.result
        if (typeof result === 'string') {
          onLoadGame({
            action: 'load',
            payload: fixSaveGame(JSON.parse(result) as GameSave, id, uid),
          })
        }
      })
      reader.readAsText(file)
    }
  }, [id, uid, onLoadGame])

  const onResume = useCallback(() => {
    return onLoadGame({
      action: 'resume',
    })
  }, [onLoadGame])

  const onTake = useCallback((target_player_id: string) => {
    return onLoadGame({
      action: 'take-slot',
      target_player_id,
    })
  }, [onLoadGame])

  const picked = Object.values(gameData?.metadata.players || {}).map(p => p.take_by).indexOf(uid) !== -1

  return (
    <Container>
      {!!gameData && gameData.version !== GAME_VERSION && (
        <Segment color="yellow" inverted>
          Your save file is for version <span style={{ fontWeight: 'bold' }}>{gameData.version}</span> but Prometheus is version <span style={{ fontWeight: 'bold' }}>{GAME_VERSION}</span>.
          <br /><br />
          Launching this game may cause fatal issues. Proceed at your own risk!
        </Segment>
      )}
      {!gameData && (
        <Input
          type="file"
          fluid
          accept="application/json"
          onChange={onUpload}
        />
      )}
      <Table>
        <Table.Body>
          {!!gameData && (Object.values(gameData.metadata.players).map((player) => {
            const isPicking = gameData.metadata.players_choosing_planet.indexOf(player.id) !== -1
            return (
              <Table.Row key={player.id}>
                <Table.Cell>{player.name}</Table.Cell>
                <Table.Cell style={{ fontStyle: 'italic' }}>
                  {!player.take_by && (
                    player.dead ? (
                      'This player left the game.'
                    ) : (
                      isPicking ? (
                        <span>This players slot <span style={{ color: 'red' }}>MUST</span> be filled.</span>
                      ) : (
                        <span>This players slot <span style={{ color: 'blue' }}>MAY</span> be filled.</span>
                      )
                    )
                  )}
                </Table.Cell>
                <Table.Cell>
                  <PromiseButton ignoreGameLogic onClick={() => onTake(player.id)} disabled={!authorized || player.dead || !!picked || !fromServer || !!player.take_by} size="small" color={player.take_by ? 'grey' : 'red'}>{player.take_by ? 'Taken...' : 'Take Position'}</PromiseButton>
                </Table.Cell>
              </Table.Row>
            )
          }))}
        </Table.Body>
      </Table>
      {!!gameData && fromServer && (
        <ButtonGroup>
          <PromiseButton ignoreGameLogic primary onClick={onResume}>Resume Game</PromiseButton>
        </ButtonGroup>
      )}
    </Container>
  )
}
