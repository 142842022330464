import React, { ReactNode, useMemo } from 'react'
import { Popup, Header, Icon, Menu } from 'semantic-ui-react'
import usePlayerActions from '../../hooks/usePlayerActions'
import { useSelector } from '../../../../hooks/useSelector'
import PromiseMenuItem from '../../../../components/PromiseMenuItem'
import formatCost from '../../../../utils/formatCost'
import { round } from 'lodash'
import { calcQuest } from './calculateQuestBonus'

interface LOCRateUpgradesPopupProps {
  children: ReactNode
}

export default ({ children }: LOCRateUpgradesPopupProps) => {
  let _bond_rate_upgrades = useSelector(s => s.game.options?.bond_rate_upgrades) || []
  const bonds = useSelector(s => s.game.sheet?.bonds)
  const level = bonds?.rate_level || 0
  const base_bond_rate_change = (useSelector(s => s.game.metadata?.prime_rate) || 0)
  const bond_rate_global_factor = (useSelector(s => s.game.metadata?.global_bond_rate_addition) || 0)
  const quest_bond_bonus = useSelector(s => s.game.sheet?.quests.rewards.bond_rate_bonus)

  const bond_rate_additions = base_bond_rate_change * .5 * 100 + bond_rate_global_factor * 100 + calcQuest(quest_bond_bonus || []) * 100

  const bond_rate_upgrades = useMemo(() => {
    const BRU = [..._bond_rate_upgrades]
    for (let i = 0; i < 100; i++) {
      if (_bond_rate_upgrades.length < 100) {
        const x = i + _bond_rate_upgrades.length
        const previous = BRU[BRU.length - 1].cost.resources
        BRU.push({
          rate: x + 12,
          cost: { resources: { Au: (previous?.Au || 0) * 2, Dia: (previous?.Dia || 0) * 2, U: (previous?.U || 0) * 2 } },
        })
      }
    }
    return BRU.slice(Math.max(level, 0), level + 6)
  }, [_bond_rate_upgrades, level])

  const {
    onUpgrade,
  } = usePlayerActions()

  return (
    <Popup basic hoverable mouseEnterDelay={400} position="bottom right" trigger={children}>
      <Header>Bond Rate Upgrades</Header>
      <Menu vertical style={{ width: '380px', maxHeight: '512px', overflowY: 'auto' }}>
        {bond_rate_upgrades.map(({ cost, rate }, i) => {
          const disabled = i > 2
          const isNextUpgrade = i === 1
          const isUpgraded = i < 1
          const className = isUpgraded ? 'strike' : ''
          let onClick
          if (isNextUpgrade) {
            onClick = () => onUpgrade('bond_rate')
          }
          return (
            <PromiseMenuItem key={rate} ignoreGameLogic disabled={disabled} onClick={onClick}>
              {isNextUpgrade && (<Icon name="angle double up" color="green" size="large" />)}
              <Menu.Header className={className}>Level {i + level + 1} | Rate: { round(rate + bond_rate_additions, 2) }%</Menu.Header>
              <p className={className}>{formatCost(cost)}</p>
            </PromiseMenuItem>
          )
        })}
      </Menu>
    </Popup>
  )
}
