export const probability = (rolls: number, tile: number) => {
  const index = ((tile - 2) * 11) + rolls - 1

  const probabilites =
  [
    0.03,	0.06,	0.09,	0.13,	0.18,	0.23,	0.29,	0.37,	0.49,	0.68,	1.00,
    0.06,	0.12,	0.18,	0.25,	0.33,	0.41,	0.51,	0.62,	0.76,	0.90,	1.00,
    0.08,	0.17,	0.26,	0.35,	0.45,	0.56,	0.66,	0.77,	0.88,	0.96,	1.00,
    0.11,	0.22,	0.33,	0.45,	0.56,	0.66,	0.76,	0.86,	0.93,	0.98,	1.00,
    0.14,	0.27,	0.40,	0.52,	0.64,	0.74,	0.83,	0.91,	0.96,	0.99,	1.00,
    0.17,	0.32,	0.47,	0.59,	0.71,	0.80,	0.88,	0.94,	0.98,	1.00,	1.00,
    0.14,	0.27,	0.40,	0.53,	0.64,	0.74,	0.83,	0.91,	0.96,	0.99,	1.00,
    0.11,	0.22,	0.33,	0.45,	0.56,	0.66,	0.76,	0.86,	0.93,	0.98,	1.00,
    0.08,	0.17,	0.26,	0.35,	0.45,	0.56,	0.66,	0.77,	0.88,	0.96,	1.00,
    0.06,	0.12,	0.18,	0.25,	0.33,	0.41,	0.51,	0.62,	0.76,	0.90,	1.00,
    0.03,	0.06,	0.09,	0.13,	0.18,	0.23,	0.29,	0.37,	0.49,	0.68,	1.00,
  ]

  return probabilites[index]
}
