import React, { useState, useCallback } from 'react'
import { Modal, Form, Button } from 'semantic-ui-react'
import firebase from 'firebase/app'
import { useSelector } from '../hooks/useSelector'

const db = firebase.firestore()

interface EditProfileModalProps {
  open: boolean
  onCancel: () => void
}

export default ({ open, onCancel }: EditProfileModalProps) => {
  const uid = useSelector(s => s.auth.uid)
  const [name, setName] = useState('')
  const onSubmit = useCallback(async (name) => {
    await db.collection('users').doc(uid || '').set({
      name,
    }, { merge: true }).catch(e => console.error(e))
    onCancel()
  }, [uid, onCancel])
  return (
    <Modal open={open} onClose={onCancel}>
      <Modal.Header>User Profile</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            width="5"
            size="large"
            label="Display Name (Default anonymous)"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={onCancel}>Cancel</Button>
        <Button onClick={() => onSubmit(name)} primary>Save</Button>
      </Modal.Actions>
    </Modal>
  )
}
