const colors: { [key: string]: string } = {
  J: '#f26202',
  U: '#fbbd08',
  N: '#a333c8',
  P: '#a333c8',
  E: '#21ba45',
  M: '#a333c8',
  S: '#767676',
  R: 'pink',
  ξ: '#008080',
  β: '#f26202',
  K: '#B40404',
  O: '#B404AE',
  α: '#008080',
  Q: '#1520CE',
  τ: '#1520CE',
  Z: '#1520CE',
}

const inverted: { [key: string]: string } = {
  J: 'white',
  U: 'white',
  N: 'white',
  P: 'white',
  E: 'white',
  M: 'white',
  S: 'white',
  R: 'pink',
  ξ: 'white',
  β: 'white',
  K: 'white',
  O: 'white',
  α: 'white',
  Q: 'white',
  τ: 'white',
  Z: 'white',
}

export default function translateOrbitingPlanetColorRaw(key: string, invert?: boolean): string {
  return invert ? inverted[key] : colors[key]
}
