import React, { ReactNode } from 'react'
import { Popup, Header, Icon, Menu } from 'semantic-ui-react'
import usePlayerActions from '../../hooks/usePlayerActions'
import { useSelector } from '../../../../hooks/useSelector'
import PromiseMenuItem from '../../../../components/PromiseMenuItem'
import formatCost from '../../../../utils/formatCost'
import { truncateCash } from '../../../../utils/truncateCash'

interface BondMaxUpgradesPopupProps {
  children: ReactNode
}

export default ({ children }: BondMaxUpgradesPopupProps) => {
  const bond_max_upgrades = useSelector(s => s.game.options?.bond_max_upgrades) || []
  const sheet = useSelector(s => s.game.sheet)
  const level = (sheet?.bonds.max_level) || 0
  const {
    onUpgrade,
  } = usePlayerActions()

  return (
    <Popup basic hoverable mouseEnterDelay={400} position="bottom right" trigger={children}>
      <Header>Bond Max Deposit Upgrades</Header>
      <Menu vertical style={{ width: '380px', maxHeight: '512px', overflowY: 'auto' }}>
        {bond_max_upgrades.map(({ cost, max }, i) => {
          const disabled = i > level + 1
          const isNextUpgrade = i === level + 1
          const isUpgraded = i <= level
          const className = isUpgraded ? 'strike' : ''
          let onClick
          if (isNextUpgrade) {
            onClick = () => onUpgrade('bond_max')
          }
          return (
            <PromiseMenuItem key={max} ignoreGameLogic disabled={disabled} onClick={onClick}>
              {isNextUpgrade && (<Icon name="angle double up" color="green" size="large" />)}
              <Menu.Header className={className}>Level {i + 1} | Max: {truncateCash(max + (sheet?.bonds.max_bonuses || 0))}</Menu.Header>
              <p className={className}>{formatCost(cost)}</p>
            </PromiseMenuItem>
          )
        })}
      </Menu>
    </Popup>
  )
}
