import React from 'react'
import { Card, Icon } from 'semantic-ui-react'
import { useSelector } from '../../../../hooks/useSelector'
import translateOrbitingPlanet from '../../../../utils/translateOrbitingPlanet'
import PromiseButton from '../../../../components/PromiseButton'
import usePlayerActions from '../../hooks/usePlayerActions'
import translateOrbitingPlanetColor from '../../../../utils/translateOrbitingPlanetColor'
import flatten from 'lodash/flatten'
import PlanetDetailsDisplay from './PlanetDetailsDisplay'
import { GameBoard } from '../../../../../types'

export default () => {
  const game_board = useSelector(s => s.game.game_board)
  const planet_movement_options = useSelector(s => s.game.sheet?.planet_movement_options)
  const {
    onPickChoosablePlanet,
  } = usePlayerActions()
  const rift_access = useSelector(s => s.game.sheet?.rift_access)
  if (!game_board || !rift_access) {
    return null
  }

  const rifts: (keyof GameBoard)[] = [
  ]

  for (const [rift, has_access] of Object.entries(rift_access)) {
    if (has_access) {
      rifts.push(rift as keyof GameBoard)
    }
  }

  const static_rifts = rifts.filter(r => !!game_board[r].next)
  const choice_rifts = rifts.filter(r => !!game_board[r].connected_planets)
  const choice_planets = flatten([...choice_rifts.map(r => game_board[r].connected_planets)]) as (keyof GameBoard)[]

  return (
    <>
      <Card.Content style={{ display: 'flex', alignItems: 'center' }}>
        <Card.Header as="h1" style={{ fontSize: '36px', marginTop: 0 }}>
          <Icon name="fighter jet" color="purple" />
          &nbsp;Rift
        </Card.Header>
      </Card.Content>
      {static_rifts.map(r => {
        const rift = game_board[r]

        if (!rift.next) return null

        const next_planet = rift.next[0]

        if (!next_planet) return null

        const orbit = next_planet.split(':')[0].split('|')[0]

        if (!orbit) return null

        return (
          <Card.Content key={r}>
            <Card.Header style={{ display: 'flex', alignItems: 'center', marginTop: 0, justifyContent: 'space-between' }}>
              {translateOrbitingPlanet(orbit)} Rift
              <PromiseButton ignoreGameLogic onClick={() => onPickChoosablePlanet(r)} size="mini" color={translateOrbitingPlanetColor(orbit)}>
                Warp
              </PromiseButton>
            </Card.Header>
          </Card.Content>
        )
      })}
      <Card.Content>
        <Card.Description>
          <div className="pr-wrapping-grid">
            {choice_planets.map(planet => {
              return (
                <>
                  <div />
                  <PlanetDetailsDisplay rift choice
                    style={{ marginBottom: '16px', marginRight: '6px' }}
                    planet={planet}
                    deed={planet_movement_options && planet_movement_options[planet]}
                  />
                </>
              )
            })}
          </div>
        </Card.Description>
      </Card.Content>
    </>
  )
}
