import React, { useEffect, useCallback, useRef } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import SheetHeader from './components/SheetHeader'
import { Grid } from 'semantic-ui-react'
import PlayerStatusList from './components/PlayerStatusList'
import useGame from '../GameView/hooks/useGame'
import PlayerPopulation from './components/PlayerPopulation/PlayerPopulation'
import PlayerCashInvestments from './components/PlayerCashInvestments'
import ErrorHandler from '../../components/ErrorHandler'
import { GameBoard } from './components/GameBoard'
import PlayerChoosePlanetMovement from './components/PlayerChoosePlanetMovement'
import PlayerTurnMessages from './components/PlayerTurnMessages/PlayerTurnMessages'
import GalacticTrader from './components/GalacticTrader/GalacticTrader'
import './GameSheet.scss'
import { PlayerStarbaseLoans } from './components/PlayerStarbaseLoans'
import { useSelector } from '../../hooks/useSelector'
import { useDispatch } from 'react-redux'
import { setFullscreen, setMap } from '../../reducers/game/game'
import { useFocusPlayer } from '../../hooks/useFocusPlayer'
import { PrivateTradeOffers } from './components/PrivateTradeOffers/PrivateTradeOffers'
import { WaitingOnPlayers } from './components/WaitingOnPlayers/WaitingOnPlayers'
import { useJoinGameRoom } from '../../hooks/useJoinGameRoom'
import { AdminEditingModal } from '../../components/AdminEditingModal'
import { GamePausedModal } from '../../components/GamePausedModal'

export default () => {
  useJoinGameRoom()
  const dispatch = useDispatch()
  const turn = useSelector(s => s.game.metadata?.turn) || 0
  const lastTurn = useRef(turn)
  const adminEditor = useSelector(s => s.game.metadata?.admin_editing)
  const paused = useSelector(s => s.game.metadata?.paused_by)
  const trade = useSelector(s => s.game.metadata?.trade)
  const is_fullscreen = useSelector(s => s.game.fullscreen)
  const is_map = useSelector(s => s.game.galaxy_display)
  const uid = useSelector(s => s.auth.uid) || ''
  const players = useSelector(s => s.game.metadata?.players) || {}
  const { game } = useGame()
  const { onFocusPlayer } = useFocusPlayer(players[uid])

  useEffect(() => {
    if (turn !== lastTurn.current) {
      lastTurn.current = turn
      dispatch(setMap(false))
      dispatch(setFullscreen(false))
    }
  }, [turn, trade, dispatch])

  const onHandleHotkey = useCallback((e: KeyboardEvent) => {
    if (e.keyCode === 70) {
      dispatch(setFullscreen(!is_fullscreen))
    }
    if (e.keyCode === 77) {
      dispatch(setMap(!is_map))
    }
    if (e.keyCode === 32) {
      onFocusPlayer()
    }
  }, [dispatch, is_fullscreen, is_map, onFocusPlayer])

  useEffect(() => {
    window.addEventListener('keyup', onHandleHotkey)
    return () => window.removeEventListener('keyup', onHandleHotkey)
  }, [onHandleHotkey])

  if (game?.status === 'complete') {
    window.location.pathname = '/'
  }

  return (
    <>
      {adminEditor && <AdminEditingModal name={adminEditor} />}
      {paused && <GamePausedModal name={players[paused].name} />}
      <div className="pg-game-container">
        <SheetHeader />
        <Grid style={{ flexGrow: 1 }}>
          {is_fullscreen && (
            <Grid.Column width="12">
              <Scrollbars autoHide style={{ height: '100%' }}>
                <PlayerChoosePlanetMovement owned fullscreen />
              </Scrollbars>
            </Grid.Column>
          )}
          {!is_fullscreen && (
            <>
              <Grid.Column width="3" style={{ paddingTop: 0 }}>
                <Scrollbars autoHide>
                  <div />
                  <PlayerStatusList />
                  <PrivateTradeOffers />
                  <PlayerChoosePlanetMovement owned />
                </Scrollbars>
              </Grid.Column>
              <Grid.Column width="9" style={{ paddingTop: 0 }}>
                <Scrollbars
                  autoHide
                >
                  <div />
                  <ErrorHandler />
                  <WaitingOnPlayers />
                  {trade && <GalacticTrader />}
                  <PlayerChoosePlanetMovement />
                </Scrollbars>
              </Grid.Column>
            </>
          )}
          <Grid.Column width="4" style={{ paddingTop: 0 }}>
            <Scrollbars
              autoHide
            >
              <div />
              <PlayerPopulation />
              <PlayerCashInvestments />
              <PlayerTurnMessages />
              <PlayerStarbaseLoans />
            </Scrollbars>
          </Grid.Column>
        </Grid>
        <GameBoard />
      </div>
    </>
  )
}
