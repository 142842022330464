import React from 'react'
import { Modal } from 'semantic-ui-react'
import { useSelector } from '../../../../hooks/useSelector'
import usePlayerActions from '../../hooks/usePlayerActions'
import { MinePurchaseQuantityDisplay } from '../PlayerChoosePlanetMovement/MinePurchaseQuantityDisplay'

interface BulkMinePurchaseModalProps {
  mineCount: number
  mineMax: number
  open: boolean
  setOpen: any
  resource: string
}

export const BulkMinePurchaseModal = ({ open, setOpen, resource, mineCount, mineMax }: BulkMinePurchaseModalProps) => {
  const { onUpgradePlanet } = usePlayerActions()

  const mineCost = useSelector(s => s.game.metadata?.mine_costs[resource]?.cash) || 0
  const cash = useSelector(s => s.game.sheet?.cash) || 0
  const maximumAffordedMines = Math.floor(cash / mineCost)

  return (
    <Modal basic open={open} onClose={() => setOpen(false)}>
      <MinePurchaseQuantityDisplay
        mineCost={mineCost}
        maximumAffordedMines={maximumAffordedMines}
        mineCount={mineCount}
        mineMax={mineMax}
        resource={resource}
        setOpen={setOpen}
        open={open}
        onPurchase={(quantity) => onUpgradePlanet([resource, quantity], 'bulk_mine').then(() => setOpen(false))}
      />
    </Modal>
  )
}
