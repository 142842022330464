import React, { useCallback, useState, useEffect } from 'react'
import { Header, Label, ButtonGroup, Button } from 'semantic-ui-react'
import { translateResource } from '../../../../utils/translateResource'
import PromiseButton from '../../../../components/PromiseButton'
import { useSelector } from '../../../../hooks/useSelector'
import { round } from 'lodash'
import { truncateCash } from '../../../../utils/truncateCash'

interface MinePurchaseQuantityDisplayProps {
  mineCount: number
  mineMax: number
  maximumAffordedMines: number
  mineCost: number
  resource: string
  onPurchase: (quantity: number) => Promise<any>
  open: boolean
  setOpen: any
  building_type?: string
}

export const MinePurchaseQuantityDisplay = ({
  mineCount,
  mineMax,
  maximumAffordedMines,
  mineCost,
  resource,
  onPurchase,
  open,
  setOpen,
  building_type,
}: MinePurchaseQuantityDisplayProps) => {
  const [buyAmount, setBuyAmount] = useState(0)
  const cash = useSelector(s => s.game.sheet?.cash) || 0
  const calculatedCash = round(cash - ((mineCost * (buyAmount < 0 ? .75 : 1)) * buyAmount), 2)
  const mine_increments = Math.max(round(Math.ceil(mineMax / 50), -1), 1)

  const onIncrement = useCallback((n: number) => () => {
    if (n < 0) {
      setBuyAmount(a => Math.max(-mineCount, a + n))
    } else if (n === Infinity) {
      setBuyAmount(Math.min(maximumAffordedMines, mineMax - mineCount))
    } else if (n > 0) {
      setBuyAmount(a => Math.min(mineMax - mineCount, a + n))
    } else {
      setBuyAmount(-mineCount)
    }
  }, [mineCount, mineMax, maximumAffordedMines])

  useEffect(() => {
    if (open) {
      setBuyAmount(0)
    }
  }, [open])

  const color = (buyAmount === 0 ? ('white') : (
    buyAmount > 0 ? ('green') : ('red')
  ))

  return (
    <>
      <Header size="huge" icon>
        Purchase {translateResource(resource)} {building_type ? building_type + 's' : 'Mines'}<br />
        <span style={{ fontSize: '34px', marginTop: '18px' }}>
          <span style={{ color }}>{mineCount + buyAmount}</span>&nbsp;/&nbsp;{mineMax}
        </span>
        <br />
        <Label style={{ marginTop: '18px' }} size="massive" color={calculatedCash < 0 ? 'red' : 'green'}>
          Current Cash: {truncateCash(calculatedCash)}
        </Label>
        <br />
        <Label style={{ marginTop: '18px' }} size="big">
          <span style={{ textDecoration: buyAmount < 0 ? 'line-through' : 'unset' }}> {building_type ? building_type : 'Mine'} Cost: {truncateCash(mineCost)}</span>
          {buyAmount < 0 && (
            <>
              <br />
              {building_type ? building_type : 'Mine'} Refund: {truncateCash(mineCost * 0.75)}
            </>
          )}
        </Label>
        <br />
        <Label color="blue" style={{ marginTop: '18px' }} size="big">
          Total Cost: {truncateCash(((mineCost * (buyAmount < 0 ? 0.75 : 1))) * buyAmount)}
        </Label>
      </Header>
      <div className="mpm-centerer">
        <ButtonGroup fluid style={{ width: '320px' }}>
          <Button onClick={onIncrement(-Infinity)} inverted color="yellow">Sell All</Button>
          <Button onClick={onIncrement(mine_increments * -1)} inverted color="yellow">{truncateCash(mine_increments * -1, { raw: true })}</Button>
          <Button onClick={onIncrement(mine_increments)} inverted primary>{'+' + truncateCash(mine_increments, { raw: true })}</Button>
          <Button onClick={onIncrement(Infinity)} inverted primary>Max</Button>
        </ButtonGroup>
        <ButtonGroup fluid style={{ width: '240px', marginTop: '18px' }}>
          <Button onClick={() => setOpen(false)} inverted secondary>Cancel</Button>
          <PromiseButton ignoreGameLogic disabled={buyAmount === 0 || (calculatedCash < 0 && buyAmount > 0)} onClick={() => onPurchase(buyAmount)} inverted primary>{buyAmount < 0 ? 'Sell' : 'Purchase'}</PromiseButton>
        </ButtonGroup>
      </div>
    </>
  )
}
