import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { Modal, Button, Input, Message } from 'semantic-ui-react'
import usePlayerActions from '../../hooks/usePlayerActions'
import { useSelector } from '../../../../hooks/useSelector'
import { round } from 'lodash'
import { truncateCash } from '../../../../utils/truncateCash'
import { getWeaponUpgradeAtLevel } from '../../../../utils/getWeaponUpgradeAtLevel'

interface ResourceModalProps {
  open: boolean
  setOpen: any
  onTrade?: any
  label: 'Wa' | 'Au' | 'population' | 'Oil' | 'U' | 'Cu' | 'Ag' | 'Dia' | 'Ti' | 'Fe' | 'Pt'
}

export const ConvertResourceModal = ({ open, setOpen, label, onTrade }: ResourceModalProps) => {
  const { onConvertResource } = usePlayerActions()
  const [submitting, setSubmitting] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [error, setError] = useState('')
  const sheet = useSelector(s => s.game.sheet)
  const weapon_level = useSelector(s => s.game.sheet?.weapons.current_level || 0)
  const resource_costs = useSelector(s => s.game.metadata?.resource_costs || {})
  const population_costs = useSelector(s => s.game.metadata?.population_cost || 0)
  const ship_rebuild_cost = useSelector(s => s.game.metadata?.ship_rebuild_cost || 0)
  const planet_purchase_options = useSelector(s => s.game.sheet?.planet_purchase_options)
  const current_planet = useSelector(s => s.game.metadata?.players[sheet?.id || 0].current_planet)
  const titanium_conversion_base_cost = useSelector(s => s.game.metadata?.titanium_conversion_base_cost || 0)
  const titanium_conversion_base_exponent = useSelector(s => s.game.metadata?.titanium_conversion_base_exponent || 0)
  const turn = useSelector(s => s.game.metadata?.turn || 0)
  const titanium_growth_per_turn = useSelector(s => s.game.metadata?.titanium_conversion_cost_growth_per_turn || 0)
  const base_relative_qty = useSelector(s => s.game.metadata?.relativeResourceQuantities || 0) * .25

  console.log('test')
  const onSubmit = useCallback(async () => {
    setSubmitting(true)
    try {
      await onConvertResource(label, quantity)
      setOpen(false)
    } catch (e) {
      // @ts-ignore
      setError(e.message)
    }
    setSubmitting(false)
  }, [quantity, label, onConvertResource, setOpen])

  useEffect(() => {
    if (open) {
      setQuantity(1)
      setError('')
    }
  }, [open])

  const weapon_damage = useMemo(() => getWeaponUpgradeAtLevel(weapon_level).die_sides, [weapon_level])

  let show_cost = false
  let costs_income = false
  let cash_cost = 0
  let text
  if (label === 'Wa') {
    text = 'Choose quantity for conversion of water. 1 water converts to 3 population.'
  } else if (label === 'Au') {
    text = `Choose quantity for conversion of gold. Every ${truncateCash(sheet?.pirate_insurance_cost || 0, { raw: true })} gold prevents attacks from pirates for an additional 1 year. You have ${truncateCash(sheet?.pirate_insurance_duration || 0, { raw: true })} years of police force remaining`
  } else if (label === 'Dia') {
    text = `Choose quantity for conversion of diamond. 1 diamond converts to ${truncateCash((resource_costs['Dia']?.cash || 0) * .6)} cash.`
  } else if (label === 'population') {
    text = `Enter quantity of population to hire. Each person costs ${truncateCash(population_costs)}`
    show_cost = true
    cash_cost = population_costs * quantity
  } else if (label === 'Oil') {
    text = `Enter quantity for conversion of oil. ${truncateCash(Math.ceil(base_relative_qty), { raw: true })} oil adds 10 to your next roll. Maximum ${truncateCash(Math.ceil(base_relative_qty) * 8, { raw: true })} oil converted per turn. You have converted ${truncateCash(sheet?.converted_oil || 0, { raw: true })} oil this turn.`
  } else if (label === 'U') {
    text = `Convert ${Math.round(((sheet?.converted_uranium_total || 0) + 1) ** 2.65)} to instantly fly your ship home to Earth.`
  } else if (label === 'Cu') {
    text = `Convert copper to increase LOC maximum by ${round(resource_costs['Oil']?.cash || 0, -1)} per copper.`
  } else if (label === 'Ag') {
    text = `Convert silver to permanently handicap yourself and make the game more challenging by decreasing your income by an additional 10% forever. You currently are collecting ${100 - (sheet?.converted_silver || 0) * 10}% of your income`
  } else if (label === 'Pt') {
    text = `Convert platinum into missile upgrades. Each platinum increases maximum damage of missiles by 2 and strength of planets by 1. You have level ${sheet?.weapons.current_level} missiles that do 1-${weapon_damage} damage. You are paying $${round(1.6 ** (sheet?.weapons.current_level || 0) - 1, 2)} income to maintain your weapons. Each level upgrade icnreases income cost.`
    show_cost = true
    cash_cost = (1.6 ** ((sheet?.weapons.current_level || 1) + quantity) - 2) - (1.6 ** ((sheet?.weapons.current_level || 1)) - 2)
    costs_income = true
  } else if (label === 'Fe') {
    let strength = 0
    if (planet_purchase_options) {
      const planet = planet_purchase_options[current_planet || '']
      if (planet && planet.type === 'deed' && planet.strength) {
        strength = planet.strength
      }
    }
    const total_damage = (weapon_damage || 0) * (1 + quantity * .1)
    const attack_win_probability = (total_damage - strength) / total_damage
    if (strength > 0) {
      text = (
        <>
          Convert iron into a missile to fire at the planet you are on. <br />
          Probability of success: <span style={
            {
              color: 'red',
            }}>{Math.round(attack_win_probability * 100)}</span>%
          Your level {sheet?.weapons.current_level} missiles do 1-{weapon_damage} damage.
          Each iron increases damage by 10%.
          If you fail in the battle everyone on your ships dies and you will have to pay {truncateCash(ship_rebuild_cost)} to rebuild.`;
        </>
      )
    } else {
      text = 'This location cannot be attacked'
    }

    // text = 'test'
  } else if (label === 'Ti') {
    // text = `Convert titanium to increase maximum quantity of specified mine by 1. [Copper: 1] [Water: 2] [Iron: 3] [Silver: 4] [Gold: 5] [Oil: 6] [Diamond: 7] [Titanium: 8] [Platinum: 9] [Uranium: 10]`;
    const q = (resource_costs['Au']?.cash || 0)
    text = `Convert titanium to increase maximum quantity of specified mine by 10% + 1. 
      [Copper: 2 & ${truncateCash((sheet?.mine_production_modifier_global_addition['Cu'] || 0) * q)}] 
      [Water: 3 & ${truncateCash((sheet?.mine_production_modifier_global_addition['Wa'] || 0) * q)}] 
      [Iron: 4 & ${truncateCash((sheet?.mine_production_modifier_global_addition['Fe'] || 0) * q)} ] 
      [Silver: 5 & ${truncateCash((sheet?.mine_production_modifier_global_addition['Ag'] || 0) * q)} ] 
      [Gold: 6 & ${truncateCash((sheet?.mine_production_modifier_global_addition['Au'] || 0) * q)} ] 
      [Oil: 7 & ${truncateCash((sheet?.mine_production_modifier_global_addition['Oil'] || 0) * q)} ] 
      [Diamond: 8 & ${truncateCash((sheet?.mine_production_modifier_global_addition['Dia'] || 0) * q)} ] 
      [Titanium: 9 & ${truncateCash((sheet?.mine_production_modifier_global_addition['Ti'] || 0) * q)} ] 
      [Platinum: 10 & ${truncateCash((sheet?.mine_production_modifier_global_addition['Pt'] || 0) * q)} ] 
      [Uranium: 11 & ${truncateCash((sheet?.mine_production_modifier_global_addition['U'] || 0) * q)} ]`
    let bulk_buy = 1
    const resource_to_mine_key: any = {
      2: 'Cu',
      3: 'Wa',
      4: 'Fe',
      5: 'Ag',
      6: 'Au',
      7: 'Oil',
      8: 'Dia',
      9: 'Ti',
      10: 'Pt',
      11: 'U',
    }
    let resource_key
    for (let i = 0; i <= 8; i++) {
      if (quantity > (1.1 * 10 ** (8 - i))) {
        bulk_buy = 10 ** (8 - i)
        resource_key = resource_to_mine_key[quantity / bulk_buy]
        break
      }
    }
    cash_cost = titanium_conversion_base_exponent ** ((sheet?.mine_production_modifier_global_addition[resource_key] || 0) + bulk_buy) * titanium_conversion_base_cost * titanium_growth_per_turn ** turn
    if (bulk_buy > 1) {
      const discount = .9 ** bulk_buy
      cash_cost *= discount
    }

    show_cost = true
  }

  return (
    <Modal size="mini" open={open} onClose={() => setOpen(false)}>
      <Modal.Header>Choose Quantity</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <span style={{ whiteSpace: 'pre-wrap' }}>
            {text}
          </span>
        </Modal.Description>
        {show_cost && (<Message color="blue">Total Cost: {truncateCash(cash_cost) + (costs_income ? ' income' : '')}</Message>)}
        <br />
        <Input type="number" value={quantity} onChange={(e) => setQuantity(Number(e.target.value))} />
        {error && (<Message color="red">{error}</Message>)}
      </Modal.Content>
      <Modal.Actions>
        {onTrade && (<Button color="yellow" onClick={onTrade}>Trade</Button>)}
        <Button secondary onClick={() => setOpen(false)}>Cancel</Button>
        <Button primary onClick={onSubmit} disabled={submitting}>Convert</Button>
      </Modal.Actions>
    </Modal>
  )
}
