import { useSelector } from '../../../../hooks/useSelector'
import { useMemo, useCallback } from 'react'
import { Card, Button } from 'semantic-ui-react'
import React from 'react'
import { PrivateTrade } from '../../../../../types'
import PromiseButton from '../../../../components/PromiseButton'
import usePlayerActions from '../../hooks/usePlayerActions'
import { translateResource } from '../../../../utils/translateResource'
import formatCost from '../../../../utils/formatCost'
import classNames from 'classnames'

interface PrivateTradeOfferProps {
  offer: PrivateTrade
}

const PrivateTradeOffer = ({ offer }: PrivateTradeOfferProps) => {
  const players = useSelector(s => s.game.metadata?.players) || {}
  const uid = useSelector(s => s.auth.uid) || ''
  const { onOfferTrade } = usePlayerActions()

  const onRevoke = useCallback(() => {
    return onOfferTrade('revoke', offer)
  }, [onOfferTrade, offer])

  const onAccept = useCallback(() => {
    return onOfferTrade('accept', offer)
  }, [onOfferTrade, offer])

  const targetDescription = offer.from === uid ? 'To' : 'From'
  const target = offer.from === uid ? players[offer.to]?.name : players[offer.from]?.name
  const color = offer.from === uid ? players[offer.to]?.color : players[offer.from]?.color

  const isOwner = offer.from === uid

  return (
    <Card.Content>
      <Card.Description style={{ position: 'relative' }}>
        <h3>
          {targetDescription}: <span style={{ color }}>{target}</span>
        </h3>
        {isOwner && (
          <PromiseButton onClick={onRevoke} size="mini" style={{ position: 'absolute', top: 0, right: 0, marginRight: 0 }} color="red">Revoke</PromiseButton>
        )}
        <p>Offering {offer.quantity} {translateResource(offer.resource)}. Asking: {formatCost(offer.cost)}</p>
        {!isOwner && (
          <Button.Group style={{ marginTop: '16px' }} fluid size="mini">
            <PromiseButton ignoreGameLogic onClick={onAccept} color="green">Accept</PromiseButton>
            <PromiseButton ignoreGameLogic onClick={onRevoke} color="red">Decline</PromiseButton>
          </Button.Group>
        )}
      </Card.Description>
    </Card.Content>
  )
}

export const PrivateTradeOffers = () => {
  const _offers = useSelector(s => s.game.metadata?.private_trade) || []
  const uid = useSelector(s => s.auth.uid) || ''
  const is_map = useSelector(s => s.game.galaxy_display)

  const offers = useMemo(() => _offers.filter((trade) => [trade.from, trade.to].indexOf(uid) !== -1), [_offers, uid])

  if (!offers.length) {
    return null
  }

  return (
    <Card fluid className={classNames({ is_map })} style={{ zIndex: 1 }}>
      <Card.Content>
        <Card.Header>Private Trades</Card.Header>
      </Card.Content>
      {offers.map((offer) => <PrivateTradeOffer key={offer.id} offer={offer} />)}
    </Card>
  )
}
