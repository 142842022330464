import { useCallback } from 'react'
import { useSelector } from '../../../hooks/useSelector'
import usePlayerActions from '../../SheetView/hooks/usePlayerActions'

export default function useGame() {
  const game = useSelector(s => s.game.game)
  const { onStartGame } = usePlayerActions()

  const onStart = useCallback(() => {
    return onStartGame()
  }, [onStartGame])

  return {
    game,
    onStart,
  }
}
