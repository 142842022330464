import React from 'react'
import { Header, Modal } from 'semantic-ui-react'

export const AdminEditingModal = ({ name }: { name: string }) => {
  return (
    <Modal basic open>
      <Modal.Content>
        <Header inverted size="huge">{name} is currently modifying the database...</Header>
      </Modal.Content>
    </Modal>
  )
}
