import { WeaponsUpgrade } from '../../types'

// COPY & PASTE TO FRONT-END
export const getWeaponUpgradeAtLevel = (level: number): WeaponsUpgrade => {
  const upgrade: WeaponsUpgrade = {
    cost: {
      cash: 0,
    },
    die_sides: (level * 2) + 4,
  }

  if (level === 1) {
    upgrade.cost.resources = { Fe: 2, cash: 500 }
  } else if (level < 5) {
    upgrade.cost.resources = { Ti: 1 }
  } else {
    upgrade.cost.resources = {
      Ti: level - 4,
      Pt: level - 4,
    }
  }

  return upgrade
}
