import React from 'react'
import { Card, Statistic, Button, Popup, Icon } from 'semantic-ui-react'
import { Splitter } from '../../../../components/Splitter/Splitter'
import './PlayerCashInvestments.scss'
import { useSelector } from '../../../../hooks/useSelector'
import PromiseButton from '../../../../components/PromiseButton'
import LOCRateUpgradesPopup from './LOCRateUpgradesPopup'
import LOCMaxUpgradesPopup from './LOCMaxUpgradesPopup'
import BondRateUpgradesPopup from './BondRateUpgradesPopup'
import BondMaxUpgradesPopup from './BondMaxUpgradesPopup'
import usePlayerActions from '../../hooks/usePlayerActions'
import numeral from 'numeral'
import { createBondUpgradeLevels } from './calculate_bond_upgrade_levels'
import { createLocUpgradeLevels } from './calculate_loc_upgrade_levels'
import { round } from 'lodash'
import { truncateCash } from '../../../../utils/truncateCash'
import { calcQuest } from './calculateQuestBonus'
import classNames from 'classnames'

export default () => {
  const loc = useSelector(s => s.game.sheet?.loc)
  const bonds = useSelector(s => s.game.sheet?.bonds)
  const options = useSelector(s => s.game.options)
  const is_map = useSelector(s => s.game.galaxy_display)
  const prime = useSelector(s => s.game.metadata?.prime_rate) || 0
  const risk_adjusted_rate = useSelector(s => s.game.sheet?.credit_risk_adjusted_rate) || 0
  const global_bond_rate_addition = useSelector(s => s.game.metadata?.global_bond_rate_addition) || 0
  const quest_bond_bonus = useSelector(s => s.game.sheet?.quests.rewards.bond_rate_bonus)
  const quest_loc_bonus = useSelector(s => s.game.sheet?.quests.rewards.loc_rate_bonus)

  const quest_loc_bonus_total = calcQuest(quest_loc_bonus || [])
  const quest_bond_bonus_total = calcQuest(quest_bond_bonus || [])

  const {
    onModifyCashAccounts,
  } = usePlayerActions()

  if (!loc || !bonds) {
    return null
  }

  const bond_max = (options?.bond_max_upgrades[bonds.max_level].max || 0) + bonds.max_bonuses
  let bond_rate = (createBondUpgradeLevels(options?.bond_rate_upgrades || [])[bonds.rate_level].rate) + (prime * .5 + global_bond_rate_addition + quest_bond_bonus_total) * 100
  const loc_max = (createLocUpgradeLevels(options?.loc_max_upgrades || [])[loc.max_level].max || 0) + loc.max_bonuses
  const loc_rate = (options?.loc_rate_upgrades[loc.rate_level].rate || 0) + (prime + risk_adjusted_rate + quest_loc_bonus_total) * 100

  return (
    <Card className={classNames('pr-cash', { is_map })} fluid style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, zIndex: 1 }}>
      <Card.Content className="pr-border-bottom" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Card.Header>Cash Accounts</Card.Header>
        <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <Popup wide="very" position="top center" basic trigger={(
            <Icon color="grey" name="info circle" />
          )}>
            <b>Cash Accounts</b> are meant for you to store extra money to receive income on, or borrow money at a steep rate to help your buying power.
            <hr />
            <b>Bonds</b> are an investment that allow you to earn a percent of your investment every year (turn). Be sure to put any extra cash at the end of your turn into your bonds.
            <br />
            <br />
            Upgrades to your bond rate are available by hovering the current bond rate. From there you can click the next level to unlock it, but make sure you have the necessary
            resource requirements available!
            <hr />
            <b>LOC (Line of Credit)</b> is a good way to borrow extra money if you're running low.
            <br />
            <br />
            Upgrades to your LOC can be made to increase the maximum amount you can borrow, as well as upgrading the rate at which you are borrowing. Don't forget to upgrade your rate
            if you've taken a large sum from your LOC!
          </Popup>
        </div>
      </Card.Content>

      <Splitter>
        <Statistic size="mini" color={bonds.cash === bond_max ? 'green' : 'black'}>
          <Statistic.Value>
            {truncateCash(bonds.cash, {}, true)}
          </Statistic.Value>
          <Statistic.Label>Bonds</Statistic.Label>
        </Statistic>

        <BondMaxUpgradesPopup>
          <Statistic className="pr-upgrade" size="mini">
            <Statistic.Value>
              {truncateCash(bond_max, {}, true)}
            </Statistic.Value>
            <Statistic.Label>Max</Statistic.Label>
          </Statistic>
        </BondMaxUpgradesPopup>

        <BondRateUpgradesPopup>
          <Statistic className="pr-upgrade" size="mini">
            <Statistic.Value>
              {bond_rate.toFixed(1)}%
            </Statistic.Value>
            <Statistic.Label>Rate</Statistic.Label>
          </Statistic>
        </BondRateUpgradesPopup>
      </Splitter>

      <Button.Group style={{ margin: '12px 0', padding: '0 12px' }} size="mini" fluid>
        <PromiseButton ignoreGameLogic onClick={() => onModifyCashAccounts('withdraw_bonds')} color="red" inverted>Sell</PromiseButton>
        <PromiseButton ignoreGameLogic onClick={() => onModifyCashAccounts('deposit_bonds')} color="green">Buy</PromiseButton>
      </Button.Group>

      <Splitter>
        <Statistic size="mini" color={loc.cash > 0 ? 'red' : 'black'}>
          <Statistic.Value>
            {investmentTruncate(loc.cash, {}, true)}
          </Statistic.Value>
          <Statistic.Label>LOC</Statistic.Label>
        </Statistic>

        <LOCMaxUpgradesPopup>
          <Statistic className="pr-upgrade" size="mini">
            <Statistic.Value>
              {investmentTruncate(loc_max, {}, true)}
            </Statistic.Value>
            <Statistic.Label>Max</Statistic.Label>
          </Statistic>
        </LOCMaxUpgradesPopup>

        <LOCRateUpgradesPopup>
          <Statistic className="pr-upgrade" size="mini">
            <Statistic.Value>
              {loc_rate.toFixed(1)}%
            </Statistic.Value>
            <Statistic.Label>Rate</Statistic.Label>
          </Statistic>
        </LOCRateUpgradesPopup>
      </Splitter>

      <Button.Group style={{ margin: '12px 0', padding: '0 12px' }} size="mini" fluid>
        <PromiseButton ignoreGameLogic onClick={() => onModifyCashAccounts('withdraw_loc')} color="red" inverted>Withdraw 25%</PromiseButton>
        <PromiseButton ignoreGameLogic onClick={() => onModifyCashAccounts('deposit_loc')} color="green">Deposit</PromiseButton>
      </Button.Group>
    </Card>
  )
}

export const investmentTruncate = (currency: number, options?: any, pennies?: boolean) => {
  let format = (options && options.raw) ? '' : '$'
  let is_negative = ''
  if (currency < 0) {
    currency *= -1
    is_negative = '-'
  }

  if (currency < 10000 && currency > -10000) {
    format += '0'
  } else if ([7, 10, 13, 16, 19].indexOf(currency.toString().length) !== -1) {
    format += '0.0a'
  } else {
    format += '0a'
  }

  if (pennies) {
    format += '.00'
  }

  if (options && options.removeDigits && options.raw) {
    format = (options && options.raw) ? '' : '$'
    if ([4, 7, 10, 13, 17].indexOf(currency.toString().length) !== -1) {
      format += '0.0a'
    } else {
      format += '0a'
    }
  }

  if (currency > 1000000000) {
    let value = Math.round(currency)
    let new_value = 0
    let more_digits = 0
    let more_digits_text = ''
    let final_string = ''
    const abbreviations = ['k', 'm', 'b', 't', 'q', 'Q', 's', 'S', 'o', 'n', 'd', 'u', 'D', 'T', 'q', 'Q', 's', 'S', 'O', 'N', 'V', '?']
    const x = 3
    for (let i = 1; i < abbreviations.length; i++) {
      const y = x * i
      let total_digits = value.toString().length
      if (value.toString().includes('e')) { // returns an array with the two values meaning it contains an e
        const scientific_notation = value.toString().split('e')
        let prefix_number = parseFloat(scientific_notation[0])
        total_digits = Math.floor(parseFloat(scientific_notation[1]) / 3)
        const suffix = abbreviations[Math.min(total_digits - 1, abbreviations.length - 1)]
        const decimals_off = parseFloat(scientific_notation[1]) - total_digits * 3
        prefix_number *= 10 ** decimals_off
        if (prefix_number >= 10) {
          prefix_number = Math.round(prefix_number)
        } else {
          prefix_number = round(prefix_number, 1)
        }
        final_string = is_negative + prefix_number + suffix
        if (!options?.raw) {
          final_string = is_negative + '$' + final_string
        }
        return final_string
      }
      if (y > total_digits - 1) {
        new_value = parseFloat(value.toString().substring(0, value.toString().length - y + 3))
        if (new_value.toString().length < 2) { // if we rounded a lot, make it a little bigger
          more_digits = parseFloat(value.toString().substring(1, 2))
          more_digits_text += '.'
          more_digits_text += more_digits.toString()
        }
        final_string = is_negative + `${new_value}${more_digits_text}${abbreviations[i - 2]}`
        if (!options?.raw) {
          final_string = is_negative + '$' + final_string
        }
        return final_string
      }
    }
    return final_string
  }

  return is_negative + numeral(currency).format(format)
}
