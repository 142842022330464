import React, { useState, useCallback } from 'react'
import { MenuItemProps, Menu } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import { pushError } from '../reducers/game/game'
import { useSelector } from '../hooks/useSelector'

export default (props: MenuItemProps) => {
  const [disabled, setDisabled] = useState(false)
  const playerChoosingPlanet = (useSelector(s => s.game.metadata?.players_choosing_planet) || []).length > 0 && !props.ignoreGameLogic
  const _onClick = props.onClick
  const dispatch = useDispatch()
  const onClick = useCallback(async (e, data) => {
    if (!_onClick) {
      return
    }
    setDisabled(true)
    try {
      await _onClick(e, data)
    } catch (e) {
      dispatch(pushError({
        type: 'error',
        message: e.message,
      }))
    }
    setDisabled(false)
  }, [_onClick, dispatch])
  return (
    <Menu.Item {...props} onClick={onClick} disabled={props.disabled || disabled || playerChoosingPlanet} />
  )
}
