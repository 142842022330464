import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from '../../../hooks/useSelector'
import { useSocket } from '../../../hooks/useSocket'

export const useJoinAdminEditorLobby = () => {
  const socket = useSocket()
  const authenticated = useSelector(s => s.auth.authorized)
  const { id } = useParams<any>()

  useEffect(() => {
    if (!socket || !socket.authenticated || !authenticated) {
      return
    }

    socket.emit('join_admin_lobby', id)
  }, [socket, authenticated, id])
}
