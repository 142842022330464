import { combineReducers, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { auth, game } from './reducers'

const rootReducer = combineReducers({
  auth,
  game,
})

export type RootState = ReturnType<typeof rootReducer>

const store = createStore(rootReducer, applyMiddleware(thunk))

export default store

// @ts-ignore
window.store = store
