import { PlayerStatus } from '../../types'
import { useCallback } from 'react'
import { useGameBoardActions } from '../views/SheetView/hooks/useGameBoardActions'

export const useFocusPlayer = (player: PlayerStatus) => {
  const { focusPlayer } = useGameBoardActions()

  const onFocusPlayer = useCallback(() => {
    focusPlayer(player.id)
  }, [player, focusPlayer])

  return {
    onFocusPlayer,
  }
}
