import update from 'immutability-helper'
import { Game, Sheet, GameMetadata, GameBoard } from '../../../types'
import { GameOptions } from '../../../types/options'
import { ConnectionStatus } from '../../../types/connection_status'

export interface Message {
  type: 'error' | 'info'
  message: string
}

interface GameState {
  games: Game[]
  game?: Game
  sheet?: Sheet
  admin_sheets?: Sheet[]
  metadata?: GameMetadata
  options?: GameOptions
  game_board?: GameBoard
  errors: Message[]
  fullscreen: boolean
  galaxy_display: boolean
  sort_owned?: string
  sort_choice?: string
  connection_status: ConnectionStatus
}

const initialState: GameState = {
  games: [],
  errors: [],
  fullscreen: false,
  galaxy_display: false,
  connection_status: {},
}

const SET_GAMES = 'games/SET_GAMES'
const SET_GAME = 'games/SET_GAME'
const SET_SHEET = 'games/SET_SHEET'
const SET_SHEET_DELTA = 'games/SET_SHEET_DELTA'
const SET_METADATA = 'games/SET_METADATA'
const SET_METADATA_DELTA = 'games/SET_METADATA_DELTA'
const SET_OPTIONS = 'games/SET_OPTIONS'
const SET_GAME_BOARD = 'games/SET_GAME_BOARD'
const SET_FULLSCREEN = 'games/SET_FULLSCREEN'
const SET_MAP = 'games/SET_MAP'
const SET_ADMIN_SHEETS = 'games/SET_ADMIN_SHEETS'
const SET_CONNECTION_STATUS_DELTA = 'games/SET_CONNECTION_STATUS_DELTA'

const SET_SORT_OWNED = 'games/SORT_OWNED'
const SET_SORT_CHOICE = 'games/SET_SORT_CHOICE'

const PUSH_ERROR = 'games/PUSH_ERROR'
const PULL_ERROR = 'games/PULL_ERROR'

export default function (state: GameState = initialState, action: GameActionTypes): GameState {
  switch (action.type) {
  case SET_CONNECTION_STATUS_DELTA:
    return update(state, { connection_status: action.payload })
  case SET_GAMES:
    return { ...state, games: action.payload }
  case SET_GAME:
    return { ...state, game: action.payload }
  case SET_SHEET:
    return { ...state, sheet: action.payload }
  case SET_SHEET_DELTA:
    return { ...state, sheet: update(state.sheet, action.payload) }
  case SET_METADATA:
    return { ...state, metadata: action.payload }
  case SET_METADATA_DELTA:
    return { ...state, metadata: update(state.metadata, action.payload) }
  case SET_OPTIONS:
    return { ...state, options: action.payload }
  case SET_GAME_BOARD:
    return { ...state, game_board: action.payload }
  case SET_FULLSCREEN:
    return { ...state, fullscreen: action.payload }
  case SET_MAP:
    return { ...state, galaxy_display: action.payload }
  case SET_ADMIN_SHEETS:
    return { ...state, admin_sheets: action.payload }
  case SET_SORT_OWNED:
    return { ...state, sort_owned: action.payload }
  case SET_SORT_CHOICE:
    return { ...state, sort_choice: action.payload }
  case PUSH_ERROR:
    return { ...state, errors: [...state.errors, action.payload] }
  case PULL_ERROR:
    const index = state.errors.indexOf(action.payload)
    return update(state, {
      errors: { $splice: [[index, 1]] },
    })
  default:
    return state
  }
}

interface SetConnectionStatusDelta {
  type: typeof SET_CONNECTION_STATUS_DELTA
  payload: any
}

export function setConnectionStatusDelta(delta: any): SetConnectionStatusDelta {
  return {
    type: SET_CONNECTION_STATUS_DELTA,
    payload: delta,
  }
}

interface SetGamesAction {
  type: typeof SET_GAMES
  payload: Game[]
}

export function setGames(games: Game[]): SetGamesAction {
  return {
    type: SET_GAMES,
    payload: games,
  }
}

interface SetGameAction {
  type: typeof SET_GAME
  payload?: Game
}

export function setGame(game?: Game): SetGameAction {
  return {
    type: SET_GAME,
    payload: game,
  }
}

interface SetSheetAction {
  type: typeof SET_SHEET
  payload?: Sheet
}

export function setSheet(sheet?: Sheet): SetSheetAction {
  return {
    type: SET_SHEET,
    payload: sheet,
  }
}

interface SetSheetDeltaAction {
  type: typeof SET_SHEET_DELTA
  payload: any
}

export function setSheetDelta(sheetChanges: any): SetSheetDeltaAction {
  return {
    type: SET_SHEET_DELTA,
    payload: sheetChanges,
  }
}

interface SetAdminSheetsAction {
  type: typeof SET_ADMIN_SHEETS
  payload?: Sheet[]
}

export function setAdminSheets(sheets?: Sheet[]): SetAdminSheetsAction {
  return {
    type: SET_ADMIN_SHEETS,
    payload: sheets,
  }
}

interface SetMetadataAction {
  type: typeof SET_METADATA
  payload?: GameMetadata
}

export function setMetadata(metadata?: GameMetadata): SetMetadataAction {
  return {
    type: SET_METADATA,
    payload: metadata,
  }
}

interface SetMetadataDeltaAction {
  type: typeof SET_METADATA_DELTA
  payload?: any
}

export function setMetadataDelta(metadataChanges?: any): SetMetadataDeltaAction {
  return {
    type: SET_METADATA_DELTA,
    payload: metadataChanges,
  }
}

interface SetOptionsAction {
  type: typeof SET_OPTIONS
  payload?: GameOptions
}

export function setOptions(options?: GameOptions): SetOptionsAction {
  return {
    type: SET_OPTIONS,
    payload: options,
  }
}

interface SetGameBoardAction {
  type: typeof SET_GAME_BOARD
  payload?: GameBoard
}

export function setGameBoard(board?: GameBoard): SetGameBoardAction {
  return {
    type: SET_GAME_BOARD,
    payload: board,
  }
}

interface SetFullscreenAction {
  type: typeof SET_FULLSCREEN
  payload: boolean
}

export function setFullscreen(fullscreen: boolean): SetFullscreenAction {
  return {
    type: SET_FULLSCREEN,
    payload: fullscreen,
  }
}

interface SetMapAction {
  type: typeof SET_MAP
  payload: boolean
}

export function setMap(fullscreen: boolean): SetMapAction {
  return {
    type: SET_MAP,
    payload: fullscreen,
  }
}

interface SetSortOwnedAction {
  type: typeof SET_SORT_OWNED
  payload: string
}

export function setSortOwned(fullscreen: string): SetSortOwnedAction {
  return {
    type: SET_SORT_OWNED,
    payload: fullscreen,
  }
}

interface SetSortChoiceAction {
  type: typeof SET_SORT_CHOICE
  payload: string
}

export function setSortChoice(fullscreen: string): SetSortChoiceAction {
  return {
    type: SET_SORT_CHOICE,
    payload: fullscreen,
  }
}

interface PushErrorAction {
  type: typeof PUSH_ERROR
  payload: Message
}

export function pushError(message: Message): PushErrorAction {
  return {
    type: PUSH_ERROR,
    payload: message,
  }
}

interface PullErrorAction {
  type: typeof PULL_ERROR
  payload: Message
}

export function pullError(message: Message): PullErrorAction {
  return {
    type: PULL_ERROR,
    payload: message,
  }
}

export type GameActionTypes = SetGamesAction |
  SetGameAction |
  SetSheetAction |
  SetSheetDeltaAction |
  SetAdminSheetsAction |
  SetMetadataAction |
  SetMetadataDeltaAction |
  SetOptionsAction |
  PushErrorAction |
  PullErrorAction |
  SetGameBoardAction |
  SetFullscreenAction |
  SetMapAction |
  SetSortChoiceAction |
  SetSortOwnedAction |
  SetConnectionStatusDelta
