import React, { useMemo, useRef } from 'react'
import { useEffect, useState } from 'react'
import { Menu } from 'semantic-ui-react'
import { useSelector } from '../hooks/useSelector'
import './TurnTimer.scss'
import usePlayerActions from '../views/SheetView/hooks/usePlayerActions'
import { useSocket } from '../hooks/useSocket'

export const TurnTimer = () => {
  const [display, setDisplay] = useState('')
  const turn_timer_expires_at = useSelector(s => s.game.metadata?.turn_timer_expires_at || 0)
  const paused_by = useSelector(s => s.game.metadata?.paused_by)
  const uid = useSelector(s => s.auth.uid) || ''
  const dead = useSelector(s => s.game.metadata?.players[uid].dead) || false
  const turn = useSelector(s => s.game.metadata?.turn) || 0
  const isHost = useSelector(s => s.game.game?.owner === s.auth.uid)
  const ready = useSelector(s => s.game.metadata?.players[uid].ready) || false
  const trade = useSelector(s => !!s.game.metadata?.trade) || false
  const timeGained = useSelector(s => s.game.options?.refresh_time_gained || 0)
  const trade_submitted = useSelector(s => !!s.game.metadata?.players[uid].trade_ready)
  const players_choosing_planet = useSelector(s => s.game.metadata?.players_choosing_planet) || []
  const intervalRef = useRef<any>()
  const offsetRef = useRef<number>()
  const sheet = useSelector(s => s.game.sheet)
  const { onAddTime, onForceTurnEnd } = usePlayerActions()
  const socket = useSocket()

  useEffect(() => {
    if (!socket || socket.connected === false) {
      return
    }

    const to = setTimeout(() => {
      const startTime = Date.now()
      socket.once('pong', (serverTime: number) => {
        const endTime = Date.now()
        const latency = endTime - startTime
        offsetRef.current = serverTime - endTime + latency / 2
        console.log('Server latency offset: ', offsetRef.current)
      })
      socket.emit('ping')
    }, 3000)

    return () => clearTimeout(to)
  }, [socket])

  const beepSound = useMemo(() => {
    const audio = new Audio('/beep.mp3')
    return audio
  }, [])

  useEffect(() => {
    if (parseFloat(display) < 5 && parseFloat(display) > 3 && ready === false) {
      beepSound.play()
    }
  }, [beepSound, display, ready])

  useEffect(() => {
    if (paused_by) {
      return
    }

    intervalRef.current = setInterval(() => {
      const now = Date.now() + (offsetRef.current ?? 0)
      if (isHost && turn_timer_expires_at <= (now) && offsetRef.current !== undefined) {
        onForceTurnEnd()
      }
      setDisplay(`${Math.max(Math.round((turn_timer_expires_at - now) / 1000), 0)}`)
    }, 1000)

    const now = Date.now() + (offsetRef.current ?? 0)
    setDisplay(`${Math.max(Math.round((turn_timer_expires_at - now) / 1000), 0)}`)

    return () => clearInterval(intervalRef.current)
  }, [turn_timer_expires_at, paused_by, isHost, onForceTurnEnd])

  const concernsMe = useMemo((): boolean => {
    if (dead) return false

    if (trade) return !trade_submitted

    const special_rift_turn = turn % 10 === 0 && turn > 19
    if (special_rift_turn && players_choosing_planet.length) {
      return players_choosing_planet[0] === uid
    }
    if (players_choosing_planet.length) {
      return players_choosing_planet.indexOf(uid) !== -1
    }

    return !ready
  }, [dead, trade, trade_submitted, turn, players_choosing_planet, ready, uid])

  return (
    <Menu.Item style={{ fontSize: '24px', position: 'relative' }} onClick={() => onAddTime(timeGained)}>
      <div className="timer-wrapper">
        <div className="timer-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div
            className="timer"
            style={{
              transition: 'background-color 0.2s linear',
              color: 'black',
              backgroundColor: concernsMe ? (
                `${parseFloat(display) < 2 ? 'red' : parseFloat(display) < 5 ? 'orange' : parseFloat(display) < 11 ? 'yellow' : '#00ff00'}`
              ) : (
                'rgba(150,150,150)'
              ),
            }}
          >
            {offsetRef.current === undefined ? ('syncing...') : (`Time: ${display}s`)}

          </div>
        </div>
      </div>
      <div className="timer-charges" style={{ fontSize: '12px', color: 'white' }}>
        Charges: {sheet?.timer_refresh_charges}
      </div>
    </Menu.Item>
  )
}
