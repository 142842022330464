// import * as firebase from 'firebase/app';
import { GameSave } from '../../../../types/arguments/loadGameArgs'

// const db = firebase.firestore();

export const fixSaveGame = (save: GameSave, id: string, uid: string) => {
  save.game.id = id
  save.metadata.paused_by = uid
  save.game.status = 'loading'
  save.game.owner = uid
  return save
}
