import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import GameView from './views/GameView/GameView'
import useAuthState from './hooks/useAuthState'
import SheetView from './views/SheetView/SheetView'
import { GameBoard } from './views/SheetView/components/GameBoard'
import { SocketContext, useSocketProvider } from './hooks/useSocket'
import { GameBoardProvider } from './views/SheetView/components/GameBoard/GameBoardProvider'

function App() {
  useAuthState()

  const socket = useSocketProvider()

  return (
    <SocketContext.Provider value={socket}>
      <Switch>
        <Route path="/game/:id" component={SheetView} />
        <Route path="/games" component={GameView} />
        <Route path="/edit" render={() => (
          <GameBoardProvider edit>
            <GameBoard edit />
          </GameBoardProvider>
        )} />
        <Route path="/" render={() => <Redirect to="/games" />} />
      </Switch>
    </SocketContext.Provider>
  )
}

export default App
