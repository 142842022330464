import { cloneDeep } from 'lodash'
import { BondRateUpgrade } from '../../../../../types'

export const createBondUpgradeLevels = (_bondUpgrade: BondRateUpgrade[]) => {
  const BRU = cloneDeep(_bondUpgrade)
  for (let i = 0; i < 100; i++) {
    if (BRU.length < 100) {
      const x = BRU.length
      const previous = BRU[BRU.length - 1].cost.resources
      BRU.push({
        rate: x + 12,
        cost: { resources: { Au: (previous?.Au || 0) * 2, Dia: (previous?.Dia || 0) * 2, U: (previous?.U || 0) * 2 } },
      })
    }
  }
  return BRU
}
