import React from 'react'
import { Modal } from 'semantic-ui-react'
import { GameBoard } from '../../../../../types'
import { useSelector } from '../../../../hooks/useSelector'
import './MinePurchaseModal.scss'
import usePlayerActions from '../../hooks/usePlayerActions'
import { MinePurchaseQuantityDisplay } from './MinePurchaseQuantityDisplay'
import { floor } from 'lodash'

interface MinePurchaseModalProps {
  open: boolean
  setOpen: any
  planet: keyof GameBoard
  resource: string
  index: number
  building_type?: string
}

export const MinePurchaseModal = ({ open, setOpen, planet, index, resource, building_type }: MinePurchaseModalProps) => {
  const { onUpgradePlanet } = usePlayerActions()

  const mineCount = useSelector(s => s.game.sheet?.planets[planet].mines[index][resource]) || 0
  const mineMax = useSelector(s => s.game.sheet?.planets[planet].deed.mine_production_maximum[resource]) || 1
  let mineCost = useSelector(s => s.game.metadata?.mine_costs[resource].cash) || 0
  const cash = floor(useSelector(s => s.game.sheet?.cash) || 0)
  const barrierCount = useSelector(s => s.game.sheet?.planets[planet].deed.disasterBarrierCountCurrent[index]) || 0
  let barrierCost = useSelector(s => s.game.metadata?.disaster_defence_costs[resource].cash) || 0
  let cost = 0
  if (building_type) {
    cost = barrierCost
  } else {
    cost = mineCost
  }
  const maxAffordedStructures = floor(cash / mineCost)

  return (
    <Modal basic open={open} onClose={() => setOpen(false)}>
      <MinePurchaseQuantityDisplay
        mineCost={cost}
        mineCount={building_type ? barrierCount : mineCount}
        mineMax={mineMax}
        maximumAffordedMines={maxAffordedStructures}
        resource={resource}
        setOpen={setOpen}
        open={open}
        onPurchase={building_type ? (quantity) => onUpgradePlanet([planet, index, quantity], 'barrier_modal').then(() => setOpen(false)) :
          (quantity) => onUpgradePlanet([planet, index, quantity], 'mine').then(() => setOpen(false)) }
        building_type={building_type}
      />
    </Modal>
  )
}
