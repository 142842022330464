import React, { useState, useCallback } from 'react'
import { Menu, SemanticCOLORS, Icon } from 'semantic-ui-react'
import get from 'lodash/get'
import ResourceButton from './ResourceButton'
import { useSelector } from '../../../hooks/useSelector'
import usePlayerActions from '../hooks/usePlayerActions'
import PromiseButton from '../../../components/PromiseButton'
import GameMenu from './GameMenu'
import { CreditRating } from './CreditRating'
import { ResourceHoverOver } from './ResourceButton/ResourceHoverOver'
import { useDispatch } from 'react-redux'
import { setMap, setFullscreen } from '../../../reducers/game/game'
import { TurnTimer } from '../../../components/TurnTimer'
import { QuestsHoverOver } from './ResourceButton/Quests'
import { useGameBoardActions } from '../hooks/useGameBoardActions'
import HelpMenu from './HelpMenu'

export default () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [helpOpen, setHelpOpen] = useState(false)
  const uid = useSelector(s => s.auth.uid)
  const timerDisabled = useSelector(s => s.game.metadata?.disable_timer)
  const difficulty = useSelector(s => s.game.options?.difficulty_settings)
  const ready = useSelector(s => get(s, `game.metadata.players.${uid}.ready`, false))
  const dead = useSelector(s => s.game.metadata?.players[uid || '']?.dead)
  const turn = useSelector(s => get(s, 'game.metadata.turn', 1))
  const is_map = useSelector(s => s.game.galaxy_display)
  const is_fullscreen = useSelector(s => s.game.fullscreen)
  const tradeActive = useSelector(s => !!s.game.metadata?.trade) || false
  const players = useSelector(s => s.game.metadata?.players) || {}
  const players_choosing_planet = useSelector(s => s.game.metadata?.players_choosing_planet) || []
  const dispatch = useDispatch()
  const {
    onReady,
  } = usePlayerActions()
  const {
    onPopout,
    poppedOut,
  } = useGameBoardActions()

  const onCancel = useCallback(() => {
    setMenuOpen(false)
  }, [])
  const onMenu = useCallback(() => {
    setMenuOpen(true)
  }, [])
  const onToggleMap = useCallback(() => {
    dispatch(setMap(!is_map))
  }, [dispatch, is_map])
  const onToggleFullscreen = useCallback(() => {
    dispatch(setFullscreen(!is_fullscreen))
  }, [dispatch, is_fullscreen])

  let text = ''
  let color: SemanticCOLORS
  if (dead) {
    text = 'You are dead'
    color = 'red'
  } else if (ready) {
    text = 'Readied'
    color = 'green'
  } else {
    text = 'Ready Up'
    color = 'orange'
  }

  let message = ''
  if (players_choosing_planet.length && Object.keys(players).length) {
    message += `Waiting for: ${players_choosing_planet.map(p => players[p].name).join(', ')} to pick their move.`
  }

  function findKeyByDifficulty(difficulty: any) {
    const foundObject = difficultyOptions.find(option => option.difficulty === difficulty)
    return foundObject ? foundObject.key : null
  }

  const result = findKeyByDifficulty(difficulty)

  return (
    <>
      <Menu inverted style={{ borderRadius: 0, marginBottom: 0, borderBottom: '1px solid #2b2b2b', zIndex: 1 }}>
        <Menu.Item header style={{ fontSize: '24px' }}>
          Year: {turn}
        </Menu.Item>
        <Menu.Item style={(difficulty || 0) > 5 ? { fontSize: `${(difficulty || 0) * 2}px`, color: 'red' } : { fontSize: '18px', color: 'green' }}>
          {result}
        </Menu.Item>
        <Menu.Item onClick={onToggleMap}>
          {is_map ? 'Show UI' : 'Hide UI'} (<strong>M</strong>)
          <Icon name={is_map ? 'eye slash' : 'eye' } style={{ paddingLeft: '16px', paddingRight: '16px' }} />
        </Menu.Item>
        <Menu.Item onClick={onToggleFullscreen}>
          {is_fullscreen ? 'Collapse My Planets' : 'Expand My Planets'} (<strong>F</strong>)
          <Icon name={is_fullscreen ? 'eye slash' : 'eye' } style={{ paddingLeft: '16px', paddingRight: '16px' }} />
        </Menu.Item>
        <Menu.Item onClick={onPopout}>
          {poppedOut ? 'Restore Gameboard' : 'Popout Gameboard'}
        </Menu.Item>
        {message && (
          <Menu.Item>
            {message}
          </Menu.Item>
        )}
        <Menu.Menu position="right">
          {typeof timerDisabled === 'boolean' && !timerDisabled && (
            <TurnTimer />
          )}
          <CreditRating />
          <Menu.Item>
            <PromiseButton disabled={dead || tradeActive} onClick={onReady} color={color} inverted={!ready}>
              {text}
            </PromiseButton>
          </Menu.Item>
          <Menu.Item icon="ellipsis vertical" onClick={onMenu} />
        </Menu.Menu>
      </Menu>
      <Menu inverted style={{ borderRadius: 0, marginTop: 0, backgroundColor: '#272727', zIndex: 1 }}>
        <Menu.Menu position="right">
          <Menu.Item onClick={() => setHelpOpen(true)}>
            <Icon name="info circle" />
            Info & Tips
          </Menu.Item>
          <QuestsHoverOver />
          <ResourceHoverOver />
          <ResourceButton color="blue" label="Wa" />
          <ResourceButton color="orange" label="Cu" />
          <ResourceButton color="purple" label="Fe" />
          <ResourceButton color="grey" label="Ag" />
          <ResourceButton color="yellow" label="Au" />
          <ResourceButton color="olive" label="Oil" />
          <ResourceButton color="teal" label="Dia" />
          <ResourceButton color="pink" label="Ti" />
          <ResourceButton color="violet" label="Pt" />
          <ResourceButton color="red" label="U" />
        </Menu.Menu>
      </Menu>
      <HelpMenu open={helpOpen} onCancel={() => setHelpOpen(false)} />
      <GameMenu open={menuOpen} onCancel={onCancel} />
    </>
  )
}

const difficultyOptions = [
  {
    key: 'Cakewalk',
    text: 'Cakewalk 🍰',
    value: 'Cakewalk',
    difficulty: 2,
  },
  {
    key: 'Very Easy',
    text: 'Very Easy 😀',
    value: 'Very Easy',
    difficulty: 3,
  },
  {
    key: 'Easy',
    text: 'Easy 😀',
    value: 'Easy',
    difficulty: 4,
  },
  {
    key: 'Normal',
    text: 'Normal 🙂',
    value: 'Normal',
    difficulty: 5,
  },
  {
    key: 'Challenging',
    text: 'Challenging 😐',
    value: 'Challenging',
    difficulty: 5.5,
  },
  {
    key: 'Tough',
    text: 'Tough 😬',
    value: 'Tough',
    difficulty: 6, // 6 5.5 Sept. 4 //anything from bot update?
  },
  {
    key: 'Brutal',
    text: 'Brutal 😢',
    value: 'Brutal',
    difficulty: 6.5, // 7 6 Sept. 4
  },
  {
    key: 'Painful',
    text: 'Painful 😮',
    value: 'Painful',
    difficulty: 7, // 7 6 Sept. 4
  },
  {
    key: 'Crippling',
    text: 'Crippling 😭',
    value: 'Crippling',
    difficulty: 7.5, // 8 6.5 Sept. 4
  },
  {
    key: 'Masochistic',
    text: 'Masochistic 👿',
    value: 'Masochistic',
    difficulty: 8, // 9 was masochistic - hardest setting we played on before; 7 Sept. 4
  },
  {
    key: 'Obscene',
    text: 'Obscene 😨',
    value: 'Obscene',
    difficulty: 8.5, // 10 7.5 Sept. 4
  },
  {
    key: 'Lethal',
    text: 'Lethal ☠️',
    value: 'Lethal',
    difficulty: 9, // 11 8 Sept. 4
  },
  {
    key: 'Futile',
    text: 'Futile 😖',
    value: 'Futile',
    difficulty: 9.5, // 11 8 Sept. 4
  },
]
