import { useCallback } from 'react'
import { useSelector } from '../../../hooks/useSelector'
import usePlayerActions from '../../SheetView/hooks/usePlayerActions'

export default function useGames() {
  const games = useSelector(s => s.game.games)
  const { onCreateGame, onJoinGame } = usePlayerActions()

  const onCreate = useCallback((name) => {
    return onCreateGame({ name })
  }, [onCreateGame])

  const onJoin = useCallback((gameId?: string) => {
    return onJoinGame({ gameId })
  }, [onJoinGame])

  return {
    games,
    onCreate,
    onJoin,
  }
}
