import { useEffect } from 'react'
import { useSelector } from './useSelector'
import { useSocket } from './useSocket'

export const useJoinLobby = () => {
  const socket = useSocket()
  const connected = useSelector(s => s.auth.connected)

  useEffect(() => {
    if (!socket || !connected) {
      return
    }

    socket.emit('join_lobby')

    return () => {
      socket.emit('leave_lobby')
    }
  }, [socket, connected])
}
