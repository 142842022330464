import React, { useState, useCallback } from 'react'
import './ResourceButton.scss'
import { Button, Label, Menu, SemanticCOLORS, Icon } from 'semantic-ui-react'
import { useSelector } from '../../../../hooks/useSelector'
// @ts-ignore
import AnimatedNumber from 'animated-number-react'
import { ConvertResourceModal } from './ConvertResourceModal'
import ResourceTradeButton from './ResourceTradeButton'
import { truncateCash } from '../../../../utils/truncateCash'

export type LabelTypes = 'Wa' | 'Cu' | 'Fe' | 'Ag' | 'Au' | 'Oil' | 'Dia' | 'Ti' | 'Pt' | 'U' | 'Fe' | 'Pt'

interface ResourceButtonProps {
  color?: SemanticCOLORS
  label: LabelTypes
}

const iconStyle: React.CSSProperties = {
  marginRight: '-2px',
  marginLeft: '4px',
}

export default ({ color, label }: ResourceButtonProps) => {
  const resource = useSelector(s => s.game.sheet?.resources[label]) || 0
  const [open, setOpen] = useState(false)
  const [tradeOpen, setTradeOpen] = useState(false)

  const onTrade = useCallback(() => {
    setOpen(false)
    setTradeOpen(true)
  }, [])

  return (
    <Menu.Item className="resource-button">
      <Button as="div" labelPosition="right">
        <Button onClick={['Wa', 'Au', 'Oil', 'U', 'Cu', 'Ag', 'Dia', 'Ti', 'Fe', 'Pt'].indexOf(label) !== -1 ? () => setOpen(true) : () => setTradeOpen(true)} color={color}>
          {label}
          {label === 'Wa' && <Icon style={iconStyle} name="user" />}
          {label === 'Au' && <Icon style={iconStyle} name="dollar sign" />}
          {label === 'Oil' && <Icon style={iconStyle} name="rocket" />}
          {label === 'U' && <Icon style={iconStyle} name="rocket" />}
          {label === 'Cu' && <Icon style={iconStyle} name="credit card outline" />}
          {label === 'Ag' && <Icon style={iconStyle} name="money bill alternate outline" />}
          {label === 'Dia' && <Icon style={iconStyle} name="diamond" />}
          {label === 'Ti' && <Icon style={iconStyle} name="factory" />}
          {label === 'Fe' && <Icon style={iconStyle} name="fire extinguisher" />}
          {label === 'Pt' && <Icon style={iconStyle} name="cogs" />}
        </Button>
        <Label color="grey" basic>
          <AnimatedNumber duration={300} value={resource} formatValue={(val: any) => truncateCash(val, { raw: true })} />
        </Label>
      </Button>
      {['Wa', 'Au', 'Oil', 'U', 'Cu', 'Ag', 'Dia', 'Ti', 'Fe', 'Pt'].indexOf(label) !== -1 && (<ConvertResourceModal
        open={open} setOpen={setOpen} onTrade={onTrade} label={label as 'Wa' | 'Au' | 'Oil' | 'U' | 'Cu' | 'Ag' | 'Dia' | 'Ti' | 'Fe' | 'Pt'} />)}
      <ResourceTradeButton open={tradeOpen} setOpen={setTradeOpen} resource={label} />
    </Menu.Item>
  )
}
