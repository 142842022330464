import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic'

const colors: { [key: string]: SemanticCOLORS } = {
  J: 'orange',
  U: 'yellow',
  N: 'purple',
  P: 'purple',
  E: 'green',
  M: 'purple',
  S: 'grey',
  ξ: 'teal',
  K: 'grey',
  O: 'grey',
  α: 'grey',
  β: 'grey',
  τ: 'teal',
  Z: 'grey',
}

export default function translateOrbitingPlanetColor(key: string): SemanticCOLORS {
  return colors[key]
}
