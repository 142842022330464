import React, { useState, useMemo, useCallback, useEffect } from 'react'
import update from 'immutability-helper'
import { Modal, Image, Input, Dropdown, DropdownItemProps, Form, Message } from 'semantic-ui-react'
import trade from './trade.png'
import PromiseButton from '../../../../components/PromiseButton'
import { Resource } from '../../../../../types'
import { translateResource } from '../../../../utils/translateResource'
import { useSelector } from '../../../../hooks/useSelector'
import firebase from 'firebase/app'
import usePlayerActions from '../../hooks/usePlayerActions'
import { round } from 'lodash'
import { truncateCash } from '../../../../utils/truncateCash'

const db = firebase.firestore()

interface ResourceTradeButtonProps {
  open: boolean
  setOpen: Function
  resource: keyof Resource
}

interface ResourceTradeButtonState {
  quantity: number
  price: number
  user?: string
}

export default ({ open, setOpen, resource }: ResourceTradeButtonProps) => {
  const players = useSelector(s => s.game.metadata?.players) || {}
  const resource_costs = useSelector(s => s.game.metadata?.resource_costs) || {}
  const uid = useSelector(s => s.auth.uid)
  const resources = useSelector(s => s.game.sheet?.resources) || { [resource]: 0 }
  const { onOfferTrade } = usePlayerActions()

  const [s, setState] = useState<ResourceTradeButtonState>({
    quantity: 1,
    price: round((resource_costs[resource]?.cash || 0), 2),
  })

  const items = useMemo((): DropdownItemProps[] => {
    return Object.entries(players).filter(([id]) => id !== uid).map(([id, player]) => ({
      text: player.name,
      value: id,
    }))
  }, [players, uid])

  const onChange = useCallback((e, data) => {
    let { name, value } = e.target
    if (!name && !value) {
      name = data.name
      value = data.value
    }
    setState(state => update(state, {
      [name]: { $set: value },
    }))
  }, [])

  const onSubmit = useCallback(async () => {
    if (!s.user || !uid) {
      throw new Error('Failed precondition.')
    }

    await onOfferTrade('offer', {
      id: db.collection('games').doc().id,
      from: uid,
      to: s.user,
      cost: { cash: (s.price) * s.quantity },
      price: Number(s.price),
      quantity: Number(s.quantity),
      resource,
    })

    setOpen(false)
  }, [uid, onOfferTrade, s, resource, setOpen])

  const cashCost = resource_costs[resource]?.cash || 0
  useEffect(() => {
    if (open) {
      setState(s => ({
        quantity: 1,
        price: round((cashCost), 2),
        user: s.user,
      }))
    }
  }, [open, cashCost, resource])

  let error = ''
  if (s.quantity > resources[resource]) {
    error = `You do not have enough ${translateResource(resource)} for this offer.`
  } else if (!s.user) {
    error = 'Please select a player.'
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Modal.Header>Offer {s.quantity} {translateResource(resource)} for {truncateCash(s.quantity * (s.price), undefined, true)}</Modal.Header>
      <Modal.Content image>
        <Image src={trade} wrapped size="small" />
        <Modal.Description>
          <Form>
            <Form.Group widths="equal" inline>
              <Form.Field>
                <label>Quantity</label>
                <Input
                  type="number"
                  value={s.quantity}
                  name="quantity"
                  onChange={onChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Price (per unit)</label>
                <Input
                  type="number"
                  value={s.price}
                  name="price"
                  onChange={onChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Player</label>
                <Dropdown
                  selection
                  name="user"
                  options={items}
                  value={s.user}
                  onChange={onChange}
                />
              </Form.Field>
            </Form.Group>
          </Form>
          <Message color="blue">Total Cost: {truncateCash(s.quantity * s.price)}</Message>
          {error && <Message color="red">{error}</Message>}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <PromiseButton ignoreGameLogic secondary onClick={() => setOpen(false)}>Cancel</PromiseButton>
        <PromiseButton ignoreGameLogic onClick={onSubmit} disabled={!s.user || !!error} primary>Send Offer!</PromiseButton>
      </Modal.Actions>
    </Modal>
  )
}
