import React from 'react'
import { Card } from 'semantic-ui-react'
import { useSelector } from '../../../../hooks/useSelector'
import PromiseDiv from '../../../../components/PromiseDiv'
import usePlayerActions from '../../hooks/usePlayerActions'
import { truncateCash } from '../../../../utils/truncateCash'
import classNames from 'classnames'

export const PlayerStarbaseLoans = () => {
  const loans = useSelector(s => s.game.sheet?.starbase_loans) || []
  const { onUpgradePlanet } = usePlayerActions()
  const is_map = useSelector(s => s.game.galaxy_display)

  if (!loans.length) {
    return null
  }

  return (
    <Card className={classNames({ is_map })} fluid style={{ zIndex: 1 }}>
      <Card.Content>
        <Card.Header>Starbase Loans</Card.Header>
      </Card.Content>

      <Card.Content style={{ paddingBottom: 0 }}>
        <div className="loan-wrapper">
          <div className="loan-header">
            <div className="loan-column">Amount</div>
            <div className="loan-column">Cost</div>
            <div className="loan-column">Rate</div>
          </div>
          {loans.map((loan, index) => {
            return (
              <PromiseDiv onClick={() => onUpgradePlanet(index, 'starbase-refund')} key={loan.amount} className="loan-row active">
                <div className="loan-column">{truncateCash(loan.amount)}</div>
                <div className="loan-column">{truncateCash(loan.cost)}</div>
                <div className="loan-column">{(loan.cost / loan.amount * 100).toFixed(1)}%</div>
              </PromiseDiv>
            )
          })}
        </div>
      </Card.Content>
    </Card>
  )
}
