import { useEffect } from 'react'
import usePlayerActions from './usePlayerActions'
import { useDispatch } from 'react-redux'
import { GameBoard } from '../../../../types'
import { setGameBoard } from '../../../reducers/game/game'
import { useSocket } from '../../../hooks/useSocket'
import { useParams } from 'react-router-dom'

export default function useGameBoard() {
  const { onGetGameBoard } = usePlayerActions()
  const { id } = useParams<any>()
  const dispatch = useDispatch()
  const socket = useSocket()
  useEffect(() => {
    if (!socket || !socket.authenticated) {
      return
    }

    onGetGameBoard(id).then(r => {
      const board: GameBoard = r.data
      dispatch(setGameBoard(board))
    })
  }, [onGetGameBoard, dispatch, socket, id])
}
