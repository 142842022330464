import React, { useCallback, useState } from 'react'
import { Header, Modal } from 'semantic-ui-react'
import { useSelector } from '../hooks/useSelector'
import usePlayerActions from '../views/SheetView/hooks/usePlayerActions'
import PromiseButton from './PromiseButton'

interface GamePausedModalProps {
  name: string
}

const story = [
  (name: string) => `${name} has paused the game!`,
  'Wait a minute... you weren\'t the one to pause it!',
  (name: string) => `I mean if you really think it's okay with ${name}, then you can...`,
  (name: string) => `Did ${name} really say it was okay to unpause it? Maybe we should ask them...`,
  'Come on, have a little dignity, you can wait.',
  'Seriously? You\'re quite persistent today...',
  (name: string) => `Fine you know what? If you really think ${name} is ready to go, why not ask them to unpause it?`,
  'Okay... you have been persistent enough. I suppose I\'ll allow you to unpause it.',
  'Give me a couple more clicks, I really am enjoying this...',
  'Mmm... faster!',
  'You got this... you\'re almost there...',
  'I am not joking, you are actually capable of unpausing the game, you\'re just going to need to press it a few more times.',
  'I\'m really struggling to tell if this easter egg was more annoying than it was enjoyable. I hope you do find some enjoyment in it.',
  'This annoying easter egg brought to you by Andrew Hartwig!',
  'Okay, this time when you click it I will genuinely unpause the game.',
  'I love you!',
]

export const GamePausedModal = ({ name }: GamePausedModalProps) => {
  const { onPauseGame } = usePlayerActions()
  const [storyIndex, setStoryIndex] = useState(0)
  const uid = useSelector(s => s.auth.uid) || ''
  const myName = useSelector(s => s.game.metadata?.players[uid].name)

  // @ts-ignore
  const text = story[0](name) as string
  const [buttonText, setButtonText] = useState(text)

  const onClick = useCallback(async () => {
    if (name !== myName) {
      const next = story[storyIndex + 1]
      if (typeof next === 'function') {
        setButtonText(next(name))
        setStoryIndex(s => s + 1)
        return
      } else if (typeof next === 'string') {
        setButtonText(next)
        setStoryIndex(s => s + 1)
        return
      }
    }

    await onPauseGame(false)
  }, [onPauseGame, name, myName, storyIndex])

  return (
    <Modal basic open>
      <Modal.Content>
        <Header inverted size="huge" style={{ display: 'flex', alignItems: 'center' }}>
          {buttonText}
        </Header>
      </Modal.Content>
      <PromiseButton ignoreGameLogic style={{ marginLeft: '32px' }} color="green" onClick={onClick}>Unpause</PromiseButton>
    </Modal>
  )
}
