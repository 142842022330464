const translations: any = {
  J: 'Jupiter',
  U: 'Uranus',
  N: 'Neptune',
  P: 'Pluto',
  E: 'Earth',
  M: 'Mercury',
  S: 'Saturn',
  R: 'Rift',
  'ξ': 'ξ - Epsilon',
  'β': 'β - Beta',
  'K': 'K - Kappa',
  'O': 'O - Omicron',
  'α': 'α - Alpha',
  'Q': 'Q - Quion',
  'τ': 'τ - Tensilon',
  'Z': 'Z - Zeta',
}

export default function translateOrbitingPlanet(key: string) {
  return translations[key] || key
}
