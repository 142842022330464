import React, { useState, useCallback } from 'react'
import update from 'immutability-helper'
import { Container, Table, Grid, Button, Card, Form, Input, Checkbox, Segment, Dropdown } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import useGame from './hooks/useGame'
import { useSelector } from '../../hooks/useSelector'
import PromiseButton from '../../components/PromiseButton'
import usePlayerActions from '../SheetView/hooks/usePlayerActions'
import useRouting from '../../hooks/useRouting'
import { GameOptions } from '../../../types'
import { useJoinGameRoom } from '../../hooks/useJoinGameRoom'
import { useDispatch } from 'react-redux'
import { setGame } from '../../reducers/game/game'
import { round } from 'lodash'

export default () => {
  const uid = useSelector(s => s.auth.uid)
  const {
    game,
    onStart,
  } = useGame()

  useJoinGameRoom()

  const _options = useSelector(s => s.game.options)
  const [options, setOptions] = useState<any>({})
  const [dirty, setDirty] = useState<boolean>(false)
  const owner_id = useSelector(s => s.game.game?.owner)

  const disabled = owner_id !== uid

  const onChange = useCallback((e, data) => {
    const { name, value, checked, options } = data

    var result = difficultyOptions.filter(obj => {
      return obj.key === value
    })

    if (typeof checked !== 'undefined') {
      setOptions((o: any) => update(o, {
        [name]: { $set: checked },
      }))
    } else if (typeof options !== 'undefined') {
      setOptions((o: any) => update(o, {
        [name]: { $set: result[0].difficulty }, // 5 is normal difficulty (challenging), 8 is hardest
        starting_cash: { $set: Math.round(500 / result[0].difficulty) },
        starting_population: { $set: Math.round(65 / result[0].difficulty) },
        planet_rolls_count: { $set: Math.round(40 / (result[0].difficulty + 5)) },
        difficulty_recession_length_multiplier: { $set: round((result[0].difficulty + 30) / 35, 3) }, // 15/10 35/30
        difficulty_recession_revenue_multiplier: { $set: round(45 / (result[0].difficulty + 40), 3) }, // 15/10 45/40
        difficulty_planet_resource_requirements: { $set: round((result[0].difficulty + 5) / 10, 2) },
        planet_upgrade_speed: { $set: round(10 / (result[0].difficulty + 5), 2) }, // /
        difficulty_geomagnetic_storms: { $set: round(Math.min(Math.max((result[0].difficulty - 3) / 2.5, 0), 1.2), 2) }, // Max rate 20% increase, min is 0%
        difficulty_credit_rating_impact: { $set: round(20 / (result[0].difficulty + 15), 2) }, // 10/5 20/15
        difficulty_bond_spread: { $set: round((1 - (result[0].difficulty / 5)) / 50, 3) },
        config_credit_rating_interest_spread: { $set: round(8 / (result[0].difficulty + 3), 2) }, // /
      }))
    } else {
      setOptions((o: any) => update(o, {
        [name]: { $set: Number(value) },
      }))
    }
    setDirty(true)
  }, [])

  const {
    onLeaveLobby,
    onDeleteGame,
    onSetGameOptions,
  } = usePlayerActions()

  const onSave = useCallback(async () => {
    await onSetGameOptions(options)
    setDirty(false)
    setOptions({})
  }, [options, onSetGameOptions])

  const { goBack } = useRouting()

  const dispatch = useDispatch()

  if (!game || !game.id) {
    return <div />
  }

  if (game.id && game.status === 'deleted') {
    setImmediate(() => {
      dispatch(setGame())
    })
    return <Redirect to="/games" />
  }

  if (game.status === 'active') {
    return <Redirect to={`/game/${game.id}`} />
  }

  const isOwner = game.owner === uid

  const buildField = (label: string, name: string, opt?: any) => {
    let value: any
    let input: any

    if (typeof (_options || {})[name as keyof GameOptions] === 'boolean') {
      if (typeof options[name] === 'boolean') {
        value = options[name]
      } else {
        value = (_options || {})[name as keyof GameOptions]
      }
      input = (
        <Checkbox
          label={label}
          checked={value}
          onChange={onChange}
          name={name}
          disabled={disabled || opt?.disabled}
        />
      )
    } else if (name === 'difficulty_settings') {
      value = name
      input = (
        <Dropdown
          placeholder='Challenging'
          label={label}
          // selection={value}
          // checked={value}
          options={difficultyOptions}
          onChange={onChange}
          name={name}
          disabled={disabled || opt?.disabled}
        />
      )
    } else {
      value = options[name] || (_options || {})[name as keyof GameOptions] || 0
      input = (
        <Input
          type="number"
          value={value}
          disabled={disabled}
          onChange={onChange}
          name={name}
          min={0}
          step={opt?.step || 1}
        />
      )
    }
    return (
      <Form.Field>
        <label>{label}</label>
        {input}
      </Form.Field>
    )
  }

  return (
    <Container>
      <Grid>
        <Grid.Column width={12}>
          <Table selectable striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Players</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {game.players.map(({ name, id }) => {
                return (
                  <Table.Row key={id}>
                    <Table.Cell>{name}</Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column width={4}>
          <Button.Group vertical fluid>
            <PromiseButton disabled={!isOwner} onClick={onStart} color="green">Start Game</PromiseButton>
            <PromiseButton disabled={!isOwner} onClick={onDeleteGame} color="yellow">Cancel Game</PromiseButton>
          </Button.Group>
          <Button.Group vertical fluid style={{ marginTop: '16px' }}>
            <PromiseButton disabled={isOwner} onClick={() => onLeaveLobby().then(() => goBack())} color="teal">Leave Lobby</PromiseButton>
          </Button.Group>
        </Grid.Column>
      </Grid>
      {_options && (
        <Grid>
          <Grid.Column width={16}>
            <Card fluid>
              <Card.Content style={{ position: 'relative' }}>
                {isOwner && (
                  <PromiseButton disabled={!dirty} size="mini" style={{ position: 'absolute', right: '16px', top: '9px' }} onClick={onSave} primary>Save</PromiseButton>
                )}
                <Card.Header>Modifiers (Experimental)</Card.Header>
              </Card.Content>
              <Card.Content>
                <Form>
                  <Segment color="red">
                    <h3><span role="img" aria-label="clock">🛡️</span> Difficulty Settings</h3>
                    <Form.Group widths="4">
                      {buildField('Difficulty', 'difficulty_settings')}
                      {buildField('Recession Length', 'difficulty_recession_length_multiplier')}
                      {buildField('Recession Revenue', 'difficulty_recession_revenue_multiplier')}
                      {buildField('Resource Requirements', 'difficulty_planet_resource_requirements')}
                      {buildField('Planet Upgrade Speed', 'planet_upgrade_speed')}
                      {buildField('Storms', 'difficulty_geomagnetic_storms')}
                    </Form.Group>
                  </Segment>
                  <Segment color="purple">
                    <h3><span role="img" aria-label="clock">🛡️</span> Loans and Interest</h3>
                    <Form.Group widths="4">
                      {buildField('Credit Rating D/E', 'difficulty_credit_rating_impact')}
                      {buildField('Interest', 'config_base_prime_interest')}
                      {buildField('CR_Interest Spread', 'config_credit_rating_interest_spread')}
                      {buildField('Leverage', 'config_leverage_factor')}
                    </Form.Group>
                  </Segment>
                  <Segment color="grey">
                    <h3><span role="img" aria-label="start">⚙️</span> Game Config</h3>
                    <Form.Group widths="4">
                      {buildField('Disable Planet Tokens', 'disable_planet_tokens')}
                      {buildField('Planet Dice Roll', 'planet_rolls_count')}
                      {buildField('Trade Occurence', 'trade_occurence', { step: 0.01 })}
                      {buildField('Trade Sell Price', 'config_trade_sell_price_ratio')}
                      {buildField('Trade Buy Price', 'config_trade_buy_price_ratio')}
                    </Form.Group>
                  </Segment>
                  <Segment color="grey">
                    <h3><span role="img" aria-label="start">⚙️</span> Player Config</h3>
                    <Form.Group widths="4">
                      {buildField('Show Equity', 'config_show_equity')}
                      {buildField('Starting Cash', 'starting_cash')}
                      {buildField('Starting Population', 'starting_population')}
                      {buildField('Starting Bonds', 'starting_bonds')}
                      {buildField('Bond Spread', 'difficulty_bond_spread')}
                      {buildField('Starting LOC', 'starting_loc')}
                      {buildField('Mine Counts', 'config_mine_counts')}
                    </Form.Group>
                  </Segment>
                  <Segment color="violet">
                    <h3><span role="img" aria-label="random">🎲</span> Random Planet Generation</h3>
                    <Form.Group widths="4">
                      {buildField('Random Planet Generation', 'random_planet_generation')}
                    </Form.Group>
                    <Form.Group widths="4">
                      {buildField('Random Planets Unlock Year', 'random_planets_access_year')}
                      {buildField('Bonus Random Quadrants Count', 'randomly_generated_quadrants')}
                      {buildField('Random Planets Per Quadrant (coming)', 'random_planets_per_quadrant')}
                      {buildField('Random Planets Unlock Interval', 'random_planets_unlock_interval')}
                    </Form.Group>
                  </Segment>
                  <Segment color="green">
                    <h3><span role="img" aria-label="clock">⏰</span> Game Timer</h3>
                    <Form.Group widths="4">
                      {buildField('Disable Turn Timers', 'disable_timer')}
                      {buildField('Buy Phase Time', 'timer_buy_phase_duration')}
                      {buildField('Trade Phase Time', 'timer_trade_phase_duration')}
                      {buildField('Travel Phase Time', 'timer_travel_phase_duration')}
                      {buildField('Timer Refreshes', 'timer_refresh_charges')}
                      {buildField('Refresh Renewal', 'refresh_charge_renewal')}
                      {buildField('Time Gained', 'refresh_time_gained')}
                    </Form.Group>
                  </Segment>
                </Form>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      )}
    </Container>
  )
}

const difficultyOptions = [ // also make sure to update the text in the sheetHeader
  {
    key: 'Cakewalk',
    text: 'Cakewalk 🍰',
    value: 'Cakewalk',
    difficulty: 2,
  },
  {
    key: 'Very Easy',
    text: 'Very Easy 😀',
    value: 'Very Easy',
    difficulty: 3,
  },
  {
    key: 'Easy',
    text: 'Easy 😀',
    value: 'Easy',
    difficulty: 4,
  },
  {
    key: 'Normal',
    text: 'Normal 🙂',
    value: 'Normal',
    difficulty: 5,
  },
  {
    key: 'Challenging',
    text: 'Challenging 😐',
    value: 'Challenging',
    difficulty: 5.5,
  },
  {
    key: 'Tough',
    text: 'Tough 😬',
    value: 'Tough',
    difficulty: 6, // 6 5.5 Sept. 4 //anything from bot update?
  },
  {
    key: 'Brutal',
    text: 'Brutal 😢',
    value: 'Brutal',
    difficulty: 6.5, // 7 6 Sept. 4
  },
  {
    key: 'Painful',
    text: 'Painful 😮',
    value: 'Painful',
    difficulty: 7, // 7 6 Sept. 4
  },
  {
    key: 'Crippling',
    text: 'Crippling 😭',
    value: 'Crippling',
    difficulty: 7.5, // 8 6.5 Sept. 4
  },
  {
    key: 'Masochistic',
    text: 'Masochistic 👿',
    value: 'Masochistic',
    difficulty: 8, // 9 was masochistic - hardest setting we played on before; 7 Sept. 4
  },
  {
    key: 'Obscene',
    text: 'Obscene 😨',
    value: 'Obscene',
    difficulty: 8.5, // 10 7.5 Sept. 4
  },
  {
    key: 'Lethal',
    text: 'Lethal ☠️',
    value: 'Lethal',
    difficulty: 9, // 11 8 Sept. 4
  },
  {
    key: 'Futile',
    text: 'Futile 😖',
    value: 'Futile',
    difficulty: 9.5, // 11 8 Sept. 4
  },
]
