import React, { useCallback, useEffect, useState } from 'react'
import { Container, TextArea } from 'semantic-ui-react'
import PromiseButton from '../../components/PromiseButton'
import useRouting from '../../hooks/useRouting'
import { useSelector } from '../../hooks/useSelector'
import { useJoinAdminEditorLobby } from './hooks/useJoinAdminEditorLobby'
import usePlayerActions from './hooks/usePlayerActions'

export const GameDataEditor = () => {
  useJoinAdminEditorLobby()

  const {
    onAdminSaveGame,
  } = usePlayerActions()

  const { goBack } = useRouting()

  const [metadata, setMetadata] = useState<string>()
  const [sheets, setSheets] = useState<string>()

  const _metadata = useSelector(s => s.game.metadata)
  const _sheets = useSelector(s => s.game.admin_sheets)

  const onSave = useCallback(() => {
    if (!metadata || !sheets) return

    const newMeta = JSON.parse(metadata)
    const newSheets = JSON.parse(sheets)

    return onAdminSaveGame(newSheets, newMeta).then(() => goBack())
  }, [onAdminSaveGame, metadata, sheets, goBack])

  useEffect(() => {
    if (!_metadata || !_sheets) {
      return
    }

    setMetadata(JSON.stringify(_metadata, null, 2))
    setSheets(JSON.stringify(_sheets, null, 2))
  }, [_metadata, _sheets])

  return (
    <div style={{ paddingBottom: '256px' }}>
      <Container>
        <h1>Editing stuff in here is super dangerous!</h1>
        <p>Ensure you write 100% proper JSON or you will completely obliterate this game.</p>
        <PromiseButton ignoreGameLogic primary onClick={onSave}>Save</PromiseButton>
        <h3>METADATA</h3>
        <TextArea spellCheck="false" onChange={(e) => setMetadata(e.currentTarget.value)} style={{ width: '100%', height: '600px', fontFamily: 'Courier New', fontWeight: 'bold' }} value={metadata} />
        <h3>PLAYER SHEETS</h3>
        <TextArea spellCheck="false" onChange={(e) => setSheets(e.currentTarget.value)} style={{ width: '100%', height: '600px', fontFamily: 'Courier New', fontWeight: 'bold' }} value={sheets} />
      </Container>
    </div>
  )
}
