import React, { MutableRefObject } from 'react'
import './GameBoard.scss'
import classnames from 'classnames'
import { useGameBoardActions } from '../../hooks/useGameBoardActions'
import useGameBoard from '../../hooks/useGameBoard'

export const GameBoard = ({ edit }: { edit?: boolean }) => {
  const { iFrameRef, poppedOut } = useGameBoardActions()

  useGameBoard()

  return (
    <div className={classnames('gameboard-container', { edit })}>
      {!poppedOut && (
        <iframe
          onContextMenu={e => { e.preventDefault(); e.stopPropagation() }}
          ref={iFrameRef as MutableRefObject<HTMLIFrameElement>}
          src="/godot-out/PrometheusBoard.html"
          title="Prometheus Board"
        />
      )}
    </div>
  )
}
