import React from 'react'
import { StarbaseLoan, GameBoard } from '../../../../../types'
import './PlanetDetailsDisplayLoan.scss'
import PromiseDiv from '../../../../components/PromiseDiv'
import usePlayerActions from '../../hooks/usePlayerActions'
import { truncateCash } from '../../../../utils/truncateCash'

interface PlanetDetailsDisplayLoanProps {
  deed: StarbaseLoan
  planet: keyof GameBoard
}

export const PlanetDetailsDisplayLoan = ({ deed, planet }: PlanetDetailsDisplayLoanProps) => {
  const {
    onUpgradePlanet,
  } = usePlayerActions()

  return (
    <div className="loan-wrapper">
      <div className="loan-header">
        <div className="loan-column">Amount</div>
        <div className="loan-column">Cost</div>
        <div className="loan-column">Rate</div>
      </div>
      {deed.offer.map((loan) => {
        return (
          <PromiseDiv onClick={() => onUpgradePlanet([planet, loan.amount], 'starbase')} key={loan.amount} className="loan-row">
            <div className="loan-column">{truncateCash(loan.amount).replace('.00', '')}</div>
            <div className="loan-column">{truncateCash(loan.cost).replace('.00', '')}</div>
            <div className="loan-column">{(loan.cost / loan.amount * 100).toFixed(1)}%</div>
          </PromiseDiv>
        )
      })}
    </div>
  )
}
