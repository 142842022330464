import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { GameDataEditor } from './GameDataEditor'
import GameSheet from './GameSheet'
import { GameBoardProvider } from './components/GameBoard/GameBoardProvider'

export default () => {
  return (
    <Switch>
      <Route path="/game/:id/edit" component={GameDataEditor} />
      <Route path="/game/:id" render={() => (
        <GameBoardProvider>
          <GameSheet />
        </GameBoardProvider>
      )} />
    </Switch>
  )
}
