import React from 'react'
import { Card, List, Popup } from 'semantic-ui-react'
import { useSelector } from '../../../hooks/useSelector'
import { PlayerStatus, PlayerStatuses } from '../../../../types/metadata'
import sum from 'lodash/sum'
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic'
import { useFocusPlayer } from '../../../hooks/useFocusPlayer'
import './PlayerStatusList.scss'
import { truncateCash } from '../../../utils/truncateCash'
import { findCreditRatingColor } from '../../../utils/calculateBalanceSheet'
import { round } from 'lodash'
import classNames from 'classnames'

interface PlayerStatusListItemProps {
  player: PlayerStatus
}

export const PlayerStatusListItem = ({ player }: PlayerStatusListItemProps) => {
  const players_choosing = useSelector(s => s.game.metadata?.players_choosing_planet) || []
  const players = useSelector(s => s.game.metadata?.players)
  const config_show_equity = useSelector(s => s.game.metadata?.config_show_equity)
  const public_income = useSelector(s => s.game.metadata?.players[player.id].public_income) || 0
  const public_rating = useSelector(s => s.game.metadata?.players[player.id].public_credit_rating) || ''
  const connectionStatus = useSelector(s => s.game.connection_status[player.id])
  const [orbiting_info, planet] = player.current_planet.split(':')
  const [planetName] = planet.split('|')
  const public_equity = (players?.[player.id].public_equity) || 0
  let total_game_equity = 0
  if (players) {
    for (const [, player] of Object.entries(players)) {
      if (player.public_equity > 0) {
        total_game_equity += player.public_equity
      }
    }
  }
  const equity_percentage = (public_equity / total_game_equity) * 100
  const {
    onFocusPlayer,
  } = useFocusPlayer(player)

  let [orbiting_planet, info] = orbiting_info.split('|')
  if (orbiting_planet === 'R') {
    orbiting_planet = info
  }

  let color: SemanticCOLORS
  let choosing = players_choosing.indexOf(player.id) !== -1
  if (player.dead) {
    color = 'red'
  } else if (player.ready) {
    color = 'green'
  } else if (choosing) {
    color = 'yellow'
  } else {
    color = 'black'
  }

  return (
    <List.Item onClick={onFocusPlayer} style={{ cursor: 'pointer', position: 'relative' }}>
      <List.Icon color={color} size="large" name="user" verticalAlign="top" />
      <List.Content>
        {!player.dead && (<List.Header><span style={{ color: player.color }}>{player.name}</span> - Roll: {sum(player.roll || [])}{choosing && ' - Choosing'}</List.Header>)}
        {player.dead && (<List.Header>{player.name}</List.Header>)}
        {player.dead && (<List.Description>Vanquished</List.Description>)}
      </List.Content>
      <div className="PSL-splitter">
        <List.Content>
          {!player.dead && (<List.Description>Planet: {planetName}</List.Description>)}
          {!player.dead && (<List.Description>Owned Planets: {player.planets.length}</List.Description>)}
          {!player.dead && (
            <List.Description>
              Rating: <span style={{ backgroundColor: findCreditRatingColor(public_rating), padding: '0 2px', borderRadius: '2px' }}>{public_rating}</span>
            </List.Description>
          )}
        </List.Content>
        {(config_show_equity) && <List.Content>
          {!player.dead && (<List.Description>Equity: {truncateCash(public_equity, { raw: false }, true)}</List.Description>)}
          {!player.dead && (<List.Description>Equity: <span style={{ color: `${equity_percentage > 80 ? 'red' : ''}` }}>{round(equity_percentage, 2)}</span>%</List.Description>)}
          {!player.dead && (<List.Description >Income: <span style={{ color: `${public_income > 0 ? 'green' : 'red'}` }}>{truncateCash(public_income)}</span></List.Description>)}
        </List.Content>}
      </div>
      <div className={classNames('psl-disconnected', connectionStatus)}>
        <div>
          DISCONNECTED
        </div>
      </div>
    </List.Item>
  )
}

export default () => {
  const _players = useSelector(s => s.game.metadata?.players) || {} as PlayerStatuses
  const players = [] as PlayerStatus[]
  const rolls = useSelector(s => s.game.metadata?.planet_rolls) || []
  const is_map = useSelector(s => s.game.galaxy_display)
  Object.keys(_players).forEach((uid) => {
    players.push(_players[uid])
  })
  players.sort((a, b) => a.name.localeCompare(b.name))
  return (
    <Card fluid className={classNames({ is_map })} style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, zIndex: 1 }}>
      <Card.Content>
        <Card.Header>Voyagers</Card.Header>
        <Popup basic trigger={(
          <List divided relaxed>
            {players.map((player) => {
              return (
                <PlayerStatusListItem key={player.name} player={player} />
              )
            })}
          </List>
        )}>
          Click to follow player on the map
        </Popup>
      </Card.Content>
      {rolls.length ? (
        <Card.Content>
          <Card.Description style={{ fontSize: '24px' }}>Planet Rolls: [{rolls.join(', ')}]</Card.Description>
        </Card.Content>
      ) : null}
    </Card>
  )
}
