import React, { } from 'react'
import { Popup, Menu, Icon } from 'semantic-ui-react'
import PromiseButton from '../../../../components/PromiseButton'
import { useSelector } from '../../../../hooks/useSelector'
import usePlayerActions from '../../hooks/usePlayerActions'
import './Quests.scss'
// import usePlayerActions from '../../hooks/usePlayerActions';

export const QuestsHoverOver = () => {
  const quests = useSelector(s => s.game.sheet?.quests)
  const turn = useSelector(s => s.game.metadata?.turn) || 0
  const { onUpgradePlanet } = usePlayerActions()
  // const { onUpgradePlanet } = usePlayerActions();

  return (
    <>
      <Popup hoverable trigger={(
        <Menu.Item>
          <Icon name = "tasks" color="blue" />
          Quests
        </Menu.Item>
      )}>
        <div style={{ width: '1000px' }} className="q-breakdown">
          <div className="q-header" style={{ justifyContent: 'center', textAlign: 'center' }}>
            Quests Available
          </div>
          <div className="q-content">
            <div className={'minicolumn bold'} style={{ justifyContent: 'center', textAlign: 'center' }}>Submit Quest?</div>
            <div className={'minicolumn bold'} style={{ justifyContent: 'center', textAlign: 'center' }}>Quest Deadline</div>
            <div className={'minicolumn bold'} style={{ justifyContent: 'center', textAlign: 'center' }}>Quest <br/> Status</div>
            <div className={'columns bold center'} style={{ justifyContent: 'center', textAlign: 'center' }}>Quest <br/>Requirements</div>
            <div className={'columns bold'} style={{ justifyContent: 'center', textAlign: 'center' }}>Quest <br/>Rewards</div>
            <div className={'minicolumn bold'} style={{ justifyContent: 'center', textAlign: 'center' }}>Activate Reward?</div>
          </div>
          {quests?.active_quests.map(r => {
            return (
              <div key={r.id} className="q-content">
                <div className={'minicolumn'}>
                  {r.complete ? 'Quest Completed' :
                    <PromiseButton style={{ size:'tiny' }} onClick={() => onUpgradePlanet([r], 'submit_quest')}
                      key={r.id + 'submit'} // this is just a key, any string I want I believe, so can replace this with whatever we like (tried adding my own string and its fine!)
                      className={'padded'}
                    >
                        +
                    </PromiseButton>
                  }
                </div>
                <div className={'minicolumn'}>{r.complete ? '' : (r.deadline || 0) - turn + ' years'}</div>
                <div className={'minicolumn'} style={{ color: `${r.complete ? 'green' : 'black'}` }}>{(r.complete ? 'Complete' : 'Incomplete') }</div>
                <div className={'columns'}><span style={{ textAlign: 'left' }}>{r.objective_message}</span></div>
                <div className={'columns'}>
                  {r.reward_message}{r.reward_charges && r.reward_charges > 0 ? ` Can be used ${r.reward_charges} times.` : r.reward_activated === false ? `. Can be active for ${r.reward_duration} years.` : ''}
                </div>

                <div className={'minicolumn'}>
                  {r.reward_activated === true ? r.reward_duration ? `activated for ${r.reward_duration} years` : `${r.reward_charges} charges remaining` :
                    <PromiseButton style={{ size:'tiny' }} onClick={() => onUpgradePlanet([r], 'activate_reward')}
                      key={`${r.id} reward`} // this is just a key, any string I want I believe, so can replace this with whatever we like (tried adding my own string and its fine!)
                      className={'padded'}
                    >
                      +
                    </PromiseButton>}
                </div>
              </div>
            )
          })}
        </div>
      </Popup>
    </>
  )
}
