import { User } from 'firebase/app'

interface AuthState {
  authorized: boolean
  name?: string | null
  email?: string | null
  uid?: string | null
  displayName?: string | null
  connected: boolean
}

const initialState: AuthState = {
  authorized: false,
  connected: false,
}

export const AUTH_STATE_CHANGED = 'auth/AUTH_STATE_CHANGED'
export const SET_DISPLAY_NAME = 'auth/SET_DISPLAY_NAME'
export const SET_CONNECTED = 'auth/SET_CONNECTED'

export default function (state: AuthState = initialState, action: AuthActionTypes): AuthState {
  switch (action.type) {
  case AUTH_STATE_CHANGED:
    return {
      ...state,
      name: action.payload?.displayName,
      email: action.payload?.email,
      authorized: !!action.payload,
      uid: action.payload?.uid,
    }
  case SET_DISPLAY_NAME:
    return {
      ...state,
      displayName: action.payload,
    }
  case SET_CONNECTED:
    return {
      ...state,
      connected: action.payload,
    }
  default:
    return state
  }
}

interface AuthStateChangedAction {
  type: typeof AUTH_STATE_CHANGED
  payload: User | null
}

interface SetDisplayNameAction {
  type: typeof SET_DISPLAY_NAME
  payload?: string
}

interface SetConnectedAction {
  type: typeof SET_CONNECTED
  payload: boolean
}

export function updateAuthorization(user: User | null): AuthStateChangedAction {
  return {
    type: AUTH_STATE_CHANGED,
    payload: user,
  }
}

export function setDisplayName(name?: string): SetDisplayNameAction {
  return {
    type: SET_DISPLAY_NAME,
    payload: name,
  }
}

export function setConnected(connected: boolean): SetConnectedAction {
  return {
    type: SET_CONNECTED,
    payload: connected,
  }
}

export type AuthActionTypes = AuthStateChangedAction | SetDisplayNameAction | SetConnectedAction
