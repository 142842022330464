import React, { ReactNode } from 'react'
import { Popup, Header, Icon, Menu } from 'semantic-ui-react'
import usePlayerActions from '../../hooks/usePlayerActions'
import { useSelector } from '../../../../hooks/useSelector'
import PromiseMenuItem from '../../../../components/PromiseMenuItem'
import formatCost from '../../../../utils/formatCost'
import { round } from 'lodash'
import { calcQuest } from './calculateQuestBonus'

interface LOCRateUpgradesPopupProps {
  children: ReactNode
}

export default ({ children }: LOCRateUpgradesPopupProps) => {
  const loc_rate_upgrades = useSelector(s => s.game.options?.loc_rate_upgrades) || []
  const loc = useSelector(s => s.game.sheet?.loc)
  const level = loc?.rate_level
  const prime_rate = useSelector(s => s.game.metadata?.prime_rate) || 0
  const credit_risk = + (useSelector(s => s.game.sheet?.credit_risk_adjusted_rate) || 0)
  const quest_loc_bonus = useSelector(s => s.game.sheet?.quests.rewards.loc_rate_bonus)
  const rate_additions = (credit_risk + prime_rate + calcQuest(quest_loc_bonus || [])) * 100
  if (typeof level !== 'number') {
    return null
  }
  const {
    onUpgrade,
  } = usePlayerActions()

  return (
    <Popup basic hoverable mouseEnterDelay={400} position="bottom right" trigger={children}>
      <Header>LOC Rate Upgrades</Header>
      <Menu vertical style={{ width: '380px', maxHeight: '512px', overflowY: 'auto' }}>
        {loc_rate_upgrades.map(({ cost, rate }, i) => {
          const disabled = i > level + 1
          const isNextUpgrade = i === level + 1
          const isUpgraded = i <= level
          const className = isUpgraded ? 'strike' : ''
          let onClick
          if (isNextUpgrade) {
            onClick = () => onUpgrade('loc_rate')
          }
          return (
            <PromiseMenuItem key={rate} ignoreGameLogic disabled={disabled} onClick={onClick}>
              {isNextUpgrade && (<Icon name="angle double up" color="green" size="large" />)}
              <Menu.Header className={className}>Level {i + 1} | Rate: { round(rate + rate_additions, 2) }%</Menu.Header>
              <p className={className}>{formatCost(cost)}</p>
            </PromiseMenuItem>
          )
        })}
      </Menu>
    </Popup>
  )
}
