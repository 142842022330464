import React, { useState, useEffect, useCallback, useRef, Fragment, CSSProperties, useMemo } from 'react'
import update from 'immutability-helper'
import { Table, TableHeader, Input, Card, Label } from 'semantic-ui-react'
import { useSelector } from '../../../../hooks/useSelector'
import { Resource, GalacticTraderOffer, GalacticTrader } from '../../../../../types'
import { translateResource } from '../../../../utils/translateResource'
import { sumBy, round, isEqual } from 'lodash'
import usePlayerActions from '../../hooks/usePlayerActions'
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic'
import PromiseButton from '../../../../components/PromiseButton'
import { truncateCash } from '../../../../utils/truncateCash'
import classNames from 'classnames'
import { getResourceUsageForecast } from '../../../../utils/getResourceUsageForecast'

export const resources: (keyof Resource)[] = ['Wa', 'Cu', 'Fe', 'Ag', 'Au', 'Oil', 'Dia', 'Ti', 'Pt', 'U']

type tradeStatus =
  'empty' |
  'editing' |
  'syncing' |
  'saved' |
  'error' |
  'done'

const tradeStatusColors: Record<tradeStatus, [CSSProperties['color'], CSSProperties['color']]> = {
  done: ['green', 'white'],
  empty: ['green', 'white'],
  editing: ['orange', 'white'],
  syncing: ['black', 'white'],
  saved: ['darkgreen', 'lightgreen'],
  error: ['red', 'white'],
}

const tradeStatusText: Record<tradeStatus, string> = {
  done: 'Done',
  empty: 'Empty',
  editing: 'Editing',
  syncing: 'Syncing',
  saved: 'Saved 😊',
  error: 'Error',
}

export default () => {
  const planets = useSelector(s => s.game.sheet?.planets) || {}
  const trade = useSelector(s => s.game.metadata?.trade)
  const myResources = useSelector(s => s.game.sheet?.resources)
  const resourceCosts = useSelector(s => s.game.metadata?.resource_costs) || {}
  const cash = useSelector(s => s.game.sheet?.cash) || 0
  const is_map = useSelector(s => s.game.galaxy_display)
  const savedOffer = useSelector(s => s.game.sheet?.trade_offer)
  const savedOfferRef = useRef(savedOffer)
  const [dirty, setDirty] = useState<boolean>(() => savedOffer?.committed === false)
  savedOfferRef.current = savedOffer
  const players = useSelector(s => s.game.metadata?.players) || {}
  const oldTrade = useRef<GalacticTrader | undefined>(trade)
  const [tradeStatus, setTradeStatus] = useState<tradeStatus>(() => {
    if (!savedOffer) {
      return 'empty'
    }

    if (savedOffer.committed) {
      return 'done'
    }

    return 'saved'
  })

  const {
    onCommitTrade,
  } = usePlayerActions()

  const [tradeOffer, setTradeOffer] = useState<GalacticTraderOffer>(() => (savedOffer ?? {
    Ag: { quantity: 0, price: trade?.Ag.price || 0 },
    Au: { quantity: 0, price: trade?.Au.price || 0 },
    Cu: { quantity: 0, price: trade?.Cu.price || 0 },
    Dia: { quantity: 0, price: trade?.Dia.price || 0 },
    Fe: { quantity: 0, price: trade?.Fe.price || 0 },
    Oil: { quantity: 0, price: trade?.Oil.price || 0 },
    Pt: { quantity: 0, price: trade?.Pt.price || 0 },
    Ti: { quantity: 0, price: trade?.Ti.price || 0 },
    U: { quantity: 0, price: trade?.U.price || 0 },
    Wa: { quantity: 0, price: trade?.Wa.price || 0 },
  }))

  const onQuantityChange = useCallback((e) => {
    const resource = e.target.name as keyof Resource
    const value = e.target.value
    setTradeOffer(p => update(p, {
      [resource]: { quantity: { $set: round(Number(value)) } },
    }))
    setTradeStatus('editing')
    setDirty(true)
  }, [])

  const onPriceChange = useCallback((e) => {
    const resource = e.target.name as keyof Resource
    const value = e.target.value
    setTradeOffer(p => update(p, {
      [resource]: { price: { $set: round(Number(value)) } },
    }))
    setTradeStatus('editing')
    setDirty(true)
  }, [])

  const onSubmitOffer = useCallback((overrideTrade?: GalacticTraderOffer) => {
    const offer = overrideTrade ? overrideTrade : tradeOffer
    setDirty(offer.committed === false)
    return onCommitTrade(offer)
  }, [tradeOffer, onCommitTrade])

  const onResetTrade = useCallback(() => {
    if (!trade) return
    setTradeOffer({
      Ag: { quantity: 0, price: trade?.Ag.price || 0 },
      Au: { quantity: 0, price: trade?.Au.price || 0 },
      Cu: { quantity: 0, price: trade?.Cu.price || 0 },
      Dia: { quantity: 0, price: trade?.Dia.price || 0 },
      Fe: { quantity: 0, price: trade?.Fe.price || 0 },
      Oil: { quantity: 0, price: trade?.Oil.price || 0 },
      Pt: { quantity: 0, price: trade?.Pt.price || 0 },
      Ti: { quantity: 0, price: trade?.Ti.price || 0 },
      U: { quantity: 0, price: trade?.U.price || 0 },
      Wa: { quantity: 0, price: trade?.Wa.price || 0 },
      committed: false,
    })
    setTradeStatus('editing')
  }, [trade])

  const { usage_forecast } = useMemo(() => (
    getResourceUsageForecast(planets, myResources, cash)
  ), [planets, myResources, cash])

  useEffect(() => {
    if (isEqual(trade, oldTrade.current) || !trade) {
      oldTrade.current = trade
      return
    }
    oldTrade.current = trade

    setTradeOffer(tradeOffer => {
      return update(tradeOffer, {
        Ag: { $set: { quantity: 0, price: trade.Ag.price } },
        Au: { $set: { quantity: 0, price: trade.Au.price } },
        Cu: { $set: { quantity: 0, price: trade.Cu.price } },
        Dia: { $set: { quantity: 0, price: trade.Dia.price } },
        Fe: { $set: { quantity: 0, price: trade.Fe.price } },
        Oil: { $set: { quantity: 0, price: trade.Oil.price } },
        Pt: { $set: { quantity: 0, price: trade.Pt.price } },
        Ti: { $set: { quantity: 0, price: trade.Ti.price } },
        U: { $set: { quantity: 0, price: trade.U.price } },
        Wa: { $set: { quantity: 0, price: trade.Wa.price } },
      })
    })
  }, [trade])

  const errors = useMemo(() => {
    const errors: string[] = []
    resources.forEach(r => {
      if (!trade || !myResources) {
        return
      }
      if (trade[r].option === 'sell') {
        if (tradeOffer[r].price > trade[r].price) {
          errors.push(`You must bid less than the price of the sale for <span class="${r}">${translateResource(r)}</span>`)
        }
        if (tradeOffer[r].price < 0) {
          errors.push(`You cannot bid a negative amount for <span class="${r}">${translateResource(r)}</span>`)
        }
        if (tradeOffer[r].quantity - (savedOffer?.[r]?.quantity ?? 0) > myResources[r]) {
          errors.push(`You only have <span class="${r}">${(myResources[r] + (savedOffer?.[r]?.quantity ?? 0)).toString()} ${translateResource(r)}</span> available to sell..`)
        }
      }
      if (trade[r].option === 'buy') {
        if (tradeOffer[r].price < trade[r].price) {
          errors.push(`You must bid more than the price of the sale for <span class="${r}">${translateResource(r)}</span>`)
        }
      }
      if (tradeOffer[r].quantity > trade[r].quantity) {
        errors.push(`You cannot bid for more <span class="${r}">${translateResource(r)}</span> than is available to bid for.`)
      }
    })
    const totalPrice = sumBy(resources, (r) => {
      if (!trade || !tradeOffer || !savedOffer || trade[r].option === 'sell') {
        return 0
      }

      return (tradeOffer[r].quantity - savedOffer[r].quantity) * tradeOffer[r].price
    })
    if (totalPrice !== 0 && totalPrice > cash) {
      errors.push(`You cannot afford this trade. You need an additional <span class="U">${truncateCash(Math.abs(cash - totalPrice))}</span>.`)
    }
    return errors
  }, [
    trade,
    myResources,
    savedOffer,
    tradeOffer,
    cash,
  ])

  useEffect(() => {
    if (tradeStatus !== 'editing' || errors.length) {
      return
    }
    const timeout = setTimeout(() => {
      setTradeStatus('syncing')
      onSubmitOffer({ ...tradeOffer, committed: false })
        .then(() => setTradeStatus('saved'))
        .catch(() => setTradeStatus('error'))
    }, 1000)
    return () => clearTimeout(timeout)
  }, [onSubmitOffer, tradeOffer, tradeStatus, errors])

  if (!trade || !myResources) {
    return null
  }

  function onFinalSubmit() {
    onSubmitOffer({ ...tradeOffer, committed: true })
  }

  let buttonText
  let buttonColor: SemanticCOLORS
  if (savedOffer?.committed) {
    buttonText = 'Waiting for others...'
    buttonColor = 'teal'
  } else if (errors.length) {
    buttonText = 'Review Errors'
    buttonColor = 'red'
  } else if (savedOffer?.committed === false && dirty) {
    buttonText = 'Commit Prices'
    buttonColor = 'green'
  } else {
    buttonText = 'Skip Offer'
    buttonColor = 'yellow'
  }

  const bill: any[] = []
  let totalCost = 0
  let totalEarnings = 0
  for (let resource of resources) {
    const offer = tradeOffer[resource]
    if (offer.quantity) {
      if (trade[resource].option === 'buy') {
        totalCost += offer.quantity * offer.price
      } else {
        totalEarnings += offer.quantity * offer.price
      }
      bill.push((
        <Table.Row key={resource}>
          <Table.Cell colSpan="5" textAlign="right">{trade[resource].option === 'buy' ? 'Buy ' : 'Sell '}{translateResource(resource)}</Table.Cell>
          <Table.Cell textAlign="right">{offer.quantity}</Table.Cell>
          <Table.Cell colSpan="2" textAlign="right">at {truncateCash(offer.price)}</Table.Cell>
          <Table.Cell colSpan="3" textAlign="right">{trade[resource].option === 'buy' ? 'Cost' : 'Earn'}: {truncateCash(offer.quantity * offer.price)}</Table.Cell>
        </Table.Row>
      ))
    }
  }

  const statuses = Object.values(players).sort((a, b) => a.name.localeCompare(b.name)).map((p, i, a) => (
    <Fragment key={p.id}>
      <span style={{ color: p.trade_ready ? 'green' : 'orange' }}>{p.name} - {p.trade_ready ? 'Ready!' : 'Trading...'}</span>
      {(a.length - 1 !== i) && ' | '}
    </Fragment>
  ))

  return (
    <Card style={{ zIndex: 1 }} className={classNames({ is_map })} fluid>
      <Table celled size="small">
        <TableHeader>
          <Table.HeaderCell colSpan={11}>
            A Galactic Trader has appeared!
            &nbsp;
            {statuses}
            <br />
            <br />
            Offer Status:
            &nbsp;
            <Label style={{
              color: tradeStatusColors[tradeStatus][1],
              backgroundColor: tradeStatusColors[tradeStatus][0],
            }}>{tradeStatusText[tradeStatus] + (errors.length ? ', with errors' : '')}</Label>
            &nbsp;
            {tradeStatus === 'editing' && errors.length > 0 && 'There are errors in your offer. Your offer will save when they are resolved.'}
            {tradeStatus === 'error' && !errors.length && (
              <>
                <br />
                Something went wrong, edit your offer to try again.
              </>
            )}
          </Table.HeaderCell>
        </TableHeader>
        <TableHeader>
          <Table.HeaderCell style={{ width: '0.1%' }}></Table.HeaderCell>
          {resources.map(r => {
            const buying = trade[r].option === 'buy'
            return (
              <Table.HeaderCell key={r} textAlign="center" className={r}>
                <span style={{
                  fontSize: '12px',
                  color: 'white',
                  backgroundColor: !buying ? 'green' : 'coral',
                  padding: '0 2px',
                  borderRadius: '2px',
                }}>{buying ? 'BUY' : 'SELL'}</span>
                <br />
                {r}
              </Table.HeaderCell>
            )
          })}
        </TableHeader>
        <Table.Body>
          <Table.Row>
            <Table.Cell style={{ whiteSpace: 'nowrap' }}>Quantity</Table.Cell>
            {resources.map(r => (
              <Table.Cell key={r} textAlign="center">{truncateCash(trade[r].quantity, { raw: true })}</Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell style={{ whiteSpace: 'nowrap' }}>Avg Price</Table.Cell>
            {resources.map(r => (
              <Table.Cell key={r} textAlign="center" style={{ fontStyle: 'italic', color: 'rgba(0,0,0,0.6)' }}>{resourceCosts[r] ? truncateCash(resourceCosts[r].cash || 0) : ''}</Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell style={{ whiteSpace: 'nowrap' }}>Cost</Table.Cell>
            {resources.map(r => (
              <Table.Cell key={r} textAlign="center" style={{ color: 'red' }}>{trade[r].option === 'buy' && truncateCash(trade[r].price || 0)}</Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell style={{ whiteSpace: 'nowrap' }}>Profit</Table.Cell>
            {resources.map(r => (
              <Table.Cell key={r} textAlign="center" style={{ color: 'green' }}>{trade[r].option === 'sell' && truncateCash(trade[r].price || 0)}</Table.Cell>
            ))}
          </Table.Row>
        </Table.Body>
        <TableHeader>
          <Table.HeaderCell colSpan={11}>
            <span className="U">Place your bids below.</span>
            {!savedOffer?.committed && errors.map(e => {
              return (
                <>
                  <br />
                  <span dangerouslySetInnerHTML={{ __html: e }} />
                </>
              )
            })}
            <PromiseButton onClick={() => onFinalSubmit()} disabled={!!errors.length || savedOffer?.committed || !['empty', 'saved'].includes(tradeStatus)} floated="right" size="mini" color={buttonColor}>{buttonText}</PromiseButton>
            <PromiseButton onClick={onResetTrade} disabled={savedOffer?.committed || !['empty', 'saved', 'error', 'editing'].includes(tradeStatus)} floated="right" size="mini" color="grey">Reset Trade</PromiseButton>
          </Table.HeaderCell>
        </TableHeader>
        <Table.Body>
          <Table.Row>
            <Table.Cell />
            {resources.map(r => (
              <Table.Cell key={r} textAlign="center" className={r} style={{ fontWeight: 'bold', padding: '2px 0' }}>
                {r}
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell># Held (Deficit/Surplus)</Table.Cell>
            {resources.map(r => {
              const amtHeld = myResources[r] + (savedOffer?.[r].quantity ?? 0)
              const forecast = amtHeld - usage_forecast[r]
              return (
                <Table.Cell key={r} textAlign='center'>
                  <span style={{ fontWeight: 900 }}>{truncateCash(amtHeld, { raw: true })}</span>
                  <br />
                  <span style={{ color: forecast < 0 ? 'red' : 'green' }}>
                    ({truncateCash(forecast, { raw: true })})
                  </span>
                </Table.Cell>
              )
            })}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Bid Quantity</Table.Cell>
            {resources.map(r => {
              const amtHeld = myResources[r] + (savedOffer?.[r].quantity ?? 0)
              const disabled = amtHeld === 0 && trade[r].option === 'sell'
              return (
                <Table.Cell key={r}>
                  <div style={{ opacity: disabled ? 0.3 : 1 }}>
                    <Input size="small" disabled={savedOffer?.committed || disabled} value={tradeOffer[r].quantity} fluid onChange={onQuantityChange} name={r} />
                  </div>
                </Table.Cell>
              )
            })}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Bid Price</Table.Cell>
            {resources.map(r => {
              const amtHeld = myResources[r] + (savedOffer?.[r].quantity ?? 0)
              const disabled = amtHeld === 0 && trade[r].option === 'sell'
              return (
                <Table.Cell key={r}>
                  <div style={{ opacity: disabled ? 0.3 : 1 }}>
                    <Input size="small" disabled={savedOffer?.committed} value={tradeOffer[r].price} fluid onChange={onPriceChange} name={r} />
                  </div>
                </Table.Cell>
              )
            })}
          </Table.Row>
          {bill.length ? (
            <>
              {bill}
              {totalCost ? (
                <Table.Row>
                  <Table.Cell colSpan="11" textAlign="right">Total Cost: {truncateCash(totalCost)}</Table.Cell>
                </Table.Row>
              ) : null}
              {totalEarnings ? (
                <Table.Row>
                  <Table.Cell colSpan="11" textAlign="right">Total Earnings: {truncateCash(totalEarnings)}</Table.Cell>
                </Table.Row>
              ) : null}
              {(totalCost && totalEarnings) ? (
                <Table.Row>
                  <Table.Cell colSpan="11" textAlign="right">Net Earnings: {truncateCash(totalEarnings - totalCost)}</Table.Cell>
                </Table.Row>
              ) : null}
            </>
          ) : null}
        </Table.Body>
      </Table>
    </Card>
  )
}
