import React, { useState } from 'react'
import { Card, Statistic, Popup, Header, List, Menu, Icon, Button } from 'semantic-ui-react'
import { useSelector } from '../../../../hooks/useSelector'
import { Splitter } from '../../../../components/Splitter/Splitter'
// @ts-ignore
import formatCost from '../../../../utils/formatCost'
import usePlayerActions from '../../hooks/usePlayerActions'
import PromiseMenuItem from '../../../../components/PromiseMenuItem'
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic'
import CashFader from './CashFader'
import { ConvertResourceModal } from '../ResourceButton/ConvertResourceModal'
import { truncateCash } from '../../../../utils/truncateCash'
import { createShipUpgradeLevelsFront } from '../PlayerCashInvestments/calculate_ship_upgrade_levels'
import { calcQuest } from '../PlayerCashInvestments/calculateQuestBonus'
import classNames from 'classnames'

export default () => {
  const population = useSelector(s => s.game.sheet?.population)
  const income = useSelector(s => s.game.sheet?.income) || 0
  const ship_growth = useSelector(s => s.game.options?.ship_growth) || []
  const _ship_upgrades = useSelector(s => s.game.options?.ship_upgrades) || []
  const is_map = useSelector(s => s.game.galaxy_display)
  const cash = useSelector(s => s.game.sheet?.cash) || 0
  const quest_bonus_pop_growth = useSelector(s => s.game.sheet?.quests.rewards.pop_growth_percentage)

  let quest_bonus = 0
  if (quest_bonus_pop_growth && population) {
    quest_bonus = Math.round(calcQuest(quest_bonus_pop_growth) * population.count)
  }

  const {
    onModifyCashAccounts,
  } = usePlayerActions()
  const [buy, setBuy] = useState(false)

  const ship_upgrades = createShipUpgradeLevelsFront(_ship_upgrades).slice(Math.max((population?.level || 0), 0), (population?.level || 0) + 6)

  const max_population = ship_upgrades[0]?.max || 35

  const count = population?.count || 0

  const {
    onUpgrade,
  } = usePlayerActions()

  let incomeStatus: SemanticCOLORS
  if (income < 0) {
    incomeStatus = 'red'
  } else if (income === 0) {
    incomeStatus = 'black'
  } else {
    incomeStatus = 'green'
  }

  let cashStatus: SemanticCOLORS
  if (cash < 0) {
    cashStatus = 'red'
  } else if (cash === 0) {
    cashStatus = 'grey'
  } else {
    cashStatus = 'green'
  }

  return (
    <Card className={classNames('pr-cash', { is_map })} fluid style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, zIndex: 1 }}>
      <ConvertResourceModal open={buy} setOpen={setBuy} label="population" />
      <Card.Content>
        <Card.Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Ship
            <div style={{ marginLeft: '16px' }}>
              <Button style={{ margin: '3px 1px', padding: '8px 8px' }} ignoreGameLogic onClick={() => onModifyCashAccounts('balance_cash')} color={cashStatus} size="massive">
                Cash:&nbsp;&nbsp;
                {truncateCash(cash, {}, true)}
              </Button>
            </div>
            <CashFader cash={cash} />
          </div>
          <Popup wide="very" position="top center" basic trigger={(
            <Icon color="grey" name="info circle" />
          )}>
            Clicking your <b>cash</b> will automatically deposit/withdraw all your cash to/from all your accounts and planets.
            <br />
            <br />
            Withdrawing and then depositing all your cash is a quick way to optimize your accounts and credit rating
            if you run out of time at the end of your turn.
            <hr />
            Your <b>Income</b> is determined by the amount your Line of Credit (LOC) is withdrawn, the amount of Bonds you own, and your ship & planet population (1$ / person / year).
            <hr />
            Your <b>Population</b> grows every year (turn). The amount it grows depends on how many people you have on board.
            <br />
            <br />
            If you hover the current population, you can see information for how much the ship will grow per year based on your ship population.
            <br />
            <br />
            Additionally, you can <b>purchase</b> more ship population here incase you are in need of purchasing a shiny new planet! The price of hiring will change as the economy grows in the game.
            <br />
            <br />
            You can also <b>convert water</b> into population. <b>1 water</b> will convert into <b>3 population</b>. This makes finding planets with water mines a good way to gain an advantage at the start of the game.
            <hr />
            Your <b>Max</b> population can be upgraded by hovering the tile and selecting your next available upgrade from the list, assuming you have the appropriate funds.
          </Popup>
        </Card.Header>
      </Card.Content>
      <Splitter style={{ paddingBottom: '12px' }}>
        <Statistic color={incomeStatus} size="tiny">
          <Statistic.Value>
            {truncateCash(income, {}, true)}
          </Statistic.Value>
          <Statistic.Label>Income</Statistic.Label>
        </Statistic>
        <Popup
          position="bottom left"
          hoverable
          basic
          trigger={(
            <Statistic className="pr-upgrade" color={max_population === population?.count ? 'red' : 'black'} size="tiny" style={{ marginBottom: 0 }}>
              <Statistic.Value>
                { truncateCash(count, { raw: true })}
              </Statistic.Value>
              <Statistic.Label>Population</Statistic.Label>
            </Statistic>
          )}
        >
          <Header>Ship Growth</Header>
          <Button onClick={() => setBuy(true)} fluid color="green">Hire Population</Button>
          <br />You can also convert 1 water into 3 people. Click the resource above. {quest_bonus > 0 ? ' You are receiving an additional ' : ''}
          <span style={{ color:'green', fontWeight:'bold' }}>{quest_bonus > 0 ? ` ${quest_bonus} pop/turn ` : ''}</span>{quest_bonus > 0 ? ' from quest rewards.' : ''}
          <List divided>
            {ship_growth.map((growth) => (
              <List.Item key={growth.count}>
                <List.Icon size="large" verticalAlign="middle" name="add user" />
                <List.Content style={{ paddingLeft: 0 }}>
                  <List.Header>{'<'} {growth.count} Population</List.Header>
                  <List.Description>+ {growth.growth} / year</List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </Popup>
        <Popup
          position="bottom right"
          hoverable
          basic
          trigger={(
            <Statistic className="pr-upgrade" size="tiny" style={{ marginBottom: 0 }}>
              <Statistic.Value>{ truncateCash(max_population, { raw: true })}</Statistic.Value>
              <Statistic.Label>Max</Statistic.Label>
            </Statistic>
          )}
        >
          <Header>Upgrades</Header>
          <Menu vertical style={{ width: '250px', maxHeight: '512px', overflowY: 'auto' }}>
            {ship_upgrades.map((upgrade, i) => {
              if (!population) { return <div key={i} /> }
              const disabled = i > 2
              const isNextUpgrade = i === 1
              const isUpgraded = i < 1
              let onClick
              if (isNextUpgrade) {
                onClick = () => onUpgrade('population_max')
              }
              return (
                <PromiseMenuItem ignoreGameLogic key={upgrade.max} disabled={disabled} onClick={onClick}>
                  {isNextUpgrade && (<Icon size="large" color="green" name="angle double up" />)}
                  <Menu.Header className={`${isUpgraded && 'strike'}`}>Level {population.level + i + 1} | Max: {truncateCash(upgrade.max, { raw: true })}</Menu.Header>
                  <p className={`${isUpgraded && 'strike'}`}>{formatCost(upgrade.cost)}</p>
                </PromiseMenuItem>
              )
            })}
          </Menu>
        </Popup>
      </Splitter>
    </Card>
  )
}
