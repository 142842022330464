import React from 'react'
import { useSelector } from '../../../../hooks/useSelector'
import { Segment, Header } from 'semantic-ui-react'
import { truncateCash } from '../../../../utils/truncateCash'
import classNames from 'classnames'

export const WaitingOnPlayers = () => {
  const players_choosing = (useSelector(s => s.game.metadata?.players_choosing_planet) || [])
  const players = useSelector(s => s.game.metadata?.players) || {}
  const turn = useSelector(s => s.game.metadata?.turn) || 0
  const is_map = useSelector(s => s.game.galaxy_display)

  if (!players_choosing.length) {
    return null
  }

  if (turn % 10 === 0 && turn > 19) {
    return (
      <Segment color="blue" className={classNames({ is_map })} inverted style={{ zIndex: 1 }}>
        <Header style={{ fontSize: '24px' }}>Special rift choice in effect!</Header>
        {Object.values(players).sort((a, b) => a.public_equity - b.public_equity).map((player) => {
          if (player.dead) {
            return <div />
          }

          return (
            <div key={player.id} style={{ display: 'flex', width: '60%', justifyContent: 'space-between', paddingBottom: '6px' }}>
              <div style={{ fontSize: '18px' }}>{player.name}</div>
              <div style={{ fontSize: '18px' }}>{truncateCash(player.public_equity, {}, true)}</div>
              <div style={{ fontSize: '18px' }}>
                {(() => {
                  if (players_choosing[0] === player.id) {
                    return <span style={{ color: 'yellow' }}>Choosing...</span>
                  }

                  if (players_choosing.indexOf(player.id) === -1) {
                    return <span style={{ color: 'white' }}>{player.current_planet}</span>
                  }

                  return <span style={{ color: 'black' }}>Waiting...</span>
                })()}
              </div>
            </div>
          )
        })}
      </Segment>
    )
  }

  let message = ''
  if (players_choosing.length && Object.keys(players).length) {
    message += `${players_choosing.map(p => players[p].name).join(', ')}`
  }

  return (
    <Segment color="yellow" className={classNames({ is_map })} inverted style={{ zIndex: 1 }}>
      <Header style={{ color: 'black', fontSize: '24px' }}>Waiting for the following players to choose a planet:</Header>
      <strong style={{ fontSize: '18px', color: 'black' }}>{message}</strong>
    </Segment>
  )
}
