import { round } from 'lodash'
import numeral from 'numeral'

export const truncateCash = (
  currency: number,
  options: { raw?: boolean, removeDigits?: boolean } = {},
  pennies?: boolean,
) => {
  let format = (options && options.raw) ? '' : '$'
  let is_negative = ''
  if (currency < 0) {
    currency *= -1
    is_negative = '-'
  }

  if (currency < 10000 && currency > -10000) {
    format += '0'
  } else if ([7, 10, 13, 16, 19].indexOf(currency.toString().length) !== -1) {
    format += '0.0a'
  } else {
    format += '0a'
  }

  if (pennies) {
    format += '.00'
  }

  if (options && options.removeDigits && options.raw) {
    format = (options && options.raw) ? '' : '$'
    if ([4, 7, 10, 13, 17].indexOf(currency.toString().length) !== -1) {
      format += '0.0a'
    } else {
      format += '0a'
    }
  }

  if (currency > 1000000000) {
    let value = Math.round(currency)
    let new_value = 0
    let more_digits = 0
    let more_digits_text = ''
    let final_string = ''
    const abbreviations = ['k', 'm', 'b', 't', 'q', 'Q', 's', 'S', 'o', 'n', 'd', 'u', 'D', 'T', 'q', 'Q', 's', 'S', 'O', 'N', 'V', '?']
    const x = 3
    for (let i = 1; i < abbreviations.length; i++) {
      const y = x * i
      let total_digits = value.toString().length
      if (value.toString().includes('e')) { // returns an array with the two values meaning it contains an e
        const scientific_notation = value.toString().split('e')
        let prefix_number = parseFloat(scientific_notation[0])
        total_digits = Math.floor(parseFloat(scientific_notation[1]) / 3)
        const suffix = abbreviations[Math.min(total_digits - 1, abbreviations.length - 1)]
        const decimals_off = parseFloat(scientific_notation[1]) - total_digits * 3
        prefix_number *= 10 ** decimals_off
        if (prefix_number >= 10) {
          prefix_number = Math.round(prefix_number)
        } else {
          prefix_number = round(prefix_number, 1)
        }
        final_string = is_negative + prefix_number + suffix
        if (!options?.raw) {
          final_string = is_negative + '$' + final_string
        }
        return final_string
      }
      if (y > total_digits - 1) {
        new_value = parseFloat(value.toString().substring(0, value.toString().length - y + 3))
        if (new_value.toString().length < 2) { // if we rounded a lot, make it a little bigger
          more_digits = parseFloat(value.toString().substring(1, 2))
          more_digits_text += '.'
          more_digits_text += more_digits.toString()
        }
        final_string = is_negative + `${new_value}${more_digits_text}${abbreviations[i - 2]}`
        if (!options?.raw) {
          final_string = is_negative + '$' + final_string
        }
        return final_string
      }
    }
    return final_string
  }

  return is_negative + numeral(currency).format(format)
}
