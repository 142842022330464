import { cloneDeep } from 'lodash'
import { Sheet, GameOptions, UpgradeCost, GameMetadata } from '../../types'
import { createBondUpgradeLevels } from '../views/SheetView/components/PlayerCashInvestments/calculate_bond_upgrade_levels'
import { createLocUpgradeLevels } from '../views/SheetView/components/PlayerCashInvestments/calculate_loc_upgrade_levels'
import { createShipUpgradeLevelsFront } from '../views/SheetView/components/PlayerCashInvestments/calculate_ship_upgrade_levels'

interface BalanceSheet {
  resources: number
  mines: number
  defences: number
  planetCashCosts: number
  planetResources: number
  upgrades: number
  human_capital: number
  debt: number
  starbase_loans: number
  planet_loans: number
  de: number
  roa: number
  roe: number
  totalAssets: number
  rating: string
  color: string
}

const ratesDEquity: ([string, number, string][]) = [
  ['AAA+', 0.15, '#00ff00'],
  ['AAA', 0.20, '#00ff00'],
  ['AA+', 0.25, 'rgb(0,255,0)'],
  ['AA', 0.35, 'rgb(0,210,0)'],
  ['A+', .45, 'rgb(0,159,0)'],
  ['A', .7, 'rgb(0,108,0)'],
  ['BBB+', 0.95, 'rgb(227,227,0)'],
  ['BBB', 1.15, 'rgb(205,205,0)'],
  ['BB+', 1.40, 'rgb(152,152,0)'],
  ['BB', 1.6, 'rgb(255,153,0)'],
  ['B+', 2.2, 'rgb(208,125,0)'],
  ['B', 2.7, 'rgb(174,105,0)'],
  ['CCC+', 4, 'rgb(217, 217, 217)'],
]

const ratesDebt_EBIT: ([string, number, string][]) = [
  ['CCC', 10, 'rgb(255, 0, 0)'],
  ['CC+', 20, 'rgb(205, 0, 0)'], // -.02       -.025
  ['CC', 30, 'rgb(174, 0, 0)'], // -.03         -.04
  ['C+', 50, 'rgb(144, 0, 0)'], // -.04        -.055
  ['C', 70, 'rgb(153, 153, 153)'], // -.05 -.06 -.07
  ['D', 1000000000000000, 'rgb(102, 102, 102)'],
]

export const findCreditRatingColor = (rating: string) => {
  let color = ratesDebt_EBIT.find(roa => roa[0] === rating)
  if (!color) {
    color = ratesDEquity.find(rde => rde[0] === rating)
    if (!color) return 'red'
  }
  return color[2]
}

/**
 * IF YOU EDIT THIS FILE, UPDATE THE BACK-END ONE TOO!!!!!
 */
export const calculateBalanceSheet = (sheet: Sheet, options: GameOptions, metadata: GameMetadata) => {
  const balance_sheet: BalanceSheet = {
    resources: 0,
    mines: 0,
    defences: 0,
    planetCashCosts: 0,
    planetResources: 0,
    upgrades: 0,
    debt: 0,
    starbase_loans: 0,
    planet_loans: 0,
    human_capital: 0,
    de: 0,
    roa: 0,
    roe: 0,
    totalAssets: 0,
    rating: '',
    color: '',
  }

  const ratesDE_Clone: ([string, number, string][]) = cloneDeep(ratesDEquity)

  ratesDE_Clone.forEach((x) => {
    if (options.difficulty_credit_rating_impact < 1) {
      x[1] = Math.max((x[1] - (1 - options.difficulty_credit_rating_impact)), x[1] - .14)
    }
    x[1] *= options.difficulty_credit_rating_impact
  })

  const ratesDebtEBIT_Clone: ([string, number, string][]) = cloneDeep(ratesDebt_EBIT)

  ratesDebtEBIT_Clone.forEach((x) => {
    x[1] *= options.difficulty_credit_rating_impact
  })

  balance_sheet.debt += sheet.loc.cash

  for (const [resource, quantity] of Object.entries(sheet.resources)) {
    balance_sheet.resources += (metadata.resource_costs[resource].cash || 0) * quantity
  }

  const addCostToUpgrade = (cost: UpgradeCost) => {
    if (cost.resources) {
      for (const [resource, quantity] of Object.entries(cost.resources)) {
        balance_sheet.upgrades += (metadata.resource_costs[resource].cash || 0) * quantity
      }
    }
    if (cost.cash) {
      balance_sheet.upgrades += cost.cash
    }
  }

  balance_sheet.upgrades += sheet.converted_resources_total_equity

  // UPGRADE DATA
  for (let i = 0; i <= sheet.loc.max_level; i++) {
    addCostToUpgrade(createLocUpgradeLevels(options.loc_max_upgrades)[i].cost)
  }
  for (let i = 0; i <= sheet.loc.rate_level; i++) {
    addCostToUpgrade(options.loc_rate_upgrades[i].cost)
  }
  for (let i = 0; i <= sheet.bonds.max_level; i++) {
    addCostToUpgrade(options.bond_max_upgrades[i].cost)
  }
  for (let i = 0; i <= sheet.bonds.rate_level; i++) {
    addCostToUpgrade(createBondUpgradeLevels(options.bond_rate_upgrades)[i].cost)
  }
  for (let i = 0; i <= sheet.population.level; i++) {
    addCostToUpgrade(createShipUpgradeLevelsFront(options.ship_upgrades)[i].cost)
  }

  for (const loan of sheet.starbase_loans) {
    balance_sheet.starbase_loans += loan.amount
  }
  balance_sheet.debt += balance_sheet.starbase_loans

  // PLANET DATA
  for (const [planet_key] of Object.entries(sheet.planets)) {
    const planet = sheet.planets[planet_key]

    // Loan
    if (planet.loan !== -1) {
      balance_sheet.planet_loans += planet.deed.levels[planet.loan].loan
    }

    // Planet Level Values
    for (let i = 0; i <= planet.level; i++) {
      const cost = planet.deed.levels[i].cost
      if (cost.resources) {
        for (const [resource, quantity] of Object.entries(cost.resources)) {
          balance_sheet.planetResources += (metadata.resource_costs[resource].cash || 0) * quantity
        }
      }
      if (cost.cash) {
        balance_sheet.planetCashCosts += cost.cash
      }
    }

    // Mines
    for (const m of planet.mines) {
      const mine = Object.keys(m)[0]
      if (m[mine]) {
        balance_sheet.mines += ((metadata.mine_costs[mine].cash || 0) * m[mine])
      }
    }

    // Defenses
    let count = 0

    planet.deed.disasterBarriers.forEach((barrier) => {
      balance_sheet.defences += planet.deed.disasterBarrierCountCurrent[count] * (metadata.disaster_defence_costs[barrier].cash || 0)
      count += 1
    })

    // Population invested in the first level of the planet
    balance_sheet.human_capital += planet.deed.levels[0].pop * metadata.population_cost
  }
  balance_sheet.debt += balance_sheet.planet_loans

  balance_sheet.human_capital += sheet.population.count * metadata.population_cost

  balance_sheet.totalAssets = sheet.bonds.cash +
                              balance_sheet.resources +
                              balance_sheet.mines +
                              balance_sheet.defences +
                              balance_sheet.planetCashCosts +
                              balance_sheet.planetResources +
                              balance_sheet.upgrades +
                              balance_sheet.human_capital

  if (sheet.cash > 0) {
    balance_sheet.totalAssets += sheet.cash
  } else {
    balance_sheet.debt -= sheet.cash
  }

  balance_sheet.roa = sheet.income / balance_sheet.totalAssets
  balance_sheet.roe = sheet.income / (balance_sheet.totalAssets - balance_sheet.debt)

  balance_sheet.de = balance_sheet.debt / (balance_sheet.totalAssets - balance_sheet.debt)

  let balance_sheet_rating_index = 0
  let new_rating_index = 0
  let where_we_got = []
  const MD_CR = metadata.credit_rating_constants
  const debt_ebit = balance_sheet.debt / (sheet.income + sheet.income_statement.interest - sheet.income_statement.bond_revenue)

  // /////////////CODE TO DETERMINE CREDIT RATING:

  // ///SCENARIO 1: Debt to Equity Based Credit Rating:
  for (let i = 0; i < ratesDE_Clone.length; i++) {
    if (balance_sheet.de < 0 || balance_sheet.de > ratesDE_Clone[ratesDE_Clone.length - 1][1]) { // this stops the loop if there is no equity (negative equity) or it passes the max D/E
      break
    }
    where_we_got.push('got here A')
    if (balance_sheet.de < ratesDE_Clone[i][1]) {
      where_we_got.push('got here B')
      balance_sheet.rating = ratesDE_Clone[i][0]
      balance_sheet.color = ratesDE_Clone[i][2]
      balance_sheet_rating_index = i
      if (balance_sheet.roa < 0) { // penalize credit rating if have negative income
        where_we_got.push('got here D')
        new_rating_index = balance_sheet_rating_index + Math.round(balance_sheet.roa * MD_CR.ROA_impact_factor_negative)
        if (new_rating_index > ratesDE_Clone.length - 1) {
          where_we_got.push('got here E')
          const switch_to_ROA_Ratings = Math.min(new_rating_index - ratesDE_Clone.length, ratesDebtEBIT_Clone.length - 1)
          balance_sheet.rating = ratesDebtEBIT_Clone[switch_to_ROA_Ratings][0]
          balance_sheet.color = ratesDebtEBIT_Clone[switch_to_ROA_Ratings][2]
        } else {
          where_we_got.push('got here F')
          balance_sheet.rating = ratesDE_Clone[new_rating_index][0]
          balance_sheet.color = ratesDE_Clone[new_rating_index][2]
        }
      }
      break
    }
  }

  // SCENARIO 2: Debt to EBIT Based Credit Rating
  if (!balance_sheet.rating) {
    for (let i = 0; i < ratesDebtEBIT_Clone.length; i++) {
      if (debt_ebit <= 0 || debt_ebit > ratesDebtEBIT_Clone[ratesDebtEBIT_Clone.length - 1][1]) {
        where_we_got.push('got here I')
        balance_sheet.rating = ratesDebtEBIT_Clone[ratesDebtEBIT_Clone.length - 1][0]
        balance_sheet.color = ratesDebtEBIT_Clone[ratesDebtEBIT_Clone.length - 1][2]
        break
      }
      where_we_got.push('got here G')
      // debt_ebit > ratesDebtEBIT_Clone[ratesDebtEBIT_Clone.length-1][1]
      if (debt_ebit < ratesDebtEBIT_Clone[i][1]) {
        where_we_got.push('got here H')
        balance_sheet.rating = ratesDebtEBIT_Clone[i][0]
        balance_sheet.color = ratesDebtEBIT_Clone[i][2]
        balance_sheet_rating_index = i
        break
      }
    }
  }

  for (let i = 0; i < ratesDE_Clone.length; i++) {
    if (ratesDE_Clone[i][0] === balance_sheet.rating) {
      if (i < MD_CR.starting_rating && balance_sheet.totalAssets < MD_CR.minimum_assets) { // sets balance sheet to the MD_CR.starting_ratingth rating if assets are low
        balance_sheet.rating = ratesDE_Clone[MD_CR.starting_rating][0]
        balance_sheet.color = ratesDE_Clone[MD_CR.starting_rating][2]
      }
    }
  }

  if (balance_sheet.totalAssets > MD_CR.minimum_assets) { // if the player has some equity (which the player most certainly will if they have income)
    if (balance_sheet.roa > 0) { // if player has any income
      for (let i = 0; i < ratesDE_Clone.length; i++) {
        let shifted_index = 0
        if (ratesDE_Clone[i][0] === balance_sheet.rating) {
          shifted_index = Math.max(Math.round(i + balance_sheet.roa * MD_CR.ROA_impact_factor_positive), 0)
          balance_sheet.rating = ratesDE_Clone[shifted_index][0]
          balance_sheet.color = ratesDE_Clone[shifted_index][2]
        }
      }
    }
  }

  if (!balance_sheet.rating) {
    throw new Error(`(front end) Hmm, something didnt quite work out... DE: ${balance_sheet.de}, ROA: ${balance_sheet.roa} balance_sheet_rating_index: 
    ${balance_sheet_rating_index} \n
    ${new_rating_index} \n
    ${where_we_got};.`)
  }

  return balance_sheet
}
