import React from 'react'
import { Menu, Popup } from 'semantic-ui-react'
import './CreditRating.scss'
import { useSelector } from '../../../../hooks/useSelector'
import { calculateBalanceSheet } from '../../../../utils/calculateBalanceSheet'
import { truncateCash } from '../../../../utils/truncateCash'

export const CreditRating = () => {
  const sheet = useSelector(s => s.game.sheet)
  const options = useSelector(s => s.game.options)
  const metadata = useSelector(s => s.game.metadata)

  if (!sheet || !options || !metadata) {
    return null
  }
  const rating = calculateBalanceSheet(sheet, options, metadata)
  const long_term_revenues = sheet.income_statement.ship_revenue + sheet.income_statement.planet_revenue + sheet.income_statement.gold_revenue
  const gross_revenues = long_term_revenues + sheet.income_statement.bond_revenue
  let operating_revenue = sheet.income
  // Warning - Operating revenue will get very small when negative interest is removed back out
  operating_revenue += sheet.income_statement.interest // EBIT - remove the interest expense (was subtracted already from income)
  operating_revenue -= sheet.income_statement.bond_revenue
  const debt_ebit = rating.debt / operating_revenue

  return (
    <Popup position="bottom right" trigger={(
      <Menu.Item fitted>
        <div className="cr-wrapper">
          <div className="rating" style={{ backgroundColor: rating.color }}>
            {rating.rating}
          </div>
        </div>
      </Menu.Item>
    )}>
      <div className="cr-breakdown">
        <div className="cr-header">
          <div className="cr-content" style={{ alignItems: 'center' }}>
            <div className="columns" style={{ justifyContent:'center', alignItems:'center' }}>Balance Sheet</div>
            <div className="columns">Income Breakdown</div>
          </div>
        </div>
        <div className="cr-content">
          <div className="columns">Credit Rating</div>
          <div className="columns" style={{ backgroundColor: rating.color }}>{rating.rating}</div>
          <div className="columns" style={{ justifyContent:'flex-start' }}>Revenue</div>
          <div className="columns">{truncateCash(sheet.income_statement.revenue, { raw: false }, true)}</div>
        </div>
        <div className="cr-content">
          <div className="columns">Debt to Equity</div>
          <div className="columns">{(rating.de * 100).toFixed(2)}%</div>
          <div className="columns" style={{ justifyContent:'flex-start' }}>Expenses</div>
          <div className="columns">{truncateCash(sheet.income_statement.expenses, { raw: false }, true)}</div>
        </div>
        <div className="cr-content">
          <div className="columns">Total Equity</div>
          <div className="columns">{truncateCash(rating.totalAssets - rating.debt, { raw: false }, true)}</div>
          <div className="columns" style={{ justifyContent:'flex-start' }}>Income</div>
          <div className="columns">{truncateCash(sheet.income, { raw: false }, true)}</div>
        </div>
        <div className="cr-content">
          <div className="columns">Tangible Equity</div>
          <div className="columns">{truncateCash(rating.totalAssets - rating.debt - rating.human_capital, { raw: false }, true)}</div>
          <div className="columns" style={{ justifyContent:'flex-start' }}>Profit Margin</div>
          <div className="columns">{(100 * sheet.income / sheet.income_statement.revenue).toFixed(2)}%</div>
        </div>
        <div className="cr-content">
          <div className="columns">Debt to EBIT</div>
          <div className="columns">{(debt_ebit).toFixed(2)}</div>
          <div className="columns" style={{ justifyContent:'flex-start' }}>Return on Equity</div>
          <div className="columns">{(rating.roe * 100).toFixed(2)}%</div>
        </div>
        <div className="cr-header">
          <div className="cr-content">
            <div className="columns" style={{ justifyContent:'center', alignItems:'center' }}>Assets</div>
            <div className="columns" style={{ justifyContent:'center', alignItems:'center' }}>Revenues</div>
          </div>
        </div>
        <div className="left">
          <div className="cr-content">
            <div className="columns bold">
              Total Assets
            </div>
            <div className="columns">
              {truncateCash(rating.totalAssets, { raw: false }, true)}
            </div>
            <div className="columns bold" style={{ alignItems:'left' }}>
              Gross Revenues
            </div>
            <div className="columns">
              {truncateCash(gross_revenues, { raw: false }, true)}
            </div>
          </div>
          <div className="cr-content">
            <div className="columns bold">
              Liquid Assets
            </div>
            <div className="columns">
              {truncateCash(sheet.cash + sheet.bonds.cash, { raw: false }, true)}
            </div>
            <div className="columns bold">
              Bond Revenue
            </div>
            <div className="columns">
              {truncateCash(sheet.income_statement.bond_revenue, { raw: false }, true)}
            </div>
          </div>
          <div className="cr-content">
            <div className="columns bold">
              Long Term Assets
            </div>
            <div className="columns">
              {truncateCash(rating.totalAssets - sheet.cash - sheet.bonds.cash, { raw: false }, true)}
            </div>
            <div className="columns bold">
              Long Term Revenues
            </div>
            <div className="columns">
              {truncateCash(long_term_revenues, { raw: false }, true)}
            </div>
          </div>
          <div className="cr-content">
            <div className="columns">
              <span className="indented">
                Resources
              </span>
            </div>
            <div className="columns">
              {truncateCash(rating.resources, { raw: true }, true)}
            </div>
            <div className="columns">
              <span className="indented">
                Planets
              </span>
            </div>
            <div className="columns">
              {truncateCash(sheet.income_statement.planet_revenue, { raw: false }, true)}
            </div>
          </div>
          <div className="cr-content">
            <div className="columns">
              <span className="indented">
                Mines
              </span>
            </div>
            <div className="columns">
              {truncateCash(rating.mines, { raw: false }, true)}
            </div>
            <div className="columns">
              <span className="indented">
                Ship
              </span>
            </div>
            <div className="columns">
              {truncateCash(sheet.income_statement.ship_revenue, { raw: false }, true)}
            </div>
          </div>
          <div className="cr-content">
            <div className="columns">
              <span className="indented">
                Planets &amp; Res.
              </span>
            </div>
            <div className="columns">
              {truncateCash(rating.planetCashCosts + rating.planetResources, { raw: false }, true)}
            </div>
            <div className="columns">
              <span className="indented">
                Gold
              </span>
            </div>
            <div className="columns">
              {truncateCash(sheet.income_statement.gold_revenue, { raw: false }, true)}
            </div>
          </div>
          <div className="cr-content">
            <div className="columns">
              <span className="indented">
                Upgrades
              </span>
            </div>
            <div className="columns">
              {truncateCash(rating.upgrades, { raw: false }, true)}
            </div>
            <div className="columns">
              Recessionary Losses
            </div>
            <div className="columns">
              {truncateCash((long_term_revenues) * (sheet.recession_final_revenue_modifier - 1), { raw: false }, true)}
            </div>
          </div>
          <div className="cr-content">
            <div className="columns">
              <span className="indented">
                Human Capital
              </span>
            </div>
            <div className="columns">
              {truncateCash(rating.human_capital, { raw: false }, true)}
            </div>
            <div className="columns">
              Net Revenues
            </div>
            <div className="columns">
              {truncateCash(long_term_revenues * sheet.recession_final_revenue_modifier + sheet.income_statement.bond_revenue, { raw: false }, true)}
            </div>
          </div>
          <div className="cr-header">
            <div className="cr-content">
              <div className="columns" style={{ justifyContent:'center', alignItems:'center' }}>Liabilities</div>
              <div className="columns" style={{ justifyContent:'center', alignItems:'center' }}>Expenses</div>
            </div>
          </div>
          <div className="cr-content">
            <div className="columns bold">
              Total Liabilities
            </div>
            <div className="columns">
              {truncateCash(rating.debt, { raw: false }, true)}
            </div>
            <div className="columns bold">
              Total Expenses
            </div>
            <div className="columns">
              {truncateCash(sheet.income_statement.expenses, { raw: false }, true)}
            </div>
          </div>
          <div className="cr-content">
            <div className="columns">
              <span className="indented">
                Planet Loans
              </span>
            </div>
            <div className="columns">
              {truncateCash(rating.planet_loans, { raw: false }, true)}
            </div>
            <div className="columns">
              <span className="indented">
                Interest
              </span>
            </div>
            <div className="columns">
              {truncateCash(sheet.income_statement.interest, { raw: false }, true)}
            </div>
          </div>
          <div className="cr-content">
            <div className="columns">
              <span className="indented">
                LOC
              </span>
            </div>
            <div className="columns">
              {truncateCash(sheet.loc.cash)}
            </div>
            <div className="columns">
              <span className="indented">
                Mines
              </span>
            </div>
            <div className="columns">
              {truncateCash(sheet.income_statement.mines, { raw: false }, true)}
            </div>
          </div>
          <div className="cr-content">
            <div className="columns">
              <span className="indented">
                Starbase Loans
              </span>
            </div>
            <div className="columns">
              {truncateCash(rating.starbase_loans, { raw: false }, true)}
            </div>
            <div className="columns">
              <span className="indented">
                Upgrades
              </span>
            </div>
            <div className="columns">
              {truncateCash(sheet.income_statement.upgrades, { raw: false }, true)}
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}
