import { cloneDeep } from 'lodash'
import { LOCMaxUpgrade } from '../../../../../types'
import { formatZeros } from './formatZeros'

export const createLocUpgradeLevels = (_locUpgrade: LOCMaxUpgrade[]) => {
  const LMU = cloneDeep(_locUpgrade)
  for (let i = 0; i < 100; i++) {
    if (LMU.length < 100) {
      LMU.push({
        max:  LMU[LMU.length - 1].max * 1.5,
        cost: {
          equity: (LMU[LMU.length - 1].cost.equity || 0) * 1.5,
          resources: {
            Cu: (LMU[LMU.length - 1].cost.resources?.Cu || 0) * 1.225,
            Ag: (LMU[LMU.length - 1].cost.resources?.Ag || 0) * 1.225,
          },
        },
      })
    }
  }
  for (let i = 0; i < LMU.length; i++) {
    LMU[i].max = formatZeros(LMU[i].max, 2)
    LMU[i].cost.equity = formatZeros(LMU[i].cost.equity || 0, 2)
    const resources = LMU[i].cost.resources
    if (resources) {
      resources.Cu = formatZeros(resources.Cu || 0, 2)
      resources.Ag = formatZeros(resources.Ag || 0, 2)
    }
  }
  return LMU
}
