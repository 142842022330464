import React from 'react'
import { Popup } from 'semantic-ui-react'
import formatCost from '../../../../utils/formatCost'
import { UpgradeCost } from '../../../../../types'

export const MineCostPopup = ({ children, cost }: { children: any, cost: UpgradeCost }) => {
  return (
    <Popup mouseEnterDelay={800} position="right center" trigger={children}>
      Cost: {formatCost(cost)}
    </Popup>
  )
}
