import React, { useState } from 'react'
import { useSelector } from '../../../../hooks/useSelector'
import { Card, Segment } from 'semantic-ui-react'
import { TurnMessage } from '../../../../../types'
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic'
import classNames from 'classnames'

interface PlayerTurnMessageItemProps {
  message: TurnMessage
}

const PlayerTurnMessageItem = ({ message }: PlayerTurnMessageItemProps) => {
  const [visible, setVisible] = useState(true)
  const is_map = useSelector(s => s.game.galaxy_display)

  let color: SemanticCOLORS | undefined = undefined
  switch (message.type) {
  case 'error':
    color = 'red'
    break
  case 'info':
    color = 'blue'
    break
  case 'success':
    color = 'green'
    break
  case 'warn':
    color = 'yellow'
    break
  default: break
  }

  if (!visible) {
    return null
  }
  if (message.urgent) {
    return (
      <Segment color={color} className={classNames({ is_map })} style={{ zIndex: 1 }} inverted onClick={() => setVisible(false)}>
        {message.message}
      </Segment>
    )
  }

  return (
    <Card color={color} className={classNames({ is_map })} style={{ whiteSpace: 'pre-line', zIndex: 1 }} fluid onClick={() => setVisible(false)}>
      <Card.Content>
        <Card.Description style={{ fontSize: '16px', color: message.urgent ? 'white' : 'black' }}>{message.message}</Card.Description>
      </Card.Content>
    </Card>
  )
}

export default () => {
  const turn_messages = useSelector(s => s.game.sheet?.turn_messages) || []
  const shared_messages = useSelector(s => s.game.metadata?.shared_messages) || []

  if (!turn_messages.length && !shared_messages.length) {
    return null
  }

  const messages = [...shared_messages, ...turn_messages]

  return (
    <>
      {messages.map(message => (
        <PlayerTurnMessageItem key={message.message} message={message} />
      ))}
    </>
  )
}
