import React, { ReactNode, useMemo } from 'react'
import { Popup, Header, Icon, Menu } from 'semantic-ui-react'
import usePlayerActions from '../../hooks/usePlayerActions'
import { useSelector } from '../../../../hooks/useSelector'
import PromiseMenuItem from '../../../../components/PromiseMenuItem'
import formatCost from '../../../../utils/formatCost'
import { truncateCash } from '../../../../utils/truncateCash'
import { formatZeros } from './formatZeros'

interface LOCMaxUpgradesPopupProps {
  children: ReactNode
}

export default ({ children }: LOCMaxUpgradesPopupProps) => {
  const _loc_max_upgrades = useSelector(s => s.game.options?.loc_max_upgrades) || []
  const loc = useSelector(s => s.game.sheet?.loc)
  const level = loc?.max_level
  const bonuses = (loc?.max_bonuses || 0)
  const {
    onUpgrade,
  } = usePlayerActions()
  if (typeof level !== 'number') {
    return null
  }

  const loc_max_upgrades = useMemo(() => {
    const LMU = [..._loc_max_upgrades]
    for (let i = 0; i < 100; i++) {
      if (LMU.length < 100) {
        LMU.push({
          max:  LMU[LMU.length - 1].max * 1.5,
          cost: { equity: (LMU[LMU.length - 1].cost.equity || 0) * 1.5, resources: {
            Cu: (LMU[LMU.length - 1].cost.resources?.Cu || 0) * 1.225,
            Ag: (LMU[LMU.length - 1].cost.resources?.Ag || 0) * 1.225 } },
        })
      }
    }
    for (let i = 0; i < LMU.length; i++) {
      LMU[i].max = formatZeros(LMU[i].max, 2)
      LMU[i].cost.equity = formatZeros(LMU[i].cost.equity || 0, 2)
      const resources = LMU[i].cost.resources
      if (resources) {
        resources.Cu = formatZeros(resources.Cu || 0, 2)
        resources.Ag = formatZeros(resources.Ag || 0, 2)
      }
    }
    return LMU.slice(Math.max(level, 0), level + 6)
  }, [_loc_max_upgrades, level])

  return (
    <Popup basic hoverable mouseEnterDelay={400} position="bottom right" trigger={children}>
      <Header>LOC Max Withdrawal Upgrades</Header>
      <Menu vertical style={{ width: '380px', maxHeight: '512px', overflowY: 'auto' }}>
        {loc_max_upgrades.map(({ cost, max }, i) => {
          const disabled = i > 2
          const isNextUpgrade = i === 1
          const isUpgraded = i < 1
          const className = isUpgraded ? 'strike' : ''
          let onClick
          if (isNextUpgrade) {
            onClick = () => onUpgrade('loc_max')
          }
          return (
            <PromiseMenuItem key={max} ignoreGameLogic disabled={disabled} onClick={onClick}>
              {isNextUpgrade && (<Icon name="angle double up" color="green" size="large" />)}
              <Menu.Header className={className}>Level {level + i + 1} | Max Credit: {truncateCash(max + (bonuses || 0))}</Menu.Header>
              <p className={className}>{formatCost(cost)}</p>
            </PromiseMenuItem>
          )
        })}
      </Menu>
    </Popup>
  )
}
