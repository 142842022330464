import React, { useCallback } from 'react'
import { useSelector } from '../../hooks/useSelector'
import { Segment, Header, Button } from 'semantic-ui-react'
import { Message, pullError } from '../../reducers/game/game'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

export default () => {
  const errors = useSelector(s => s.game.errors)
  const dispatch = useDispatch()
  const is_map = useSelector(s => s.game.galaxy_display)

  const onClear = useCallback((error: Message) => {
    dispatch(pullError(error))
  }, [dispatch])

  if (!errors.length) {
    return null
  }

  return (
    <div style={{ marginTop: '14px' }}>
      {errors.reverse().map((error) => {
        return (
          <Segment key={error.message} clearing inverted color="orange" className={classNames({ is_map })} style={{ zIndex: 1 }}>
            <Header>Error</Header>
            <Button color="black" onClick={() => onClear(error)} inverted floated="right">Oops, got it!</Button>
            {error.message}
          </Segment>
        )
      })}
    </div>
  )
}
