import React, { useState, useCallback } from 'react'
import {
  Container, Button, Table, Grid, ButtonGroup } from 'semantic-ui-react'
import { useSelector } from '../../hooks/useSelector'
import useGames from './hooks/useGames'
import CreateGameModal from './modals/CreateGameModal'
import useRouting from '../../hooks/useRouting'
import PromiseButton from '../../components/PromiseButton'
import { useJoinLobby } from '../../hooks/useJoinLobby'

export default () => {
  const authorized = useSelector(s => s.auth.authorized)
  const uid = useSelector(s => s.auth.uid)
  const [modalOpen, setModalOpen] = useState(false)

  const { push } = useRouting()

  const {
    games,
    onCreate,
    onJoin,
  } = useGames()

  const onSubmit = useCallback((name: string) => {
    return onCreate(name)
      .then((r) => {
        push(`/games/${r.data}`)()
        setModalOpen(false)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [onCreate, push])

  useJoinLobby()

  return (
    <>
      <Container>
        <Grid>
          <Grid.Column width={12}>
            <Table selectable striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Games</Table.HeaderCell>
                  <Table.HeaderCell>Players</Table.HeaderCell>
                  <Table.HeaderCell width="4"></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {games.map(game => {
                  const isJoined = !!game.players.find(p => p.id === uid)
                  const isStarted = game.status !== 'new'
                  const isLoading = game.status === 'loading'
                  return (
                    <Table.Row key={game.id}>
                      <Table.Cell>{game.name}</Table.Cell>
                      <Table.Cell>{game.players.length}</Table.Cell>
                      <Table.Cell>
                        {(() => {
                          if (isLoading) {
                            return <PromiseButton color="violet" size="small" floated="right" onClick={push(`/games/resume/${game.id}`)}>Resume</PromiseButton>
                          }
                          if (isJoined) {
                            return <PromiseButton color="yellow" size="small" floated="right" onClick={push(`/games/${game.id}`)}>View</PromiseButton>
                          } else if (isStarted && !isJoined) {
                            return <Button color="vk" disabled size="small" floated="right">In Progress</Button>
                          } else {
                            return <PromiseButton onClick={() => onJoin(game.id).then(r => push(`/games/${r.data}`)())} disabled={!authorized} size="small" floated="right" color="green">Join Game</PromiseButton>
                          }
                        })()}
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column width="4">
            <ButtonGroup vertical fluid>
              <PromiseButton disabled={!authorized} onClick={() => setModalOpen(true)} fluid primary>Create Game</PromiseButton>
              <Button disabled={!authorized} onClick={push('/games/resume')} fluid>Load Game</Button>
            </ButtonGroup>
          </Grid.Column>
        </Grid>
        <CreateGameModal
          onSubmit={onSubmit}
          setOpen={setModalOpen}
          open={modalOpen}
        />
      </Container>
    </>
  )
}
