import React, { useCallback, useState } from 'react'
import { Button, ButtonProps } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import { pushError } from '../reducers/game/game'
import { useSelector } from '../hooks/useSelector'

export default ({ ignoreGameLogic, ...props }: ButtonProps) => {
  const [disabled, setDisabled] = useState(false)
  const playerChoosingPlanet = (useSelector(s => s.game.metadata?.players_choosing_planet) || []).length > 0 && !ignoreGameLogic
  const originalOnClick = props.onClick
  const dispatch = useDispatch()
  const onClick = useCallback(async (e, data) => {
    if (!originalOnClick) {
      return
    }
    setDisabled(true)
    try {
      await originalOnClick(e, data)
    } catch (e) {
      dispatch(pushError({
        type: 'error',
        message: e.message,
      }))
    }
    setDisabled(false)
  }, [originalOnClick, dispatch])
  return <Button {...props} disabled={props.disabled || disabled || playerChoosingPlanet} onClick={onClick} />
}
