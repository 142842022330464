import React, { useState, useEffect } from 'react'
import { Modal, Form, Button } from 'semantic-ui-react'
import { useSelector } from '../../../hooks/useSelector'
import PromiseButton from '../../../components/PromiseButton'

interface CreateGameModalProps {
  open: boolean
  setOpen: (state: boolean) => any
  onSubmit: (name: string) => any
}

export default ({ open, onSubmit, setOpen }: CreateGameModalProps) => {
  const displayName = useSelector(s => s.auth.displayName)
  const [name, setName] = useState<string>(`${displayName || 'Anonymous'}'s Game`)
  useEffect(() => {
    setName(`${displayName || 'Anonymous'}'s Game`)
  }, [displayName])
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Modal.Header>Start New Game</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            width="5"
            size="large"
            label="Game Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => setOpen(false)}>Cancel</Button>
        <PromiseButton onClick={() => onSubmit(name)} primary>Start!</PromiseButton>
      </Modal.Actions>
    </Modal>
  )
}
