import { useState, createContext, useContext, useEffect } from 'react'
import client from 'socket.io-client'
import { useSelector } from './useSelector'
import * as firebase from 'firebase/app'
import store from '../store'
import { Game, GameMetadata, GameOptions, Sheet } from '../../types'
import { setAdminSheets, setConnectionStatusDelta, setGame, setGames, setMetadata, setMetadataDelta, setOptions, setSheet, setSheetDelta } from '../reducers/game/game'
import { setConnected } from '../reducers/auth/auth'

const PORT = process.env.REACT_APP_PORT || 4000

interface SetGameResult {
  [key: string]: string
}

const onSetGames = (games: SetGameResult) => {
  if (!games) {
    store.dispatch(setGames([]))
  } else {
    store.dispatch(setGames(Object.values(games).map(s => JSON.parse(s) as Game)))
  }
}

const onSetGame = (game?: Game) => {
  store.dispatch(setGame(game))
}

const onSetSheet = (sheet?: Sheet) => {
  store.dispatch(setSheet(sheet))
}

const onSetConnectionStatusDelta = (delta: any) => {
  store.dispatch(setConnectionStatusDelta(delta))
}

const onSetSheetDelta = (sheetChanges: any) => {
  store.dispatch(setSheetDelta(sheetChanges))
}

const onSetAdminSheets = (sheets?: Sheet[]) => {
  store.dispatch(setAdminSheets(sheets))
}

const onSetMetadata = (metadata?: GameMetadata) => {
  store.dispatch(setMetadata(metadata))
}

const onSetMetadataDelta = (metadataChanges: any) => {
  store.dispatch(setMetadataDelta(metadataChanges))
}

const onSetOptions = (options?: GameOptions) => {
  store.dispatch(setOptions(options))
}

const onConnect = () => {
  setImmediate(() => {
    store.dispatch(setConnected(true))
  })
}

const onDisconnect = () => {
  setImmediate(() => {
    store.dispatch(setConnected(false))
  })
}

export const SocketContext = createContext<SocketIOClient.Socket | undefined>(undefined)

declare global {
  namespace SocketIOClient {
    interface Socket {
      authenticated?: boolean
    }
  }
}

export const useSocketProvider = () => {
  const uid = useSelector(s => s.auth.uid)
  const [socket, setSocket] = useState<SocketIOClient.Socket>()

  useEffect(() => {
    if (!socket) {
      return
    }

    socket.on('set-game', onSetGame)
    socket.on('set-games', onSetGames)
    socket.on('set-sheet', onSetSheet)
    socket.on('set-connection-status-delta', onSetConnectionStatusDelta)
    socket.on('set-sheet-delta', onSetSheetDelta)
    socket.on('set-admin-sheets', onSetAdminSheets)
    socket.on('set-metadata', onSetMetadata)
    socket.on('set-metadata-delta', onSetMetadataDelta)
    socket.on('set-options', onSetOptions)
    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)
  }, [socket])

  useEffect(() => {
    if (uid) {
      firebase.auth().currentUser?.getIdToken().then((token) => {
        const newClient = client(`${window.location.protocol}//${window.location.hostname}:${PORT}`, {
          transportOptions: {
            polling: {
              extraHeaders: {
                'Authorization': `Bearer ${token}`,
              },
            },
          },
        })
        newClient.authenticated = true
        setSocket(newClient)
      })
    } else {
      setSocket(client(`${window.location.protocol}//${window.location.hostname}:${PORT}`))
    }

    return () => {
      setSocket(s => {
        if (s) {
          s.close()
        }

        return undefined
      })
    }
  }, [uid])

  return socket
}

export const useSocket = () => {
  const socket = useContext(SocketContext)

  return socket
}
