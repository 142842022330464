import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import './CashFader.scss'
import { truncateCash } from '../../../../utils/truncateCash'

interface CashFaderProps {
  cash: number
}

export default ({ cash }: CashFaderProps) => {
  const last = useRef(cash)
  const [difference, setDifference] = useState(0)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setDifference(cash - last.current)
    setVisible(true)

    const timer = setTimeout(() => {
      setVisible(false)
    }, 1400)

    last.current = cash

    return () => clearTimeout(timer)
  }, [cash])

  if (!difference) {
    return null
  }

  return (
    <div className={classNames('cash-fader', {
      'cash-fader-visible': visible,
      'negative': difference < 0,
    })}>
      {difference > 0 && '+'}{truncateCash(difference)}
    </div>
  )
}
