import { GameBoard, PlayerStatuses } from '../../types'

export const findOwner = (planet: keyof GameBoard, players: PlayerStatuses) => {
  let owner
  for (const [id, player] of Object.entries(players)) {
    if (player.planets.indexOf(planet) !== -1) {
      owner = id
      break
    }
  }
  return owner
}
