import { UpgradeCost } from '../../types'
import { truncateCash } from './truncateCash'

interface CostOptions {
  ignoreCash?: boolean
  removeDigits?: boolean
}

export default function formatCost(cost: UpgradeCost, options?: CostOptions) {
  let result = []

  if (typeof cost.cash === 'number' && !options?.ignoreCash) {
    result.push(`${truncateCash(cost.cash)}`)
  }

  if (cost.resources) {
    Object.keys(cost.resources).forEach((resource) => {
      if (options?.removeDigits) {
        // @ts-ignore
        result.push(`${resource}: ${truncateCash(cost.resources[resource], { raw: true, removeDigits: options.removeDigits })}`)
      } else {
        // @ts-ignore
        result.push(`${resource}: ${truncateCash(cost.resources[resource], { raw: true })}`)
      }
    })
  }

  if (cost.income && cost.income !== 0) {
    result.push(`Income: ${truncateCash(cost.income)}`)
  }

  if (cost.equity) {
    result.push(`Min equity: ${truncateCash(cost.equity)}`)
  }

  return result.join(', ')
}
