const resources: any = {
  Wa: 'Water',
  Cu: 'Copper',
  Fe: 'Iron',
  Ag: 'Silver',
  Au: 'Gold',
  Oil: 'Oil',
  Dia: 'Diamond',
  Ti: 'Titanium',
  Pt: 'Platinum',
  U: 'Uranium',
}

const resource_colors: any = {
  Wa: 'blue',
  Cu: 'orange',
  Fe: 'purple',
  Ag: 'grey',
  Au: 'yellow',
  Oil: 'olive',
  Dia: 'teal',
  Ti: 'pink',
  Pt: 'violet',
  U: 'red',
}

export const translateResource = (resource: any) => {
  return resources[resource] || resource
}

export const translateResourceColor = (resource: any) => {
  return resource_colors[resource]
}
