import { cloneDeep } from 'lodash'
import { ShipUpgrade } from '../../../../../types'
import { formatZeros } from './formatZeros'

export const createShipUpgradeLevelsFront = (_shipUpgrade: ShipUpgrade[]) => {
  let SMU = cloneDeep(_shipUpgrade)

  SMU = [{
    cost: { cash: 0 },
    max: 35,
  }, {
    cost: { cash: 200 }, // 200 100 150 200
    max: 60,
  }, {
    cost: { cash: 500 }, // 600 300 600 400 500
    max: 90, // 100
  }, {
    cost: { cash: 600, resources: { Fe: 1 } }, // 0 600 cash
    max: 135, // 150
  }, {
    cost: { cash: 800, resources: { Fe: 3 } },
    max: 200, // 300
  }, {
    cost: { cash: 1200, resources: { Fe: 4 } }, // Fe 5 4
    max: 300, // 600 400
  }, {
    cost: { cash: 3000, resources: { Fe: 1, Pt: 1 } }, // rez 2 1
    max: 450, // 600 450
  }, {
    cost: { income: 1000, resources: { Fe: 2, Pt: 2 } }, // rez 3 2
    max: 700, // 600 900 700
  }, {
    cost: { income: 5000, resources: { Fe: 3, Pt: 3, U: 3 } }, // rez 4 3
    max: 1200, // 5000 this value is overriden by some trigger to 1000
  }, {
    cost: { income: 8000, resources: { Fe: 7, Pt: 7, U: 7 } }, // 5000 5 5 5 8000 8 8 8
    max: 1500, // 10000 this value is overriden as well
  }]

  for (let i = 10; i < 15; i++) {
    SMU.push({
      max: SMU[i - 1].max * 2,
      cost: { income: (SMU[i - 1].cost.income || 0) * 8.5, resources: {
        Fe: Math.round((SMU[i - 1].cost.resources?.Fe || 0) * 3),
        Pt: Math.round((SMU[i - 1].cost.resources?.Pt || 0) * 3),
        U: Math.round((SMU[i - 1].cost.resources?.Pt || 0) * 3) } },
    })
  }

  for (let i = 15; i < 102; i++) {
    SMU.push({
      max: SMU[i - 1].max * 2,
      cost: { income: (SMU[i - 1].cost.income || 0) * 4.6, resources: {
        Fe: Math.round((SMU[i - 1].cost.resources?.Fe || 0) * 2.5),
        Pt: Math.round((SMU[i - 1].cost.resources?.Pt || 0) * 2.5),
        U: Math.round((SMU[i - 1].cost.resources?.Pt || 0) * 2.5) } },
    })
  }

  for (let i = 8; i < 102; i++) {
    SMU[i].max = formatZeros(SMU[i].max, 1)
    SMU[i].cost.income = formatZeros((SMU[i].cost.income || 0), 1)
    const resources = SMU[i].cost.resources
    if (resources) {
      resources.Fe = formatZeros(Math.round((SMU[i].cost.resources?.Fe || 0)), 2)
      resources.Pt = formatZeros(Math.round((SMU[i].cost.resources?.Pt || 0)), 2)
      resources.U = formatZeros(Math.round((SMU[i].cost.resources?.U || 0)), 2)
    }
  }

  return SMU
}
