import React from 'react'
import { Button, Modal } from 'semantic-ui-react'

interface HelpMenuProps {
  onCancel: () => void
  open: boolean
}

export default ({ onCancel, open }: HelpMenuProps) => {
  return (
    <Modal open={open} onClose={onCancel}>
      <Modal.Content>
        <h2 id="timeline-of-the-game-by-year-">Timeline of the game by year:</h2>
        <ul>
          <li><strong>Years 1-10</strong><ul>
            <li>Consider growing the population of your ship to 60 before buying many planets, as population grows faster with more citizens on board.</li>
            <li>Pick and choose very low population planets to start to avoid depleting the ship. Avoid hitting the maximum ship population by upgrading the size of your ship. </li>
            <li>Collect as starter resources as possible (water, copper, iron, silver) as these are required for the first levels of most planets, needed for the credit line, and are used for tile upgrades.</li>
          </ul>
          </li>
          <li><strong>Years 10-20</strong><ul>
            <li>Consider buying a few large planets and lots of mines to help fund and feed alpha planets that are coming soon.</li>
          </ul>
          </li>
          <li><strong>Year 20</strong><ul>
            <li>All players can warp to the Alpha Quadrant (Requires 1-3 waters &amp; 30-200 pop).</li>
            <li>Choose a planet you can relatively easily ugrade.</li>
          </ul>
          </li>
          <li><strong>Year 29</strong><ul>
            <li>Buy all planetary barriers required to protect mines on those planets  </li>
          </ul>
          </li>
          <li><strong>Year 30</strong><ul>
            <li>Geomagnetic storms begin and will destroy mines that do not have barriers</li>
            <li>Access to Kappa Quadrant</li>
            <li>Mines and tile upgrades begin inflating in value and become more expensive</li>
            <li>Interest rates begin rising soon.</li>
            <li>Planet loans and LOC are variable rate while starbase loans are fixed interest rates.</li>
            <li>Rates can reach as high as 35% by year 55 (so try to reduce debt and keep an A credit rating)</li>
          </ul>
          </li>
          <li><strong>Year 35</strong><ul>
            <li>Resources begin inflating in value and become more expensive</li>
          </ul>
          </li>
          <li><strong>Year 40</strong><ul>
            <li>Mines permanently expire when hit by geomagnetic storms, barriers help reduce how many are lost.</li>
            <li>Access to Omicron quadrant</li>
          </ul>
          </li>
          <li><strong>Year 50</strong><ul>
            <li>Access to Beta quadrant</li>
          </ul>
          </li>
          <li><strong>Year 55</strong><ul>
            <li>Most extreme interest rates</li>
          </ul>
          </li>
          <li><strong>Year 60</strong><ul>
            <li>Interest rates begin dropping and will drop for around 35 years</li>
            <li>Access to Tensilon quadrant</li>
          </ul>
          </li>
          <li><strong>Year 70</strong><ul>
            <li>Access to Quion quadrant</li>
            <li>Pirates begin invading planets and stealing resources. You can convert gold to hire policy force to prevent losing resources.</li>
          </ul>
          </li>
          <li><strong>Year 80</strong><ul>
            <li>Access to Epsilon quadrant</li>
          </ul>
          </li>
          <li><strong>Year 85</strong><ul>
            <li>Lowest interest rates happen around year 85. Money is free so borrow as much as possible without letting the credit rating drop below CCC+</li>
          </ul>
          </li>
        </ul>
        <h2 id="credit-ratings">Credit Ratings</h2>
        <p><strong>Credit Ratings</strong> follow your credit rating closely. If you are borrowing too much it will begin to drop.</p>
        <p>A great credit rating is <strong>A</strong>. Above an <strong>A</strong> credit rating and you may actually not be borrowing enough money to help propel your investments. Below and <strong>A</strong> and you may be spending too much. Players generally get into serious trouble if their credit rating drops below <strong>BB+</strong>. </p>
        <p>If this happens, lenders consider you a higher risk and begin to increase the interest rates on your loans. This can further worsen your income and credit rating and cause a viscious downward spiral if you not careful.</p>
        <p><strong>Credit ratings from best to worst:</strong></p>
        <ul>
          <li>AAA+</li>
          <li>AAA</li>
          <li>AA+</li>
          <li>AA</li>
          <li>A+</li>
          <li>A</li>
          <li>BBB+</li>
          <li>BBB</li>
          <li>BB+</li>
          <li>BB</li>
          <li>B+</li>
          <li>B</li>
          <li>CCC+</li>
          <li>CCC</li>
          <li>CC+</li>
          <li>CC</li>
          <li>C+</li>
          <li>C</li>
          <li>D</li>
        </ul>
        <h2 id="mine-tips">Mine Tips</h2>
        <p>Located at the bottom right of planets.</p>
        <p>Only buy what you need to upgrade your planets. <strong>NEVER</strong> let a planet get stuck on a level due to insufficient resources as this has a massive impact on your income long-term.</p>
        <p>Use traders, buy mines, trade with others, or use quests to make sure you have sufficient resources. If you don&#39;t have the resources, make sure before you buy a planet make sure you have a plan on how to feed it all the resources.</p>
        <h2 id="planet-population">Planet Population</h2>
        <p>Each <strong>1</strong> population gives <strong>$1</strong> income.</p>
        <p>As such, when you move 10 people from your ship onto a planet, it continues to create $10 income and your income is unchanged.</p>
        <p>When that planet levels up and increases to (for example) 15 population, you are now receiving $15 income from that planet.</p>
        <h2 id="planet-returns-percentage">Planet Returns Percentage</h2>
        <p>Each planet displays a rate of return.</p>
        <p>This shows you how profitable the planet would be to buy.</p>
        <p>A <strong>10% return</strong> means that for every <strong>$100</strong> invested in the planet <em>(in population, resources, cash)</em>, you will receive <strong>$10</strong>.</p>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={onCancel}>Close</Button>
      </Modal.Actions>
    </Modal>
  )
}
