import React, { useCallback, useMemo } from 'react'
import { useSelector } from '../../../../hooks/useSelector'
import { Card, Label, Icon, ButtonGroup } from 'semantic-ui-react'
import groupBy from 'lodash/groupBy'
import get from 'lodash/get'
import translateOrbitingPlanet from '../../../../utils/translateOrbitingPlanet'
import RiftChoice from './RiftChoice'
import { GameBoard, PlanetDeed } from '../../../../../types'
import translateOrbitingPlanetColor from '../../../../utils/translateOrbitingPlanetColor'
import PlanetDetailsDisplay from './PlanetDetailsDisplay'
import './PlayerChoosePlanetMovement.scss'
import { useDispatch } from 'react-redux'
import { setFullscreen, setSortOwned } from '../../../../reducers/game/game'
import PromiseButton from '../../../../components/PromiseButton'
import usePlayerActions from '../../hooks/usePlayerActions'
import { truncateCash } from '../../../../utils/truncateCash'
import classNames from 'classnames'

export default ({ owned, fullscreen }: { owned?: boolean, fullscreen?: boolean }) => {
  const uid = useSelector(s => s.auth.uid)
  const players_choosing_planet = (useSelector(s => s.game.metadata?.players_choosing_planet) || [])
  const has_rift_in_choice = useSelector(s => s.game.sheet?.has_rift_in_choice)
  const planet_movement_options = useSelector(s => s.game.sheet?.planet_movement_options)
  const planet_purchase_options = useSelector(s => s.game.sheet?.planet_purchase_options)
  const planets = useSelector(s => s.game.sheet?.planets)
  const current_planet = useSelector(s => get(s, `game.metadata.players.${uid}.current_planet`)) as keyof GameBoard
  const is_fullscreen = (useSelector(s => s.game.fullscreen))
  const is_map = useSelector(s => s.game.galaxy_display)
  const sort_owned = useSelector(s => s.game.sort_owned)
  const rift_opening_year = useSelector(s => s.game.metadata?.rift_opening_year)
  const { onUpgradePlanet } = usePlayerActions()
  const dispatch = useDispatch()

  const onSort = useCallback((sort: string) => {
    dispatch(setSortOwned(sort))
  }, [dispatch])

  const totalLoanAvailable = useMemo(() => {
    let total = 0

    if (!owned || !planets) {
      return total
    }

    for (const p of Object.values(planets)) {
      if (p.loan === p.level) {
        continue
      }

      if (p.loan === -1) {
        total += p.deed.levels[p.level].loan
      } else {
        total += Math.max(0, p.deed.levels[p.level].loan - p.deed.levels[p.loan].loan)
      }
    }

    return total
  }, [planets, owned])

  const totalLoanUsed = useMemo(() => {
    let total = 0

    if (!owned || !planets) {
      return total
    }

    for (const p of Object.values(planets)) {
      if (p.loan > -1) {
        total += p.deed.levels[p.loan].loan
      }
    }

    return total
  }, [planets, owned])

  if (fullscreen && !is_fullscreen) {
    return null
  }

  if (!planet_purchase_options || !rift_opening_year) {
    return null
  }

  if (!planet_movement_options) {
    return null
  }

  if (!Object.keys(planet_purchase_options).length && !Object.keys(planet_movement_options).length && !owned) {
    return null
  }

  if (!planets) {
    return null
  }

  if (!players_choosing_planet && !Object.keys(planet_purchase_options).length && !owned) {
    return null
  }

  if (!current_planet) {
    return null
  }

  if (owned && planets && !Object.keys(planets).length) {
    return null
  }

  const isPurchase = !!Object.keys(planet_purchase_options).length

  let relevant_planets
  if (owned) {
    relevant_planets = planets
  } else if (isPurchase) {
    relevant_planets = planet_purchase_options
  } else {
    relevant_planets = planet_movement_options
  }

  let planet_options = groupBy(Object.keys(relevant_planets).map(planet_data => {
    const [region_info, planet_info] = planet_data.split(':')
    const [orbiting_planet, quadrant] = region_info.split('|')
    const [planet_name, data] = planet_info.split('|')

    let deed
    if (owned) {
      deed = planets[planet_data].deed
    } else if (isPurchase) {
      deed = planet_purchase_options[planet_data]
    } else {
      deed = planet_movement_options[planet_data]
    }

    return {
      isPlanet: ['Starbase', 'Battlestation'].indexOf(planet_name) === -1,
      planet_name,
      data,
      orbiting_planet,
      quadrant,
      original: planet_data as keyof GameBoard,
      deed,
      ownedPlanet: planets[planet_data],
      purchased_at: planets[planet_data]?.purchased_at,
    }
  }), 'orbiting_planet')

  let sorted_keys: string[] = Object.keys(planet_options).filter(p => p !== 'R')
  if (owned && sort_owned === 'latest') {
    planet_options = {
      'All Planets': Object.values(planet_options).flat(),
    }
    planet_options['All Planets'].sort((a, b) => {
      return (b.purchased_at ?? 0) - (a.purchased_at ?? 0)
    })
    sorted_keys = ['All Planets']
  } else if (owned && sort_owned === 'planet') {
    planet_options = {
      'All Planets': Object.values(planet_options).flat(),
    }
    planet_options['All Planets'].sort((a, b) => {
      const bd = (b.deed as PlanetDeed).levels
      const ad = (a.deed as PlanetDeed).levels
      return bd[bd.length - 1].pop - ad[ad.length - 1].pop
    })
    sorted_keys = ['All Planets']
  } else if (owned && sort_owned === 'region') {
    for (const value of Object.values(planet_options)) {
      value.sort((a, b) => a.planet_name.localeCompare(b.planet_name))
    }
    sorted_keys.sort((a, b) => rift_opening_year[b] - rift_opening_year[a])
  } else if (owned && sort_owned === 'upgrade') {
    planet_options = {
      'All Planets': Object.values(planet_options).flat(),
    }
    planet_options['All Planets'].sort((a, b) => {
      const bv = b.ownedPlanet.sub_levels_current - b.ownedPlanet.tile
      const av = a.ownedPlanet.sub_levels_current - a.ownedPlanet.tile
      return bv - av
    })
    sorted_keys = ['All Planets']
  } else {
    for (const value of Object.values(planet_options)) {
      value.sort((a, b) => a.planet_name.localeCompare(b.planet_name))
    }
    sorted_keys.sort((a, b) => a.localeCompare(b))
  }

  const current_orbiting_planet = current_planet.split(':')[0].split('|')[0]

  delete planet_options.R

  return (
    <Card fluid className={classNames({ is_map })} style={{ zIndex: 1 }}>
      <Card.Content>
        <Card.Header as="h1">
          {owned && 'Your Planets'}
          {!owned && !isPurchase && 'Where would you like to go?'}
          {!owned && !!isPurchase && 'Choose an action!'}
        </Card.Header>
      </Card.Content>
      {owned && (
        <React.Fragment>
          <Card.Content>
            <ButtonGroup size="mini">
              <PromiseButton disabled={totalLoanAvailable === 0} ignoreGameLogic size="mini" color="red" inverted onClick={() => onUpgradePlanet(null, 'all_planet_loans')}>
                Withdraw All ({truncateCash(totalLoanAvailable, { raw: true })})
              </PromiseButton>
              <PromiseButton disabled={totalLoanUsed === 0} ignoreGameLogic size="mini" color="green" inverted onClick={() => onUpgradePlanet(null, 'deposit_all_planet_loans')}>
                Deposit All ({truncateCash(totalLoanUsed, { raw: true })})
              </PromiseButton>
            </ButtonGroup>
          </Card.Content>
          <Card.Content>
            <Card.Header as="h5" style={{ marginBottom: '6px' }}>
              Sorting
              {owned && <Icon onClick={() => dispatch(setFullscreen(!is_fullscreen))} style={{ position: 'absolute', right: '16px', cursor: 'pointer' }} name="expand" size="large" />}
            </Card.Header>
            <Label size="small" color={!sort_owned ? 'green' : undefined} onClick={() => onSort('')} style={{ marginTop: '2px', cursor: 'pointer', userSelect: 'none' }}>Default</Label>
            <Label size="small" color={sort_owned === 'region' ? 'green' : undefined} onClick={() => onSort('region')} style={{ marginTop: '2px', cursor: 'pointer', userSelect: 'none' }}>Region Size</Label>
            <Label size="small" color={sort_owned === 'planet' ? 'green' : undefined} onClick={() => onSort('planet')} style={{ marginTop: '2px', cursor: 'pointer', userSelect: 'none' }}>Planet Size</Label>
            <Label size="small" color={sort_owned === 'upgrade' ? 'green' : undefined} onClick={() => onSort('upgrade')} style={{ marginTop: '2px', cursor: 'pointer', userSelect: 'none' }}>Upgrading</Label>
            <Label size="small" color={sort_owned === 'latest' ? 'green' : undefined} onClick={() => onSort('latest')} style={{ marginTop: '2px', cursor: 'pointer', userSelect: 'none' }}>Latest</Label>
          </Card.Content>
        </React.Fragment>
      )}
      {has_rift_in_choice && !owned && (<RiftChoice />)}
      {sorted_keys.map((orbiting_planet, i) => {
        return (
          <React.Fragment key={`${orbiting_planet}${i}`}>
            <Card.Content>
              <Card.Header as="h1" style={{ fontSize: owned ? '16px' : '36px', display: 'flex', alignItems: 'center', marginTop: 0 }}>
                <Icon style={{ marginTop: '2px', marginRight: '6px' }} name="globe" size={owned ? 'big' : 'small'} color={translateOrbitingPlanetColor(orbiting_planet)} />
                {translateOrbitingPlanet(orbiting_planet)}
                {orbiting_planet === current_orbiting_planet && (
                  <Label size={owned ? 'large' : 'big'} color={translateOrbitingPlanetColor(orbiting_planet)} style={{ marginLeft: '16px' }}>
                    <Icon name="globe" />
                    Current Orbit
                  </Label>
                )}
              </Card.Header>
            </Card.Content>
            <Card.Content>
              <Card.Description>
                <div className="pr-wrapping-grid">
                  {planet_options[orbiting_planet].map((planet) => {
                    if (isPurchase && planet.original.indexOf('Stargate') !== -1) {
                      return null
                    }

                    return (
                      <React.Fragment key={planet.original}>
                        <div />
                        <PlanetDetailsDisplay choice
                          style={{ marginBottom: '16px', marginRight: '6px' }}
                          planet={planet.original}
                          deed={planet.deed}
                          isPurchase={isPurchase}
                          owned={owned || false}
                          ownedPlanet={planet.ownedPlanet}
                          fullscreen={fullscreen}
                        />
                      </React.Fragment>
                    )
                  })}
                </div>
              </Card.Description>
            </Card.Content>
          </React.Fragment>
        )
      })}
    </Card>
  )
}
