import { OwnedPlanets, Resource, Sheet } from '../../types'

export const getResourceUsageForecast = (planets: OwnedPlanets, sheet_resources: Sheet['resources'] | undefined, cash: number | undefined) => {
  const _current_mines: any = {
    Wa: 0,
    Cu: 0,
    Fe: 0,
    Ag: 0,
    Au: 0,
    Oil: 0,
    Dia: 0,
    Ti: 0,
    Pt: 0,
    U: 0,
  }
  const _current_barriers: any = {
    Wa: 0,
    Cu: 0,
    Fe: 0,
    Ag: 0,
    Au: 0,
    Oil: 0,
    Dia: 0,
    Ti: 0,
    Pt: 0,
    U: 0,
  }
  const _maximum_mines = { ..._current_mines }
  const _usage_forecast = { ..._current_mines }
  for (const [planet] of Object.entries(planets)) {
    planets[planet].mines.forEach((mine) => {
      const mineKey = Object.keys(mine)[0]
      _current_mines[mineKey] += mine[mineKey]
      _maximum_mines[mineKey] += planets[planet].deed.mine_production_maximum[mineKey]
    })
    let count = 0
    planets[planet].deed.disasterBarriers.forEach((barrier) => {
      // const mineKey = Object.keys(barrier)[0]
      _current_barriers[barrier] += planets[planet].deed.disasterBarrierCountCurrent[count]
      count += 1
    })
    planets[planet].deed.levels.forEach((level, i) => {
      if (i > planets[planet].level && i - planets[planet].level < 4) {
        for (const [resource, amount] of Object.entries(level.cost.resources || {})) {
          _usage_forecast[resource] += amount
        }
      }
    })
  }
  const _current_resources: Resource = {
    Wa: sheet_resources?.Wa || 0,
    Cu: sheet_resources?.Cu || 0,
    Fe: sheet_resources?.Fe || 0,
    Ag: sheet_resources?.Ag || 0,
    Au: sheet_resources?.Au || 0,
    Oil: sheet_resources?.Oil || 0,
    Dia: sheet_resources?.Dia || 0,
    Ti: sheet_resources?.Ti || 0,
    Pt: sheet_resources?.Pt || 0,
    U: sheet_resources?.U || 0,
  }
  const _cash = (cash || 0)
  return {
    maximum_mines: _maximum_mines,
    usage_forecast: _usage_forecast,
    current_mines: _current_mines,
    sheet_resources2: _current_resources,
    current_barriers: _current_barriers,
    cash2: _cash,
  }
}
