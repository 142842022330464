import React, { useCallback, useState } from 'react'
import {
  Menu, Button, Dropdown, Icon,
} from 'semantic-ui-react'
import firebase from 'firebase/app'
import { useSelector } from '../../hooks/useSelector'
import { Switch, Route } from 'react-router-dom'
import GameList from './GameList'
import GamePreparationScreen from './GamePreparationScreen'
import EditProfileModal from '../../components/EditProfileModal'
import usePlayerActions from '../SheetView/hooks/usePlayerActions'
import { GAME_VERSION } from '../../GAME_VERSION'
import { GameResumeScreen } from './GameResumeScreen'

const provider = new firebase.auth.GoogleAuthProvider()
if (window.location.host.indexOf('localhost') !== -1) {
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
}

export default () => {
  const [editingProfile, setEditingProfile] = useState<boolean>(false)
  const authorized = useSelector(s => s.auth.authorized)
  const name = useSelector(s => s.auth.name)
  const displayName = useSelector(s => s.auth.displayName)
  const {
    onGetGameBoard,
  } = usePlayerActions()

  const onSignIn = useCallback(() => {
    firebase.auth().signInWithPopup(provider)
  }, [])

  const onSignOut = useCallback(() => {
    firebase.auth().signOut()
  }, [])

  const onEditProfile = useCallback(() => {
    setEditingProfile(true)
  }, [])

  return (
    <>
      <EditProfileModal open={editingProfile} onCancel={() => setEditingProfile(false)} />
      <Menu inverted style={{ borderRadius: 0 }}>
        <Menu.Item header>
          <Icon name="rocket" color="red" />
          Prometheus v{GAME_VERSION}
        </Menu.Item>

        <a href="https://discord.gg/uPkBBBTax6" target="_blank" rel="noreferrer noopener">
          <Menu.Item header onClick={() => {}}>
            <Icon name="discord" color="blue" />
            Join our Discord!
          </Menu.Item>
        </a>

        <Menu.Menu position="right">
          {authorized ? (
            <Dropdown item text={`${name} (${displayName || 'Anonymous'})`}>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => onGetGameBoard().then(r => console.log(r.data))}>Get Game Board (check console)</Dropdown.Item>
                <Dropdown.Item onClick={onEditProfile}>Edit Profile</Dropdown.Item>
                <Dropdown.Item onClick={onSignOut}>Log Out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Menu.Item>
              <Button primary onClick={onSignIn}>Sign In</Button>
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu>
      <Switch>
        <Route path="/games/resume/:id?" component={GameResumeScreen} />
        <Route path="/games/:id" component={GamePreparationScreen} />
        <Route path="/games" component={GameList} />
      </Switch>
    </>
  )
}
