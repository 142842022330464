import { useEffect } from 'react'
import firebase from 'firebase/app'
import { useDispatch } from 'react-redux'
import { updateAuthorization, setDisplayName } from '../reducers/auth/auth'
import { useSelector } from './useSelector'
import { GamePlayer } from '../../types'

export default function useAuthState() {
  const dispatch = useDispatch()
  const uid = useSelector(s => s.auth.uid)

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      dispatch(updateAuthorization(user))
    })
  }, [dispatch])

  useEffect(() => {
    if (!uid) {
      return
    }

    const unsub = firebase.firestore().collection('users').doc(uid).onSnapshot((doc) => {
      const player = doc.data() as GamePlayer
      if (player?.name) {
        dispatch(setDisplayName(player.name))
      }
    })

    return () => {
      unsub()
      dispatch(setDisplayName())
    }
  }, [uid, dispatch])
}
