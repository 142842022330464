import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { pushError } from '../reducers/game/game'

export default (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const [disabled, setDisabled] = useState(false)
  const originalOnClick = props.onClick
  const dispatch = useDispatch()
  const onClick = useCallback(async (e) => {
    if (!originalOnClick) {
      return
    }
    setDisabled(true)
    try {
      await originalOnClick(e)
    } catch (e) {
      dispatch(pushError({
        type: 'error',
        message: e.message,
      }))
    }
    setDisabled(false)
  }, [originalOnClick, dispatch])
  return <div {...props} onClick={disabled ? () => {} : (e) => onClick(e)} />
}
