import React, { useCallback } from 'react'
import { Card, Icon, Popup, Menu, Header, Label } from 'semantic-ui-react'
import './PlanetDetailsDisplay.scss'
import { GameBoard, PlanetDeed, OwnedPlanet, StarbaseLoan, NonDeed, Sheet } from '../../../../../types'
import PromiseButton from '../../../../components/PromiseButton'
import usePlayerActions from '../../hooks/usePlayerActions'
import translateOrbitingPlanetColor from '../../../../utils/translateOrbitingPlanetColor'
import { PlanetDetailsDisplayDeed } from './PlanetDetailsDisplayDeed'
import { useSelector } from '../../../../hooks/useSelector'
import classNames from 'classnames'
import { findOwner } from '../../../../utils/findOwner'
import { PlanetDetailsDisplayLoan } from './PlanetDetailsDisplayLoan'
import formatCost from '../../../../utils/formatCost'
import { useDispatch } from 'react-redux'
import { pushError } from '../../../../reducers/game/game'
import { truncateCash } from '../../../../utils/truncateCash'
import { round } from 'lodash'
import { useGameBoardActions } from '../../hooks/useGameBoardActions'

interface PlanetDetailsDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
  planet: keyof GameBoard
  style?: React.CSSProperties
  choice?: boolean
  rift?: boolean
  deed?: PlanetDeed | StarbaseLoan | NonDeed
  isPurchase?: boolean
  owned?: boolean
  ownedPlanet?: OwnedPlanet
  fullscreen?: boolean
}

export default ({ planet, style, choice, rift, deed, isPurchase, owned, ownedPlanet, fullscreen }: PlanetDetailsDisplayProps) => {
  const {
    onPickChoosablePlanet,
    onUpgradePlanet,
  } = usePlayerActions()
  const {
    focusPlanet,
  } = useGameBoardActions()

  const tile = useSelector(s => s.game.sheet?.planets[planet]?.tile) || 0
  const turn = useSelector(s => s.game.metadata?.turn) || 0
  const players_choosing_planet = useSelector(s => s.game.metadata?.players_choosing_planet) || []
  const players = useSelector(s => s.game.metadata?.players)
  const uid = useSelector(s => s.auth.uid)
  const tile_upgrades = useSelector(s => s.game.metadata?.tile_upgrades)
  const disable_planet_tokens = useSelector(s => s.game.options?.disable_planet_tokens)
  const sublevels_current = useSelector(s => s.game.sheet?.planets[planet]?.sub_levels_current) || 0
  const gameboard = useSelector(s => s.game.game_board)
  let sheet = useSelector(s => s.game.sheet) as Sheet || {}
  const dispatch = useDispatch()

  const onFocusPlanet = useCallback((planet) => {
    focusPlanet(planet)
  }, [focusPlanet])

  if (!players || !uid || !tile_upgrades) {
    return null
  }

  const owner = findOwner(planet, players)

  const isDeed = deed && deed.type === 'deed'
  const isLoan = deed && deed.type === 'starbase'
  let next_resource = ''
  let resource_qty_required
  if (owned) {
    let index = 0
    index = tile_upgrades.findIndex(x => x.from_numbers[0] === tile)
    if (index === -1 && disable_planet_tokens === false) {
      index = tile_upgrades.findIndex(x => x.from_numbers[1] === tile)
    }
    if (!index && disable_planet_tokens === true) {
      index = 0
    }
    if (index + 1 < tile_upgrades.length) {
      for (const [, rez] of Object.entries(tile_upgrades[index].cost)) {
        if (rez) {
          next_resource = Object.keys(rez)[0]
          resource_qty_required = rez[Object.keys(rez)[0]]
        }
      }
      if (!next_resource) {
        next_resource = tile_upgrades[index].cost.cash?.toString() || ''
      }
    }
  }

  let buttonText: any = false
  let disabled = false
  if (owned) {
    if (disable_planet_tokens) {
      buttonText = `${round(sublevels_current, 1)}/${tile} - ${next_resource}`
    } else {
      buttonText = `${tile} - ${next_resource}`
    }
  } else if (isPurchase) {
    if (isLoan) {
      buttonText = false
    } else if (owner === uid) {
      buttonText = 'Already Owned by You'
      disabled = true
    } else if (owner) {
      buttonText = `Owned by ${players[owner].name}`
      disabled = true
    } else {
      buttonText = 'Buy Planet'
    }
  } else if (choice && !rift) {
    if ((turn % 10 === 0) && (turn > 19)) {
      disabled = players_choosing_planet[0] !== uid || Object.values(players).some(p => p.current_planet === planet)
    }

    if (deed && (deed as any).hasOwnProperty('rent') && owner !== uid) {
      buttonText = `Travel (Rent: ${truncateCash((deed as any).rent)})`
    } else if (deed && (deed as any).hasOwnProperty('rent') && owner === uid) {
      buttonText = 'Travel (Owned by you)'
    } else {
      buttonText = 'Travel'
    }
  } else if (choice && rift) {
    if (deed && (deed as any).hasOwnProperty('rent') && owner !== uid) {
      buttonText = `Warp (Rent: ${truncateCash((deed as any).rent)})`
    } else if (deed && (deed as any).hasOwnProperty('rent') && owner === uid) {
      buttonText = 'Warp (Owned by you)'
    } else {
      buttonText = 'Warp'
    }
  }

  const orbit = planet.split(':')[0].split('|')[0]

  const connections = players_choosing_planet.length && gameboard?.[planet]?.connected_planets?.length && !owned ? (
    <Card.Content style={{ padding: '6px' }}>
      Connections:<br />
      {gameboard?.[planet]?.connected_planets?.map((p) => (
        <Label color="grey" style={{ marginTop: '2px' }}>{p}</Label>
      ))}
    </Card.Content>
  ) : (
    undefined
  )

  const button = (
    <PromiseButton ignoreGameLogic
      size="mini"
      disabled={disabled}
      style={owned ? { borderRadius: '50xp' } : {}}
      className={classNames({ icon: owned })}
      color={disabled ? 'grey' : owned && (sheet.resources[next_resource] >= resource_qty_required || next_resource === (tile_upgrades[0].cost.cash?.toString()) || 0) ? 'green' : owned && !next_resource ? 'grey' : owned ? 'orange' : translateOrbitingPlanetColor(orbit)}
      onClick={() => owned ? onUpgradePlanet(planet, 'tile').catch(e => dispatch(pushError({ message: e.message, type: 'error' }))) : (isPurchase ? onUpgradePlanet(planet, 'purchase') : onPickChoosablePlanet(planet))}
    >
      {!owned && !disabled && (<Icon name={isPurchase ? 'dollar sign' : 'rocket'} />)}
      {buttonText}
    </PromiseButton>
  )

  const downgrade = (
    <PromiseButton fluid onClick={() => onUpgradePlanet(planet, 'downgrade_tile')} secondary ignoreGameLogic>
      Downgrade Tile
    </PromiseButton>
  )

  const isLevelingUp = owned && (round(sublevels_current, 1) + 1) >= tile

  return (
    <div className={classNames({ 'leveling-up': isLevelingUp })} style={{ ...(owned && !fullscreen ? { width: '100%' } : {}), ...style }}>
      <Card style={{ ...((owned && !fullscreen) ? { width: '100%' } : {}) }}>
        <Card.Content>
          <Card.Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Popup position="bottom left" hoverable trigger={(
                <div style={{ cursor: 'pointer' }} onClick={() => onFocusPlanet(planet)}>{planet.split(':')[1]}</div>
              )}>
                Click to show on map
              </Popup>

              {!owned && isPurchase && (
                <Popup wide="very" position="top center" basic trigger={(
                  <div>
                    <Icon color="grey" size="small" style={{ marginLeft: '6px' }} name="info circle" />
                  </div>
                )}>
                  To <b>Buy a Planet</b> you must have:
                  <ol>
                    <li style={{ marginLeft: '6px' }}>Enough cash as dictated in the bar at the top of the card</li>
                    <li style={{ marginLeft: '6px' }}>Enough population to satisfy the level 1 population count</li>
                    <li style={{ marginLeft: '6px' }}>Enough of the required resources in the level 1 "Req" (Required Resources) column, if any</li>
                  </ol>
                  The planet population will grow over time, earning you additional income, but in order for the planet to upgrade you will need the "Req" (Required Resources) available during it's upgrade phase.
                  <hr />
                  Each planet has <b>Resource Mines</b> dictated at the bottom right of the card. Once you purchase this planet, you can start purchasing mines on it to harvest resources you'll need throughout the game.
                  <br />
                  Be sure to find a good balance of resources as this can severly constrain your ability to grow late game.
                </Popup>
              )}

              {!!owned && (
                <Popup wide="very" position="top center" basic trigger={(
                  <div>
                    <Icon color="grey" size="small" style={{ marginLeft: '6px' }} name="info circle" />
                  </div>
                )}>
                  Your <b>Owned Planets</b> upgrade every couple of turns. Knowing when a planet will upgrade is based on the <i>Tile</i> on the right side of the card.
                  <br />
                  This will increase by 1 every turn, and will overflow into it's next level.
                  <hr />
                  <b>Resource Mines</b> at the bottom right of the card can be purchased by clicking on them. These will be earned at the same time the planet should level up.
                  <br />
                  If you don't have the required resources for your planet to level up, and are blocked from levelling up, your mines <i>will still</i> mine their resources.
                  <hr />
                  <b>Loans</b> can be taken out on your planets. You can withdraw a loan up to the current level of your planet by clicking on the loan in the row.
                  <br />
                  Additionally, you can use the <b>Withdraw All</b> / <b>Deposit All</b> buttons at the top of this card to do this action more quickly.
                  <hr />
                  You can <b>Lock</b> your planet from upgrading if you don't want the resources to be drawn from your pool at the end of the turn.
                  <br />
                  To do so, click the planet level you wish for it not to get to.
                  <br />
                  To remove the lock, simply click it again.
                </Popup>
              )}

              {!!owned && isLevelingUp && (
                <Popup position="top center" basic trigger={(
                  <div>
                    <Icon color="green" size="small" style={{ marginLeft: '4px' }} name="arrow alternate circle up" />
                  </div>
                )}>
                  {planet.split(':')[1]} is about to upgrade!
                  <br />
                  <br />
                  Ensure it has the appropriate resource requirements. Resources earned on your next turn will count toward whether or not this will upgrade.
                </Popup>
              )}
            </div>
            {(buttonText && owned) ? (
              <Popup position="bottom right" hoverable trigger={button}>
                <Header>Tile Upgrades</Header>
                <PromiseButton fluid onClick={() => onUpgradePlanet(planet, 'tile')} primary ignoreGameLogic>
                  Enhance Tile
                </PromiseButton>
                <p>{disable_planet_tokens ? 'increase upgrade speed of planet' : 'upgrade closer to 7'}</p>
                {disable_planet_tokens ? '' : downgrade}
                <Menu vertical>
                  {tile_upgrades.slice(0, tile_upgrades.length - 1).map((upgrade, i) => {
                    return (
                      <Menu.Item key={upgrade.from_numbers.join(', ')}>
                        {upgrade.from_numbers.join(' or ')} =&gt;&nbsp;
                        {formatCost(upgrade.cost) + ' => '}
                        {tile_upgrades[i + 1].from_numbers.join(' or ')}
                      </Menu.Item>
                    )
                  })}
                </Menu>
              </Popup>
            ) : (buttonText && button) }
          </Card.Header>
        </Card.Content>
        {connections}
        {isDeed && <PlanetDetailsDisplayDeed deed={deed as PlanetDeed} planet={planet} owned={owned || false} ownedPlanet={ownedPlanet} />}
        {isLoan && isPurchase && <PlanetDetailsDisplayLoan deed={deed as StarbaseLoan} planet={planet} />}
      </Card>
    </div>
  )
}
