import React, { useCallback, useMemo, useState } from 'react'
import { Modal, Menu, Header } from 'semantic-ui-react'
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic'
import { useSelector } from '../../../../hooks/useSelector'
import PromiseMenuItem from '../../../../components/PromiseMenuItem'
import usePlayerActions from '../../hooks/usePlayerActions'
import { doBlobbyStuff } from '../../../../utils/doBlobbyStuff'
import moment from 'moment'
import { GAME_VERSION } from '../../../../GAME_VERSION'

interface GameMenuProps {
  onCancel: () => void
  open: boolean
}

interface GameMenuItems {
  title: string
  desc: string
  color: SemanticCOLORS
  action: () => void
  disabled?: boolean
}

export default ({ onCancel, open }: GameMenuProps) => {
  const owner = useSelector(s => s.game.game?.owner)
  const uid = useSelector(s => s.auth.uid) || ''
  const name = useSelector(s => s.game.game?.name)
  const disableTimer = useSelector(s => s.game.metadata?.disable_timer)
  const year = useSelector(s => s.game.metadata?.turn)
  const dead = useSelector(s => s.game.metadata?.players[uid].dead)
  const players_choosing_planet = useSelector(s => s.game.metadata?.players_choosing_planet)
  const [count, setCount] = useState(0)

  const {
    onLeaveGame,
    onEndGame,
    onExportGame,
    onPauseGame,
  } = usePlayerActions()

  useMemo(() => {
    if (open) {
      setCount(0)
    }
  }, [open])

  const onSafelyEndGame = useCallback(() => {
    if (count > 0) {
      onEndGame()
    } else {
      setCount(c => c + 1)
    }
  }, [onEndGame, count])

  const onDownloadGame = useCallback(async () => {
    onCancel()
    const result = await onExportGame()
    const blob = new Blob([JSON.stringify({
      ...result.data,
      version: GAME_VERSION,
    }, null, 2)], { type: 'application/json' })
    doBlobbyStuff(blob, `prometheus-${name}-${moment(new Date()).format('YYYY-MM-DD-HH-mm-ss') + 'Year ' + year}`)
  }, [onExportGame, onCancel, name, year])

  const items: GameMenuItems[] = useMemo(() => {
    const _items: GameMenuItems[] = [{
      title: 'Pause Game',
      desc: 'I have to go pee, but that turn timer is way too cruel!',
      color: 'blue',
      disabled: dead,
      action: () => { onCancel(); onPauseGame(true) },
    }, {
      title: 'Abandon Colony',
      desc: 'Abandon my colony and return to the main menu.',
      color: 'orange',
      disabled: dead || (!!uid && (players_choosing_planet?.indexOf(uid) !== -1)),
      action: () => { onCancel(); onLeaveGame() },
    }, {
      title: count === 0 ? 'Finish Game' : 'Are you sure?',
      desc: count === 0 ? 'The game is over, the planets have been conquered, our income is too high, and we want the option to start a new game.' : 'Press again to confirm I want the game to end.',
      color: 'red',
      disabled: owner !== uid,
      action: onSafelyEndGame,
    }]

    if (disableTimer) {
      _items.splice(0, 1)
    }

    _items.unshift({
      title: 'Save Game',
      desc: 'Download the game to be able to resume it later. (This will not work well if a new version of the game is released)',
      color: 'violet',
      action: onDownloadGame,
    })

    return _items
  }, [uid, owner, disableTimer, count, dead, onCancel, onDownloadGame, onLeaveGame, onPauseGame, onSafelyEndGame, players_choosing_planet])

  return (
    <Modal open={open} onClose={onCancel} size="mini">
      <Menu vertical fluid>
        {items.map(({ action, title, desc, disabled, color }) => (
          <PromiseMenuItem key={title} onClick={action} disabled={disabled} ignoreGameLogic>
            <Header color={color}>{title}</Header>
            <p>{desc}</p>
          </PromiseMenuItem>
        ))}
      </Menu>
    </Modal>
  )
}
